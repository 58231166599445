<template>
  <div class="guide-exhibit-navigation">
    <router-link v-if="previousLink" :to="previousLink"><img :src="require('@/assets/icons/exhibit_nav_left.svg')">{{$t('AudioGuides.exhibit_previous')}}</router-link>
    <router-link class="next" v-if="nextLink" :to="nextLink"><img :src="require('@/assets/icons/exhibit_nav_right.svg')">{{$t('AudioGuides.exhibit_next')}}</router-link>
  </div>
</template>
<script>
  export default {
    props: {
      previousLink: {
        type: String,
        required: false,
      },
      nextLink: {
        type: String,
        required: false,
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-exhibit-navigation {
    > a {
      img {
        margin-right: 15px;
      }
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      display: flex;
      flex-direction: row;
      > a {
        display: flex;
        align-items: center;
        img {
          margin-right: 18px;
        }
      }
      .next {
        flex-direction: row-reverse;
        margin-left: auto;
        img {
          margin-left: 18px;
          margin-right: 0;
        }
      }
    }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      display: flex;
      flex-direction: column;

      > a:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
</style>
