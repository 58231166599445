<template>
  <div class="vernissage-container" v-if="vernissages?.length && localizedVernissages?.length" :style="{ left: offsetStyle }" role="list"
    :class="{ 'moving': swiping }"
    @touchend="onSwipeEnd" @touchmove="onSwipe" @touchstart="onSwipeStart">
    <b-modal id="register-modal" @close="closeRegisterModal" @hide="closeRegisterModal" body-class="p-0" hide-footer>
      <!--Регистрация-->
      <div class="modal-info-div" v-if="!register.registerFinished">
        <div class="modal-info-content">
          <span class="modal-info-string">{{$t('Vernissage.modal-info')}}</span>
          <div class="annual-pass-list">
            <template v-for="annualPass in activePasses">
              <b-check
                :key="annualPass.annualPassGuid"
                v-if="!isAlreadyRegistered(annualPass) && allowedAnnualPassTypes && allowedAnnualPassTypes.includes(annualPass.type)"
                :value="annualPass" v-model="register.selectedAnnualPasses" class="annual-pass-checkbox">
                <div class="annual-pass-ticket-desc">{{annualPass.owner}} (#{{annualPass.number}})</div>
              </b-check>
            </template>
            <div class="annual-pass-ticket-desc" v-if="!hasAnyAvailableVernissage">
              {{$t('Vernissage.no-annual-pass-available')}}
            </div>
          </div>
          <div class="registration-warning">{{$t('Vernissage.modal-warn')}}</div>
          <div class="registration-error" v-if="isRegistrationLimitExceeded(register.selectedVernissage)">
            {{$t('Vernissage.registration-limit-exceeded')}}
          </div>
          <b-button class="b-register" v-b-modal="'register-modal'"
                    :disabled="(!register.selectedAnnualPasses || register.selectedAnnualPasses.length <= 0) ||
                     (!hasAnyAvailableVernissage || isRegistrationLimitExceeded(register.selectedVernissage))
                     || isProcessing"
                    variant="primary" @click="onRegisterClick()">
            {{$t('Vernissage.modal-register')}}
          </b-button>
        </div>
      </div>
      <!--Успешное завершение регистрации-->
      <div v-else-if="register.registerFinished && register.success" class="modal-info-div">
        <div class="modal-info-content-success">
          <div class="modal-info-container">
            <div class="d-flex flex-column">
              <inline-svg class="success-icon" :src="require('../assets/icons/success.svg')"/>
              <span class="modal-info-string v-registration-success">{{$t('Vernissage.registration-success')}}</span>
            </div>

            <div class="vernissage-info" v-if="register && register.selectedVernissage">
              <div class="modal-info-string v-title">
                {{register.selectedVernissage.title}}
              </div>
              <div class="modal-info-string v-subtitle">
                {{register.selectedVernissage.startDateFormatted}}
              </div>
            </div>
          </div>
          <b-dropdown-item link-class="external" class="modal-info-string add-to-calendar" href="#" @click="addToCalendar">
            {{$t('Vernissage.add-to-calendar')}}
          </b-dropdown-item>
        </div>
      </div>
      <!--Ошибка при регистрации-->
      <div v-else-if="register.registerFinished && !register.success" class="modal-info-div">
        <div class="modal-info-content-success">
          <div class="v-title">
            {{$t('Vernissage.registration-error')}}
          </div>
        </div>
      </div>
    </b-modal>

    <div class="v-card" :key="index" v-for="(vernissage, index) in localizedVernissages" role="listitem">
      <img class="v-card-img" :src="vernissage.image" alt="vernissage-img">
      <div class="v-card-body">
        <h3 class="v-c-title" aria-level="3">{{vernissage.title}}</h3>
        <div class="v-c-event-type">{{ $t(`Vernissage.event-type-${vernissage.type == null ? 'vernissage' : vernissage.type}`)}}</div>
        <div class="v-c-date">{{vernissage.startDateFormatted}}</div>
        <div class="v-c-info">{{vernissage.info}}</div>

        <div class="vernissage-registration-unavailable" v-if="!registrationAvailable">
          {{$t('Vernissage.registration-unavailable')}}
        </div>
        <div class="vernissage-already-registered"
             v-else-if="isAlreadyRegisteredWithAnyAnnualPass(activePasses, vernissage)">
          {{$t('Vernissage.registration-already')}}
        </div>
        <b-button v-else-if="registrationAvailable && vernissage.isOpen" class="v-c-register"
          v-b-modal="'register-modal'" variant="primary"
          @click="setVernissage(vernissage)">
          {{$t('Vernissage.register')}}
        </b-button>
        <div class="vernissage-registration-open-soon" v-else-if="vernissage.isOpenSoon">
          {{$t('Vernissage.registration-open-soon')}}
        </div>
        <div class="vernissage-registration-closed" v-else-if="vernissage.isClosed">
          {{$t('Vernissage.registration-closed')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import {createEvent} from 'ics'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import vernissageApi from '../modules/vernissage'
  import InlineSvg from 'vue-inline-svg'
  import store from '@/store'

  export default {
    name: 'RegisterForVernissage',
    components: {InlineSvg},
    props: {
      allowedAnnualPassTypes: {
        type: Array
      },
      offset: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        register: {
          selectedVernissage: undefined,
          selectedAnnualPasses: [],
          registerFinished: false,
          success: false
          // registerFinished: true,
          // success: true
        },
        isProcessing: false,
        startSwipeOffset: 0,
        swipeOffset: 0,
        swiping: false,
      }
    },
    created () {
      moment.locale(this.$i18n.locale)
    },
    mounted() {
      this.fetchVernissageList()
    },
    computed: {
      ...mapGetters({activePasses: 'loyalty/activePasses'}),
      ...mapState({
        vernissages: state => state.vernissages.vernissages,
        vernissagesRegistrationInfo: state => state.vernissages.registrationInfo
      }),
      registrationAvailable() {
        // показывать только пользователям с разрешениями
        return this.activePasses.map(ap => ap.type).some(val => this.allowedAnnualPassTypes.includes(val))
      },
      localizedVernissages: function () {
        return this?.vernissages?.map(vernissage => vernissage[this.$i18n.locale]).map(vernissage => {
          const startDate = moment(vernissage?.startDate)
          const registrationStartDate = moment(vernissage?.registrationStartDate)

          const hoursBeforeCloseRegistration = vernissage?.hoursBeforeCloseRegistration
          // закрывать регистрацию за X часов до начала
          const gap = 60 * 60 * 1000 * hoursBeforeCloseRegistration

          const dateWithGap = moment.unix((startDate.valueOf() - gap) / 1000)
          const currentDate = moment()

          // Проверка квоты на регистрацию
          const registrationLimit = vernissage.registrationLimit
          const registrationsCount = (typeof vernissage.guid === 'string') ?
                                     this.vernissagesRegistrationInfo
                                         .filter(info => typeof info.guid === 'string')
                                         .filter(info => info.guid.toLowerCase() === vernissage.guid.toLowerCase())
                                         .shift()?.registrationsCount : 0

          // дата регистрации > текущего дня либо галочка "registrationClosed"/либо квота достигнута/либо осталось <= X часов до начала вернисажа
          const isClosed = vernissage.registrationClosed === 'true'
            || (currentDate.isBetween(dateWithGap, startDate) || currentDate.isAfter(startDate))
            || (parseInt(registrationsCount) >= registrationLimit)

          const isOpen = currentDate.isSameOrAfter(registrationStartDate) && !isClosed
          const isOpenSoon = currentDate.isBefore(registrationStartDate) && !isClosed && !isOpen
          return {
            ...vernissage,
            startDateUnix: vernissage.startDate ? startDate.unix() : null,
            startDateFormatted: vernissage.startDate ? startDate.format('DD MMMM YYYY, HH:mm') : '',
            isOpen,
            isOpenSoon,
            isClosed
          }
        })
          .sort((e1, e2) => e1?.startDateUnix - e2?.startDateUnix)
      },
      hasAnyAvailableVernissage() {
        // Проверка наличия хотя бы одного подходящего абонемента для регистрации
        for (const annualPass of this.activePasses) {
          if (!this.isAlreadyRegistered(annualPass)) {
            return true
          }
        }
        return false
      },
      offsetStyle() {
        let breakPointProp = getComputedStyle(document.body)
          .getPropertyValue('--breakpoint-md')
        if (window.matchMedia(`(min-width: ${breakPointProp})`)?.matches) {
          return `calc((-100% / 3 - 15px) * ${this.offset} - ${this.swipeOffset ?? 0}px)`
        }

        return `calc((-100% + 20px) * ${this.offset} + ${20 - (this.swipeOffset ?? 0)}px)`
      },
    },
    methods: {
      ...mapActions({
        forceLoadAnnualPassList: 'loyalty/forceLoadAnnualPassList',
        fetchVernissageList: 'vernissages/fetchVernissageList',
      }),
      setVernissage(vernissage) {
        this.register.selectedVernissage = vernissage
      },
      closeRegisterModal() {
        this.register = {
          selectedAnnualPasses: [],
          selectedVernissage: undefined,
          success: false,
          registerFinished: false
        }
      },
      isAlreadyRegisteredWithAnyAnnualPass(annualPasses, vernissage) {
        // Проверка наличия привязки абонемента к верниссажу
        if (annualPasses === null || annualPasses === undefined) {
          return false
        }
        const selectedVernissageGuid = vernissage?.guid
        for (const annualPass of annualPasses) {
          if (annualPass?.events?.[selectedVernissageGuid.toLowerCase()]) {
            return true
          }
        }
        return false
      },
      isAlreadyRegistered(annualPass) {
        // Проверка наличия привязки абонемента к верниссажу
        const selectedVernissageGuid = this.register?.selectedVernissage?.guid
        return Boolean(annualPass?.events?.[selectedVernissageGuid])
      },
      onRegisterClick() {
        this.isProcessing = true
        const annualPassGuid = this.register.selectedAnnualPasses?.map(ap => ap.annualPassGuid)
        const vernissageGuid = this.register.selectedVernissage?.guid
        const annualPassNumbers = this.register.selectedAnnualPasses?.map(ap => ap.number)

        const sendInfoRequest = {
          annualPassGuids: annualPassGuid,
          passNumbers: annualPassNumbers,
          vernissageId: vernissageGuid,
          eventType: 'VERNISSAGE',
          visitType: 'MUSEUM',
          locale: this.$root.$i18n.locale
        }

        vernissageApi.eventRegister(vernissageGuid, sendInfoRequest)
          .then(() => {
            this.register.success = true
            this.register.registerFinished = true
            this.forceLoadAnnualPassList()
          })
          .then(() => store.dispatch('auth/checkUnreadMessageCount'))
          .catch(e => {
            this.register.success = false
            this.register.registerFinished = true
            console.debug('vernissage registration error', e.message)
          })
          .finally(()=> this.isProcessing = false)
      },
      isRegistrationLimitExceeded(vernissage) {
        const registrationsCount = this.vernissagesRegistrationInfo?.
          filter(info => info?.vernissageGuid === vernissage?.guid)?.
          shift()?.registrationsCount

        return registrationsCount >= vernissage?.registrationLimit
      },
      addToCalendar() {
        let startDate = moment(this.register.selectedVernissage?.startDate).toArray()
        // отступ месяца (от нуля), ics нужна индексация от единицы
        startDate[1] += 1
        let event = {
          start: startDate,
          startOutputType: 'local',
          startInputType: 'local',
          title: this.register.selectedVernissage?.title,
          url: window.location.origin + '/',
          productId: '-//erarta account',
          uid: `${this.register.selectedVernissage?.guid}@${window.location.hostname}`,
        }

        let eventData = createEvent(event)
        let fileURL = window.URL.createObjectURL(new Blob([eventData.value], {type: 'text/calendar'}))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'vernissage.ics')
        document.body.appendChild(fileLink)
        fileLink.click()
      },
      onSwipeStart(event) {
        this.startSwipeOffset = event.changedTouches[0].pageX
        this.swipeOffset = 0
        this.swiping = true
      },
      onSwipe(event) {
        this.swipeOffset = this.startSwipeOffset - event.changedTouches[0].pageX
      },
      onSwipeEnd() {
        this.swiping = false
        if (this.swipeOffset > 20) {
          this.$emit('swiped-right', this.swipeOffset)
        } else if (this.swipeOffset < -20) {
          this.$emit('swiped-left', this.swipeOffset)
        }
        this.swipeOffset = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .vernissage-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% - (45px * 2))/3);
    gap: 40px 45px;
    padding-bottom: 80px;
    position: relative;
    overflow: visible;
    &:not(.moving) {
      transition: left 0.5s ease-out;
    }
  }

  /* Card start */
  .v-card {
    min-height: 526px;
    background: #{$white};
    display: grid;
    grid-template-rows: 274px auto;
  }

  .v-card-img {
    width: 100%;
    height: 274px;
    object-fit: cover;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .v-card-body {
    height: auto;
    grid-row-start: 2;
    grid-row-end: 2;
    display: grid;
    grid-template-rows: repeat(5, auto);
    padding: 16px;
  }

  .v-c-title, .add-to-calendar {
    font-size: 19px;
    font-weight: #{$font-weight-medium};
    margin-bottom: 12px;
    line-height: 24px;
  }

  .v-c-event-type, .v-c-date, .v-c-info {
    font-size: 14px;
    line-height: 20px;
    color: #49494A;
    font-weight: #{$font-weight-medium};
  }

  .v-c-event-type {
    margin-bottom: 12px;
  }

  .v-c-date {
    margin-bottom: 16px;
  }

  .v-c-info {
    margin-bottom: 22px;
  }

  .v-c-register {
    margin-top: auto;
    height: 50px;
    color: #{$white};
  }

  .vernissage-registration-unavailable, .vernissage-registration-closed, .registration-closed, .vernissage-already-registered,
  .vernissage-registration-open-soon {
    margin-top: 30px;
    font-size: 12px;
    font-weight: #{$font-weight-medium};
  }

  .vernissage-registration-unavailable {
    color: #{$gray-600};
  }

  .vernissage-registration-closed, .registration-closed, .vernissage-already-registered {
    color: #{$primary};
  }

  .vernissage-registration-open-soon {
    color: #F4BF00;
  }

  .vernissage-already-registered {
    color: #21995F;
  }

  /* Card end */

  /* modal start */
  .modal-info-container {
    padding: 0 9px;
  }

  .modal-info-content {
    padding-top: 60px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .modal-info-string {
    font-family: 'Graphik LCG', sans-serif;
  }

  .annual-pass-list {
    margin-top: 42px;
    margin-bottom: 33px;
  }

  .annual-pass-checkbox {
    min-width: 250px;
    top: 3px;
  }

  .annual-pass-ticket-desc {
    font-family: 'Graphik LCG', sans-serif;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
  }

  .select-annual-pass {
    display: grid;
    grid-template-columns: .2fr 3fr;
    align-items: center;
  }

  .registration-warning {
    margin-bottom: 30px;
    font-family: 'Graphik LCG', sans-serif;
    font-size: 18px;
    color: #{$secondary};
  }

  .registration-error {
    font-family: 'Graphik LCG', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 12px;
    color: #{$primary};
  }

  .b-register {
    width: 268px;
    height: 50px;
    font-size: 14px;
    font-family: 'Graphik LCG', sans-serif;
    align-self: center;
  }

  /* modal end */

  /* modal success start */
  .success-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 11px;
  }

  .v-title, .v-subtitle, .add-to-calendar > * {
    font-size: 19px;
    font-weight: 500;
    font-family: 'Graphik LCG', sans-serif;
    line-height: 24px;
  }

  .v-registration-success {
    font-size: 18px;
    line-height: 28px;
  }

  .v-subtitle {
    color: #49494A;
    font-size: 14px;
    line-height: 20px;
  }

  li.add-to-calendar {
    border-top: solid 2px #{$erarta-light-gray};
    padding-top: 20px;
    list-style: none;
    display: grid;
    justify-content: center;
  }

  .modal-info-content-success {
    min-height: 514px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .vernissage-info {
    margin-top: 26px;
    margin-bottom: 84px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  /* modal success end */

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .vernissage-container {
      grid-auto-flow: column;
      grid-auto-columns: calc(100% - 40px);
      column-gap: 20px;
      padding-bottom: 15px;
    }

    /* Card start */
    .v-card {
      width: auto; /* TODO: width: 280px? */
      min-height: 361px;
      max-height: 406px;
      grid-template-rows: 210px auto; /* TODO: 210px 151px */
    }

    .v-card-img {
      width: 100%;
      height: 210px;
    }

    .v-card-body {
      padding: 12px;
    }

    .v-c-title, .add-to-calendar {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 20px;
    }

    .v-c-event-type, .v-c-date, .v-c-info {
      margin-bottom: 8px;
      line-height: 20px;
      color: #{$secondary};
    }

    .v-subtitle {
      color: #{$secondary};
    }

    .v-c-register {
      height: 40px;
      color: #{$white};
    }

    .vernissage-registration-unavailable, .vernissage-registration-closed, .registration-closed, .vernissage-already-registered,
    .vernissage-registration-open-soon {
      margin-top: 3px;
      font-size: 14px;
      font-weight: #{$font-weight-medium};
      line-height: 20px;
    }
    /* Card end */

    /* modal-start */
    .annual-pass-checkbox {
      top: 0;
      min-width: 250px;
    }

    .modal-info-string, .annual-pass-ticket-desc, .registration-warning {
      font-size: 14px;
    }

    .register-button-list {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }

    .b-register {
      width: 100%;
      height: 40px;
      font-size: 14px;
    }

    .annual-pass-list {
      margin-top: 16px;
      margin-bottom: 30px;
    }

    .annual-pass-ticket-desc {
      /*margin-left: 10px;*/
      padding-top: 1px;
    }

    .modal-info-content-success {
      min-height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding-top: 40px;
    }

    .v-title {
      font-size: 17px;
    }

    .success-icon {
      width: 22px;
      height: 22px;
      margin-bottom: 19px;
    }

    .vernissage-info {
      margin-bottom: 50px;
    }

    li.add-to-calendar {
      border-top: solid 1px #{$erarta-light-gray};
    }
  }
</style>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  #register-modal .modal-content {
    border: none;
    border-radius: unset;
    padding: 0 60px 40px 60px;
  }

  #register-modal li.add-to-calendar a {
    font-size: 18px;
  }

  #register-modal .annual-pass-checkbox * {
    cursor: pointer !important;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    #register-modal .modal-content {
      padding: 0 20px 20px 20px;
    }

    #register-modal .modal-info-content {
      padding-top: 0;
      min-height: calc(100vh - 100px);
    }
  }

</style>
