import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue} from 'bootstrap-vue'
import {i18n} from './modules/locale'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import smoothscroll from 'smoothscroll-polyfill'
import tracker from '@/modules/track'
import './assets/styles/app.scss'
import VueSSE from 'vue-sse'

import VueCookies from 'vue-cookies'

import moment from 'moment'
import en from 'moment/locale/en-gb'
import ru from 'moment/locale/ru'

moment.defineLocale('en', en)
moment.defineLocale('ru', ru)

Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.use(VueCookies)
Vue.use(VueSSE)
store.dispatch('updateRecaptchaSiteKey')
  .then(() => Vue.use(VueReCaptcha, {siteKey: store.getters.recaptchaSiteKey}))
smoothscroll.polyfill()

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
  beforeRouteEnter(to, from, next)  {
    next(() => {
      if (!to.meta?.customTitle) {
        tracker.track(to, from)
      }
    })
  }
})

function _calculateScrollbarWidth() {
  document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px')
}
// recalculate on resize
window.addEventListener('resize', _calculateScrollbarWidth, false)
// recalculate on dom load
document.addEventListener('DOMContentLoaded', _calculateScrollbarWidth, false)
// recalculate on load (assets loaded as well)
window.addEventListener('load', _calculateScrollbarWidth)
