<template>
  <div class="row audio-guide-list" v-if="guideList && guideList !== {}" >
    <figure class="col-md-4 audio-guide-list-item" v-for="(guide,uri) in guideList" v-bind:key="uri">
      <router-link :to="guidePathPrefix + '/' + getGuideGuid(uri)">
        <div class="img-wrapper" >
        <img :src="guide.preview">
        <svg class="audio-guide-img-corner" viewBox="0 0 18 18"><rect :fill="guide.tooltipColor ? guide.tooltipColor : 'white'" x="0" y="0" width="18" height="18" /></svg>
      </div>
      <figcaption>{{guide.title}}</figcaption>
      </router-link>
      <p>{{guide.titleTooltip}}</p>
    </figure>
  </div>
</template>
<script>
  export default {
    props: {
      guideIndex: {
        type: Object,
        required: true
      },
      guideList: {
        type: Object,
        required: true
      },
      guidePathPrefix: {
        type: String,
        default: '/audioGuide'
      }
    },
    methods: {
      getGuideGuid(link) {
        return Object
          .entries(this.guideIndex.structure)
          .filter(([,v]) => v === link)
          .map(([k]) => k)[0]
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .audio-guide-img-corner {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: auto;
  }
  .audio-guide-list figure {
    position: relative;
    @supports not (aspect-ratio: 370 / 274) {
      .img-wrapper {
        padding-top: 74.05405405405405%;
      }
      img {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }

    .img-wrapper {
      position: relative;
    }

    img {
      object-fit: cover;
      aspect-ratio: 370 / 274;
      width: 100%;
    }

    figcaption {
      margin-top: 16px;
      margin-bottom: 13px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
</style>
