<template>
  <div class="vernissage-container" v-if="meetings?.length && localizedMeetings?.length" :style="{ left: offsetStyle }" role="list"
    :class="{ 'moving': swiping }"
    @touchend="onSwipeEnd" @touchmove="onSwipe" @touchstart="onSwipeStart">
    <b-modal id="register-meeting-modal" @close="closeRegisterModal" @hide="closeRegisterModal" body-class="p-0" hide-footer>
      <!--Регистрация-->
      <div class="modal-info-div" v-if="!register.registerFinished">
        <div class="modal-info-content">
          <p v-for="(val, index) in modalInfo" :key="index">{{val}}</p>
          <div class="modal-info-string v-title" v-if="register.selectedMeeting">
            {{register.selectedMeeting.title}}
          </div>
          <div class="modal-info-string v-subtitle" v-if="register.selectedMeeting">
            {{register.selectedMeeting.startDateFormatted}}
          </div>
          <div class="annual-pass-list">
            <template v-for="annualPass in activePasses">
              <b-form-checkbox
                v-if="!isAlreadyRegistered(annualPass)"
                :key="annualPass.annualPassGuid"
                :value="annualPass" v-model="register.selectedAnnualPasses" class="annual-pass-checkbox">
                <div class="annual-pass-ticket-desc">{{annualPass.owner}} (#{{annualPass.number}})</div>
              </b-form-checkbox>
            </template>
            <template v-for="ticket in activeTickets">
              <b-form-checkbox
                :key="ticket.number"
                v-if="!isAlreadyRegistered(ticket)"
                :value="ticket" v-model="register.selectedAnnualPasses" class="annual-pass-checkbox">
                <div class="annual-pass-ticket-desc">#{{ticket.number}}</div>
              </b-form-checkbox>
            </template>
            <div class="annual-pass-ticket-desc" v-if="!hasAnyAvailableMeeting">
              {{$t('Vernissage.no-annual-pass-available')}}
            </div>
          </div>
          <div class="registration-error" v-if="isRegistrationLimitExceeded(register.selectedMeeting)">
            {{$t('Vernissage.registration-limit-exceeded')}}
          </div>
          <div class="register-button-list">
            <b-button class="b-register" v-b-modal="'register-meeting-modal'"
              :disabled="(!register.selectedAnnualPasses || register.selectedAnnualPasses.length <= 0) ||
                (!hasAnyAvailableMeeting || isRegistrationLimitExceeded(register.selectedMeeting))
                || isProcessing"
              variant="primary" @click="onRegisterClick(false)">
              {{$t('Vernissage.modal-register-museum')}}
            </b-button>
            <b-button class="b-register" v-b-modal="'register-meeting-modal'"
              v-if="register.selectedMeeting && register.selectedMeeting.streamRegistrationEnabled"
              :disabled="(!register.selectedAnnualPasses || register.selectedAnnualPasses.length <= 0) ||
                !hasAnyAvailableMeeting || isProcessing"
              variant="outline-primary" @click="onRegisterClick(true)">
              {{$t('Vernissage.modal-register-for-stream')}}
            </b-button>
          </div>
        </div>
      </div>
      <!--Успешное завершение регистрации-->
      <div v-else-if="register.registerFinished && register.success" class="modal-info-div">
        <div class="modal-info-content-success">
          <div class="modal-info-container">
            <div class="d-flex flex-column">
              <inline-svg class="success-icon" :src="require('../assets/icons/success.svg')"/>
              <span class="modal-info-string v-registration-success">{{$t(register.registeredForStream ? 'Vernissage.registration-meeting-stream-success' : 'Vernissage.registration-meeting-museum-success')}}</span>
            </div>

            <div class="vernissage-info" v-if="register && register.selectedMeeting">
              <div class="modal-info-string v-title">
                {{register.selectedMeeting.title}}
              </div>
              <div class="modal-info-string v-subtitle">
                {{register.selectedMeeting.startDateFormatted}}
              </div>
            </div>
          </div>
          <b-dropdown-item link-class="external" class="modal-info-string add-to-calendar" href="#" @click="addToCalendar">
            {{$t('Vernissage.add-to-calendar')}}
          </b-dropdown-item>
        </div>
      </div>
      <!--Ошибка при регистрации-->
      <div v-else-if="register.registerFinished && !register.success" class="modal-info-div">
        <div class="modal-info-content-success">
          <div class="v-title">
            {{$t('Vernissage.registration-error')}}
          </div>
        </div>
      </div>
    </b-modal>

    <div class="v-card" :key="index" v-for="(meeting, index) in localizedMeetings" role="listitem">
      <img class="v-card-img" :src="meeting.image" alt="vernissage-img">
      <div class="v-card-body">
        <h3 class="v-c-title" aria-level="3">{{meeting.title}}</h3>
        <div class="v-c-event-type">{{ $t(`Vernissage.event-type-${meeting.type == null ? 'vernissage' : meeting.type}`)}}</div>
        <div class="v-c-date">{{meeting.startDateFormatted}}</div>
        <div class="v-c-info">{{meeting.info}}</div>

        <div class="vernissage-registration-unavailable" v-if="!registrationAvailable">
          {{$t('Vernissage.registration-unavailable')}}
        </div>
        <template v-else-if="isAlreadyRegisteredWithAnyAnnualPass(meeting)">
          <a :href="meeting.streamLink" class="event-stream-link" v-if="meeting.streamLink">{{ $t('Vernissage.stream-url') }}</a>
          <div class="vernissage-already-registered" v-else>
            <!-- TODO: провести различие между  -->
            {{ $t(isAlreadyRegisteredForStream(meeting)
              ? 'Vernissage.registered-for-stream'
              : 'Vernissage.meeting-registered-museum')}}
          </div>
        </template>
        <b-button v-else-if="registrationAvailable && meeting.isOpen" class="v-c-register"
          v-b-modal="'register-meeting-modal'" variant="primary"
          @click="setMeeting(meeting)">
          {{$t('Vernissage.meeting-register')}}
        </b-button>
        <div class="vernissage-registration-open-soon" v-else-if="meeting.isOpenSoon">
          {{$t('Vernissage.registration-open-soon')}}
        </div>
        <div class="vernissage-registration-closed" v-else-if="meeting.isClosed">
          {{$t('Vernissage.registration-closed')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import {createEvent} from 'ics'
  import vernissageApi from '../modules/vernissage'
  import InlineSvg from 'vue-inline-svg'
  import store from '@/store'

  export default {
    name: 'RegisterForMeeting',
    components: {InlineSvg},
    props: {
      offset: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        register: {
          selectedMeeting: undefined,
          selectedAnnualPasses: [],
          registerFinished: false,
          registeredForStream: false,
          success: false,
          // registerFinished: true,
          // success: true,
        },
        isProcessing: false,
        startSwipeOffset: 0,
        swipeOffset: 0,
        swiping: false,
      }
    },
    created () {
      moment.locale(this.$i18n.locale)
    },
    mounted() {
      // TODO: актуализировать путь
      this.fetchMeetingList()
    },
    computed: {
      modalInfo() {
        let val = this.$root.$t(this.register.selectedMeeting?.streamRegistrationEnabled ? 'Vernissage.modal-meeting-info' : 'Vernissage.modal-meeting-info-no-stream')
        if (Array.isArray(val)) {
          return val
        }
        return [val]
      },
      localizedMeetings() {
        return this.meetings
              .map(meeting => meeting[this.$i18n.locale])
              .map(meeting => {
                const startDate = moment(meeting?.startDate)
                const registrationStartDate = moment(meeting?.registrationStartDate)

                const hoursBeforeCloseRegistration = meeting?.hoursBeforeCloseRegistration
                // закрывать регистрацию за X часов до начала
                const gap = 60 * 60 * 1000 * hoursBeforeCloseRegistration

                const dateWithGap = moment.unix((startDate.valueOf() - gap) / 1000)
                const currentDate = moment()

                // Проверка квоты на регистрацию
                const registrationLimit = meeting.registrationLimit
                const registrationsCount = (typeof meeting.guid === 'string') ?
                                           this.meetingsRegistrationInfo
                                               .filter(info => typeof info.meetingGuid === 'string')
                                               .filter(info => info.meetingGuid.toLowerCase() === meeting.guid.toLowerCase())
                                               .shift()?.registrationsCount : 0

                // дата регистрации > текущего дня либо галочка "registrationClosed"/либо квота достигнута/либо осталось <= X часов до начала вернисажа
                const isClosed = meeting.registrationClosed === 'true'
                              || (currentDate.isBetween(dateWithGap, startDate) || currentDate.isAfter(startDate))
                              || (parseInt(registrationsCount) >= registrationLimit)

                const isOpen = currentDate.isSameOrAfter(registrationStartDate) && !isClosed
                const isOpenSoon = currentDate.isBefore(registrationStartDate) && !isClosed && !isOpen
                return {
                  ...meeting,
                  startDateUnix: meeting.startDate ? startDate.unix() : null,
                  startDateFormatted: meeting.startDate ? startDate.format('DD MMMM YYYY, HH:mm') : '',
                  isOpen,
                  isOpenSoon,
                  isClosed
                }
              })
               .sort((e1, e2) => e1?.startDateUnix - e2?.startDateUnix)
      },
      hasAnyAvailableMeeting() {
        // Проверка наличия хотя бы одного подходящего абонемента для регистрации
        for (const annualPass of this.activePasses) {
          if (!this.isAlreadyRegistered(annualPass)) {
            return true
          }
        }// Проверка наличия хотя бы одного подходящего билета для регистрации
        for (const ticket of this.activeTickets) {
          if (!this.isAlreadyRegistered(ticket)) {
            return true
          }
        }
        return false
      },
      registrationAvailable() {
        return this.activePasses?.length > 0 || this.activeTickets?.length > 0
      },
      offsetStyle() {
        let breakPointProp = getComputedStyle(document.body)
          .getPropertyValue('--breakpoint-md')
        if (window.matchMedia(`(min-width: ${breakPointProp})`)?.matches) {
          return `calc((-100% / 3 - 15px) * ${this.offset} - ${this.swipeOffset ?? 0}px)`
        }

        return `calc((-100% + 20px) * ${this.offset} + ${20 - (this.swipeOffset ?? 0)}px)`
      },
      ...mapState({
        meetings: state => state.meetings.meetings,
        meetingsRegistrationInfo: state => state.meetings.registrationInfo
      }),
      ...mapGetters({
        activePasses: 'loyalty/activePasses',
        activeTickets: 'loyalty/activeTickets',
      }),
    },
    methods: {
      ...mapActions({
        fetchMeetingList: 'meetings/fetchMeetingList',
        forceLoadAnnualPassList: 'loyalty/forceLoadAnnualPassList',
        forceLoadTicketList: 'loyalty/forceLoadTicketList'
      }),
      setMeeting(vernissage) {
        this.register.selectedMeeting = vernissage
      },
      closeRegisterModal() {
        this.register = {
          selectedAnnualPasses: [],
          selectedMeeting: undefined,
          success: false,
          registerFinished: false
        }
      },
      isAlreadyRegisteredWithAnyAnnualPass(meeting) {
        // Проверка наличия привязки абонемента к верниссажу
        if (this.activePasses == null || this.activeTickets == null) {
          return false
        }
        const selectedMeetingGuid = meeting?.guid?.toLowerCase()
        if (!selectedMeetingGuid) {
          return false
        }
        for (const annualPass of this.activePasses) {
          if (annualPass?.events?.[selectedMeetingGuid]) {
            return true
          }
        }
        for (const ticket of this.activeTickets) {
          if (ticket?.events?.[selectedMeetingGuid]) {
            return true
          }
        }
        return false
      },
      isAlreadyRegisteredForStream(meeting) {
        // TODO: Проверка типа регистрации на встречу
        const selectedMeetingGuid = meeting?.guid?.toLowerCase()

        for (const annualPass of this.activePasses) {
          if (annualPass?.events?.[selectedMeetingGuid] === 'STREAM') {
            return true
          }
        }
        for (const ticket of this.activeTickets) {
          if (ticket?.events?.[selectedMeetingGuid] === 'STREAM') {
            return true
          }
        }
        return false
      },
      isAlreadyRegistered(annualPass) {
        // Проверка наличия привязки абонемента к верниссажу
        const selectedMeetingGuid = this.register?.selectedMeeting?.guid?.toLowerCase()
        return annualPass?.events?.[selectedMeetingGuid]
      },
      onRegisterClick(registerForStream) {
        const sendInfoRequest = {
          annualPassGuids: this.register.selectedAnnualPasses
                          ?.filter(ap => Boolean(ap.annualPassGuid))
                          ?.map(ap => ap.annualPassGuid),
          ticketNumbers: this.register.selectedAnnualPasses
                        ?.filter(ticket => !ticket.annualPassGuid)
                        ?.map(ticket => ticket.number),
          eventType: 'AUTHORS_MEETING',
          visitType: registerForStream ? 'STREAM' : 'MUSEUM',
          locale: this.$root.$i18n.locale
        }

        this.isProcessing = true

        vernissageApi.eventRegister(this.register.selectedMeeting?.guid, sendInfoRequest)
                     .then(() => {
                       this.register.success = true
                       this.register.registerFinished = true
                       this.register.registeredForStream = registerForStream
                       this.forceLoadAnnualPassList()
                       this.forceLoadTicketList()
                     })
                     .then(() => store.dispatch('auth/checkUnreadMessageCount'))
                     .catch(e => {
                       this.register.success = false
                       this.register.registerFinished = true
                       console.debug('meeting registration error', e.message)
                     })
                     .finally(() => this.isProcessing = false)
      },
      isRegistrationLimitExceeded(meeting) {
        const registrationsCount = this.meetingsRegistrationInfo?.
          filter(info => info?.guid === meeting?.guid)?.
          [0]?.registrationsCount

        return registrationsCount >= meeting?.registrationLimit
      },
      addToCalendar() {
        let startDate = moment(this.register.selectedMeeting?.startDate).toArray()
        // отступ месяца (от нуля)
        startDate[1] += 1
        let event = {
          start: startDate,
          startOutputType: 'local',
          startInputType: 'local',
          title: this.register.selectedMeeting?.title,
          url: window.location.origin + '/',
          productId: '-//erarta account',
          uid: `${this.register.selectedMeeting?.guid}@${window.location.hostname}`,
        }

        let eventData = createEvent(event)
        let fileURL = window.URL.createObjectURL(new Blob([eventData.value], {type: 'text/calendar'}))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'meeting.ics')
        document.body.appendChild(fileLink)
        fileLink.click()
      },
      onSwipeStart(event) {
        this.startSwipeOffset = event.changedTouches[0].pageX
        this.swipeOffset = 0
        this.swiping = true
      },
      onSwipe(event) {
        this.swipeOffset = this.startSwipeOffset - event.changedTouches[0].pageX
      },
      onSwipeEnd() {
        this.swiping = false
        if (this.swipeOffset > 20) {
          this.$emit('swiped-right', this.swipeOffset)
        } else if (this.swipeOffset < -20) {
          this.$emit('swiped-left', this.swipeOffset)
        }
        this.swipeOffset = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .vernissage-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% - (45px * 2))/3);
    gap: 40px 45px;
    padding-bottom: 80px;
    position: relative;
    overflow: visible;
    &:not(.moving) {
      transition: left 0.5s ease-out;
    }
  }

  /* Card start */
  .v-card {
    min-height: 526px;
    background: #{$white};
    display: grid;
    grid-template-rows: 274px auto;
  }

  .v-card-img {
    width: 100%;
    height: 274px;
    object-fit: cover;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .v-card-body {
    height: auto;
    grid-row-start: 2;
    grid-row-end: 2;
    display: grid;
    grid-template-rows: repeat(5, auto);
    padding: 16px;
  }

  .v-c-title, .add-to-calendar {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 24px;
  }

  .v-c-event-type, .v-c-date, .v-c-info {
    font-size: 14px;
    line-height: 20px;
    color: #49494A;
    font-weight: 500;
  }

  .v-c-event-type {
    margin-bottom: 12px;
  }

  .v-c-date {
    margin-bottom: 16px;
  }

  .v-c-info {
    margin-bottom: 22px;
  }

  .v-c-register {
    margin-top: auto;
    height: 50px;
    color: #{$white};
  }

  .vernissage-registration-unavailable, .vernissage-registration-closed, .registration-closed, .vernissage-already-registered,
  .event-stream-link,
  .vernissage-registration-open-soon {
    margin-top: 30px;
    font-size: 12px;
    font-weight: 500;
  }

  .vernissage-registration-unavailable {
    color: #{$gray-600};
  }

  .vernissage-registration-closed, .registration-closed, .vernissage-already-registered,
  .event-stream-link {
    color: #{$primary};
  }

  .vernissage-registration-open-soon {
    color: #F4BF00;
  }

  .event-stream-link {
    color: #{$primary};
  }

  .event-stream-link::after {
    content: url("../assets/icons/my-erarta-arrow.svg");
    padding-left: 10px;
    display: inline-block;
    vertical-align: middle;
    height: 12px; /*почему то игнорирует оригинал */
  }

  .vernissage-already-registered {
    color: #21995F;
  }

  /* Card end */

  /* modal start */
  .modal-info-container {
    padding: 0 9px;
  }

  .modal-info-content {
    padding-top: 60px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .modal-info-string {
    font-family: 'Graphik LCG', sans-serif;
  }

  .annual-pass-list {
    margin-top: 42px;
    margin-bottom: 33px;
  }

  .annual-pass-checkbox {
    min-width: 250px;
    top: 3px;
  }

  .annual-pass-ticket-desc {
    font-family: 'Graphik LCG', sans-serif;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
  }

  .registration-warning {
    margin-bottom: 30px;
    font-family: 'Graphik LCG', sans-serif;
    font-size: 18px;
    color: #{$secondary};
  }

  .registration-error {
    font-family: 'Graphik LCG', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 12px;
    color: #{$primary};
  }

  .register-button-list {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-items: center;
    column-gap: 26px;
  }

  .b-register {
    width: 268px;
    height: 50px;
    font-size: 14px;
    font-family: 'Graphik LCG', sans-serif;
    align-self: center;
  }
  /* modal end */

  /* modal success start */
  .success-icon {
    width: 44px;
    height: 44px;
    margin-bottom: 11px;
  }

  .v-title, .v-subtitle, .add-to-calendar > * {
    font-size: 19px;
    font-weight: 500;
    font-family: 'Graphik LCG', sans-serif;
    line-height: 24px;
  }

  .modal-info-string {
    &.v-title {
      margin-top: 26px;
    }
    &.v-subtitle {
      margin-top: 12px;
    }
  }

  .v-registration-success {
    font-size: 18px;
    line-height: 28px;
  }

  .v-subtitle {
    color: #49494A;
    font-size: 14px;
    line-height: 20px;
  }

  li.add-to-calendar {
    border-top: solid 2px #{$erarta-light-gray};
    padding-top: 20px;
    list-style: none;
    display: grid;
    justify-content: center;
  }

  .modal-info-content-success {
    min-height: 514px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .vernissage-info {
    margin-top: 26px;
    margin-bottom: 84px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  /* modal success end */

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .vernissage-container {
      grid-auto-flow: column;
      grid-auto-columns: calc(100% - 40px);
      column-gap: 20px;
      padding-bottom: 15px;
    }

    /* Card start */
    .v-card {
      width: auto; /* TODO: width: 280px? */
      min-height: 361px;
      max-height: 406px;
      grid-template-rows: 210px auto; /* TODO: 210px 151px */
    }

    .v-card-img {
      width: 100%;
      height: 210px;
    }

    .v-card-body {
      padding: 12px;
    }

    .v-c-title, .add-to-calender {
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 20px;
    }

    .v-c-event-type, .v-c-date, .v-c-info {
      margin-bottom: 8px;
      line-height: 20px;
      color: #{$secondary};
    }

    .v-subtitle {
      color: #{$secondary};
    }

    .v-c-register {
      height: 40px;
      color: #{$white};
    }

    .vernissage-registration-unavailable, .vernissage-registration-closed, .registration-closed, .vernissage-already-registered,
    .vernissage-registration-open-soon {
      margin-top: 3px;
      font-size: 14px;
      font-weight: #{$font-weight-medium};
      line-height: 20px;
    }
    /* Card end */

    /* modal-start */
    .annual-pass-checkbox {
      top: 0;
      min-width: 250px;
    }

    .modal-info-string, .annual-pass-ticket-desc, .registration-warning {
      font-size: 14px;
    }

    .register-button-list {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }

    .b-register {
      width: 100%;
      height: 60px;
      font-size: 14px;
    }

    .annual-pass-list {
      margin-top: 16px;
      margin-bottom: 30px;
    }

    .annual-pass-ticket-desc {
      /*margin-left: 10px;*/
      padding-top: 1px;
    }

    .modal-info-content-success {
      min-height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding-top: 40px;
    }

    .v-title {
      font-size: 17px;
    }

    .success-icon {
      width: 22px;
      height: 22px;
      margin-bottom: 19px;
    }

    .vernissage-info {
      margin-bottom: 50px;
    }

    li.add-to-calendar {
      border-top: solid 1px #{$erarta-light-gray};
    }
  }
</style>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  #register-meeting-modal .modal-content {
    border: none;
    border-radius: unset;
    padding: 0 60px 40px 60px;
  }

  #register-meeting-modal li.add-to-calendar a {
    font-size: 18px;
  }

  #register-meeting-modal .annual-pass-checkbox * {
    cursor: pointer !important;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    #register-meeting-modal .modal-content {
      padding: 0 20px 20px 20px;
    }

    #register-meeting-modal .modal-info-content {
      padding-top: 0;
      min-height: calc(100vh - 100px);
    }

  }

</style>
