import {AXIOS} from '@/modules/backend-config'

export default {
  getRecaptchaPublicKey() {
    return AXIOS.get('/recaptcha')
  },
  getUserInfo() {
    return AXIOS.post('/user')
  },
  recoverPassword(form) {
    return AXIOS.post('/recover', {
      email: form.email,
      captchaToken: form.captchaToken,
      locale: form.locale
    })
  },
  confirmPasswordRecovery(form) {
    return AXIOS.post('/confirmPasswordRecovery', {
      token: form.token,
      password: form.password
    })
  },
  signUp (form) {
    return AXIOS.post('/signup', {
      email: form.email,
      password: form.password,
      captchaToken: form.captchaToken,
      locale: form.locale
    })
  },
  changePassword(form) {
    return AXIOS.post('/changePassword', {
      oldPassword: form.oldPassword,
      newPassword: form.newPassword,
    })
  },
  confirmRegistration(token, locale) {
    return AXIOS.post('/confirmRegistration', {
      token: token,
      locale: locale
    })
  },
  getAnnualPassList() {
    return AXIOS.get('/annual_pass')
  },
  getTicketList() {
    return AXIOS.get('/one_time_ticket')
  },
  getOrderList() {
    return AXIOS.get('/order')
  },
  getAnnualPassTypeList() {
    return AXIOS.get('/annual_pass_types')
  },
  bindAnnualPass(number, ownerName) {
    return AXIOS.post(`/annual_pass/${number}/bind`, {
      ownerName
    })
  },
  unbindAnnualPass(number) {
    return AXIOS.post(`/annual_pass/${number}/unbind`)
  },
  performAutoRenewalSubscription(number) {//TODO: перетащить в membership
    return AXIOS.post(`/annual_pass/${number}/subscribe`)
  },
  performAutoRenewalUnsubscription(number) {
    return AXIOS.post(`/annual_pass/${number}/unsubscribe`)
  },
  sendGiftInfo(passId, giftIndex, passNumber, giftNameRU, giftNameEN, locale) {
    return AXIOS.post('annual_pass/gift', {
      passId: passId,
      giftIndex: giftIndex,
      passNumber: passNumber,
      giftNameRU: giftNameRU,
      giftNameEN: giftNameEN,
      locale: locale
    })
  }
}
