import router from '../router'

export default {
  handleClicks (event) {
    // ensure we use the link, in case the click has been received by a subelement
    let { target } = event
    while (target && target.tagName !== 'A') target = target.parentNode
    // handle only links that occur inside the component and do not reference external resources
    if (target && target.matches('.dynamic-content a:not([href*=\'://\'])') && target.href) {
      // some sanity checks taken from vue-router:
      // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
      const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
      // don't handle with control keys
      if (metaKey || altKey || ctrlKey || shiftKey) return
      // don't handle when preventDefault called
      if (defaultPrevented) return
      // don't handle right clicks
      if (button !== undefined && button !== 0) return
      // don't handle if `target="_blank"`
      if (target && target.getAttribute) {
        const linkTarget = target.getAttribute('target')
        if (/\b_blank\b/i.test(linkTarget)) return
      }
      // don't handle same page links/anchors
      const url = new URL(target.href)
      const to = url.pathname
      const urlSearchParams = new URLSearchParams(url.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (window.location.pathname !== to && event.preventDefault) {
        event.preventDefault()
        let routeObj = {
          path: to,
          hash: url.hash
        }
        if (params != null) {
          routeObj.query = params
        }
        router.push(routeObj)
      }
    }
  },
  joinQueryParams(obj){
    let str = []
    for (let p in obj)
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(p) && obj[p] != null) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return str.join('&')
  }
}
