<template>
  <div class="message-wrapper">
    <div class="w-100">
      <div class="message-title">
        <hr>
        <small class="msg-date">{{ message.creationDate | format }}</small>
        <h1 class="msg-subject">{{message['title_'+$i18n.locale]}}</h1>
        <hr>
      </div>
      <div class="message-body">
        <div v-html="message['body_'+$i18n.locale]"></div>
        <div v-html="message['qrcode_caption_'+$i18n.locale]"></div>
        <qrcode-vue v-if="message['qrcode_'+$i18n.locale]" :value="message['qrcode_'+$i18n.locale]" size="200" class="qr-code qr-code-desktop"></qrcode-vue>
        <img v-if="message['qrcode_'+$i18n.locale]" class="qr-icon" src="../assets/images/qr-zoom-icon.svg" @click="$refs.qrModal.show()">
      </div>
      <div v-html="message['footer_'+$i18n.locale]" class="message-footer"></div>
      <b-modal ref="qrModal" body-class="qr-code-modal" :hide-footer="true">
        <qrcode-vue class="d-flex justify-content-center qr-code" :value="message['qrcode_'+$i18n.locale]" ref="modal-qr-code"></qrcode-vue>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import messages from '@/modules/messages'
  import moment from 'moment'
  import QrcodeVue from 'qrcode.vue'
  import {mapActions} from 'vuex'

  export default {
    name: 'Message',
    props: ['id'],
    components: {QrcodeVue},
    data() {
      return {
        message: {},
        error: ''
      }
    },
    mounted() {
      this.loadMessage()
    },
    methods: {
      loadMessage() {
        messages.getMessage(this.id)
                .then(response => {
                  this.message = response.data
                  return response.data?.messageRead
                })
                .then(read => {
                  if (!read) {
                    messages.updateReadState(this.id).then(
                      () => this.updateUnreadCount()
                    )
                  }
                })
                .catch(error => this.error = error.response?.data?.message)
      },
      ...mapActions({updateUnreadCount: 'auth/checkUnreadMessageCount'})
    },
    filters: {
      format: function (date) {
        return moment(date).format('DD.MM.YYYY')
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

  hr {
    border: none;
    background-color: #{$erarta-light-gray};
    height: 2px;
    width: 100%;
    margin: 0;
  }

  .message-wrapper {
    padding-top: 120px;
  }

  .message-title {
    min-height: 102px;
    margin-bottom: 40px;
  }

  .msg-date, .msg-subject, .message-body {
    padding-left: 16px;
    padding-right: 16px;
  }

  .msg-subject {
    padding-top: 19px;
    padding-bottom: 18px;
  }

  .message-footer {
    margin-top: 100px;
    margin-bottom: 80px;
    color: #{$secondary};
  }

  .qr-code {
    margin-top: 26px;
  }

  .qr-icon {
    width: 50px;
    height: 50px;
  }

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  hr {
    height: 1px;
  }

  .message-wrapper {
    padding-top: 81px;
  }

  .message-title {
    min-height: 61px;
    margin-bottom: 12px;
  }

  .msg-date, .msg-subject, .message-body {
    padding: 0 3px;
  }

  .msg-subject {
    padding-top: 10px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 16px;
  }

  .message-footer {
    margin-top: 54px;
    margin-bottom: 30px;
  }

  .qr-code {
    margin-top: 23px;
  }

  .qr-code-desktop {
    display: none;
  }

  .qr-icon {
    padding-left: 2px;
  }
}

@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .qr-icon {
    display: none;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/_variables.scss";

.message-footer img {
  width: 100%;
}
.message-body a {
  color: #{$primary};
}
.message-body a:hover {
  opacity: .7;
  text-decoration: underline;
}

</style>
