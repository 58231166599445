import { render, staticRenderFns } from "./AudioGuideDemoHeader.vue?vue&type=template&id=28b7eaca"
import script from "./AudioGuideDemoHeader.vue?vue&type=script&lang=js"
export * from "./AudioGuideDemoHeader.vue?vue&type=script&lang=js"
import style0 from "./AudioGuideDemoHeader.vue?vue&type=style&index=0&id=28b7eaca&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports