<template>
  <div class="vote-header">
    <div class="vote-header-title">
      <img class="award-logo" v-if="icon" :src="icon">
      <page-title :title="title">
        <small v-if="subheading">{{ subheading }}</small>
      </page-title>
    </div>
    <div v-if="hasAnnualPass">
      <p v-if="description" class="vote-description" v-html="description"></p>
    </div>
    <div v-else class="d-flex flex-column buy-annual-pass">
      <a :href="membershipPurchaseUrl"
        class="btn btn-primary buy-annual-pass-button">{{$t('Common.myErarta_buyTickets')}}</a>
      <a class="details opacity-hover"
        :href="$t('Common.myErarta_details_link')">{{ $t('Common.myErarta_details') }}</a>
      <span class="has-ticket">{{ $t('Common.myErarta_alreadyHaveTicket') }}</span>
      <router-link class="link-ticket" to="/loyalty">
        <div class="font-weight-medium d-flex align-items-center">
          <span style="margin-right: 6px">{{ $t('Common.myErarta_link') }}</span>
          <inline-svg class="icon-expand" :src="require('../../assets/icons/my-erarta-arrow.svg')"/>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import {mapGetters, mapState} from 'vuex'
import common from '@/modules/common'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'VoteHeader',
  components: {PageTitle, InlineSvg},
  props: {
    data: {
      type: Object
    },
    hasAnnualPass: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    subheading: {
      type: String,
      required: false
    },
    description: {
      type: String
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    membershipPurchaseUrl() {
      return process.env.VUE_APP_MEMBERSHIP_PURCHASE_URL + '?' + common.joinQueryParams({
        'duration[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseDuration,
        'type[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseAnnualPassType,
        lang: this.$root.$i18n.locale,
      })
    },
    ...mapGetters({annualPassesUnsorted: 'loyalty/activePasses'}),
    ...mapState({annualPassesLoaded: state => state.loyalty.annualPassesLoaded}),
  },
}
</script>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .vote-header {
    margin-top: 90px;

    .vote-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .title-container {
      padding-top: 47px;
      padding-bottom: 0;

      .title {
        font-size: 70px;
        line-height: 1;
      }
    }

    .award-logo {
      height: 158px;
    }

    .buy-annual-pass-button {
      margin-top: 40px;
    }

    .vote-description {
      padding-top: 40px;
      width: 992px;
      margin: 0;
      line-height: 28px;
    }

    .vote-description p {
      display: inline-block;
      margin: 0;
    }

    .vote-description p a {
      text-decoration: underline;
    }

    .buy-annual-pass a {
      color: #{$primary};
    }

    .link-ticket {
      text-decoration: none !important;
      font-size: 16px;
    }

    .link-ticket:hover div {
      opacity: 0.7;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
    }

  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .vote-header {
      width: 100%;
      margin-top: 33px;

      .award-logo {
        height: 97px;
      }

      .buy-annual-pass-button {
        margin-top: 27px;
      }

      .title-container {
        width: 100%;
        padding-top: 20px;

        .title {
          font-size: 32px;
        }
      }


      .vote-description {
        padding-top: 27px;
        width: 100%;
        line-height: 24px;
      }

      .vote-description p {
        display: inline-block;
        padding-right: 0;
      }

      .vote-description p a {
        text-decoration: underline;
      }
    }
  }

</style>
