<template>
  <b-form class="form-email-password" @submit.prevent="onSubmit">
    <b-alert class="acc-error" variant="danger" :model-value="error != null && errorMessage != null" @dismissed="!error">{{ $t(errorMessage) }}</b-alert>
    <div class="form-email">
      <h2 class="form-title">{{ $t('EmailAndPassword.email') }}</h2>
      <label class="user-email my-0">{{ email }}</label>
      <img class="email-info align-self-center" v-b-modal="'email-info-modal-id'" src="../assets/icons/info.svg" alt="info-icon">
    </div>
    <h2 class="form-title change-passwd">{{ $t('EmailAndPassword.change_password') }}</h2>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.oldPassword" :type="passwordFieldOld.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldOld.hover = true" @mouseleave="passwordFieldOld.hover = false"
             @click="passwordFieldOld = switchVisibility(passwordFieldOld)" :src="passwordIcon(passwordFieldOld)">
      </div>
      <label class="form-input-string">{{ $t('EmailAndPassword.old_password') }}</label>
      <b-form-invalid-feedback :state="v$.form.oldPassword.$dirty ? !v$.form.oldPassword.required.$invalid : null">
        {{ $t('EmailAndPassword.field_old_errors_mandatory') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.newPassword" :type="passwordFieldNew.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldNew.hover = true" @mouseleave="passwordFieldNew.hover = false"
             @click="passwordFieldNew = switchVisibility(passwordFieldNew)" :src="passwordIcon(passwordFieldNew)">
      </div>
      <label class="form-input-string">{{ $t('EmailAndPassword.new_password') }}</label>
      <b-form-invalid-feedback :state="v$.form.newPassword.$dirty ? !v$.form.newPassword.required.$invalid : null">
        {{ $t('EmailAndPassword.field_new_errors_mandatory') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.newPassword.$dirty ? !v$.form.newPassword.minLength.$invalid : null">
        {{ $t('EmailAndPassword.field_new_errors_invalid') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.repeatPassword" :type="passwordFieldRep.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldRep.hover = true" @mouseleave="passwordFieldRep.hover = false"
             @click="passwordFieldRep = switchVisibility(passwordFieldRep)" :src="passwordIcon(passwordFieldRep)">
      </div>
      <label class="form-input-string">{{ $t('EmailAndPassword.repeat_password') }}</label>
      <b-form-invalid-feedback :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.required.$invalid : null">
        {{ $t('EmailAndPassword.field_repeat_errors_mandatory') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback
        :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.sameAsPassword.$invalid : null">
        {{ $t('EmailAndPassword.field_repeat_errors_invalid') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="isProcessing" class="acc-btn-submit">
      {{ $t('EmailAndPassword.save') }}
    </b-button>
    <b-modal id="email-info-modal-id" body-class="p-0" hide-footer>
      <div class="modal-info-div">
        <div class="modal-info-content">
          <img class="modal-info-icon" src="../assets/icons/info.svg" alt="info-icon">
          <span class="modal-info-string">{{ $t('EmailAndPassword.change_email_info') }}</span>
        </div>
      </div>
    </b-modal>
    <b-modal id="change-confirm-modal-id" body-class="p-0" hide-footer>
      <div class="modal-info-div">
        <div class="modal-info-content">
          <img class="modal-info-icon" src="../assets/icons/info.svg" alt="info-icon">
          <span class="modal-info-string">{{ $t('EmailAndPassword.change_password_success') }}</span>
        </div>
      </div>
    </b-modal>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {required, minLength, sameAs} from '@vuelidate/validators'
import account from '@/modules/account'

export default {
  name: 'MyEmailAndPassword',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
      isProcessing: false,
      error: null,
      email: null,
      passwordFieldOld: {
        type: 'password',
        hover: false,
        visible: false
      },
      passwordFieldNew: {
        type: 'password',
        hover: false,
        visible: false
      },
      passwordFieldRep: {
        type: 'password',
        hover: false,
        visible: false
      }
    }
  },
  validations() {
    return {
      form: {
        oldPassword: {
          required
        },
        newPassword: {
          minLength: minLength(6),
          required
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs(this.form.newPassword)
        }
      }
    }
  },
  mounted() {
    {
      let email = this.$store.state.auth.userData.email
      if (!email) {
        this.$router.push('/login')
      } else {
        this.email = email
      }
    }
  },
  computed: {
    errorMessage() {
      if (this.error == null) {
        return null
      }
      const fields = {
        'USER_NOT_FOUND': 'EmailAndPassword.error_invalid_previous_password',
      }
      return fields[this.error]
    }
  },
  methods: {
    onSubmit() {
      this.error = null
      this.isProcessing = true
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.isProcessing = false
        return
      }
      account.changePassword(this.form)
             .then(() => {
               this.$bvModal.show('change-confirm-modal-id')
               this.form.oldPassword = null
               this.form.newPassword = null
               this.form.repeatPassword = null
             })
             .catch(err => this.error = err.response?.data?.errorKind)
             .finally(() => this.isProcessing = false)
    },
    switchVisibility(field) {
      field.type = field.type === 'password' ? 'text' : 'password'
      return field
    },
    passwordIcon(passwordField) {
      if (passwordField.type === 'password') {
        if (passwordField.hover === true) {
          return require('@/assets/icons/eye-hover.svg')
        } else {
          return require('@/assets/icons/eye.svg')
        }
      } else {
        if (passwordField.type === 'text') {
          if (passwordField.hover === true) {
            return require('@/assets/icons/eye-slash-hover.svg')
          } else {
            return require('@/assets/icons/eye-slash.svg')
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .form-email {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 20px;
  }

  .form-title {
    display: block;
    font-size: 36px;
    font-weight: #{$font-weight-medium};
  }

  button[type='submit'] {
    display: block;
  }
  .user-email {
    display: block;
    font-size: 20px;
    font-weight: #{$font-weight-medium};
    color: #{$secondary};
    grid-column-start: 1;
  }

  .email-info {
    display: block;
    grid-row-start: 1;
    grid-column-start: 2;
    height: 33px;
    width: 33px;
    margin-left: auto;
    margin-right: 0;
  }

  .modal-info-icon {
    display: block;
    height: 33px;
    width: 33px;
    margin-left: 0;
    margin-right: auto;
  }

  .form-input-string {
    display: block;
    font-size: 14px;
    color: #{$secondary};
  }

  .form-email-password {
    a {
      color: #{$primary};
    }
    > .form-title.change-passwd {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }

  .modal-info-div {
    width: 100%;
    min-height: 458px;
    padding-top: 180px;
  }

  .modal-info-content {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .password-input{
    position: relative;
  }

  .toggle-icon{
    top: 20px;
    right: 20px;
    height: 20px;
    width: 26px;
    display: inline-block !important;
    position: absolute;
  }

  @media only screen and (min-width:  #{map-get($grid-breakpoints, md)}) {
    .form-group {
      margin-top: 28px;
    }
    button[type='submit'] {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width:  #{map-get($grid-breakpoints, md)}) {

    .error .form-email .form-input-string {
      width: 280px !important;
    }

    .form-email-password {
      width: 280px;
      > .form-title.change-passwd {
        margin-top: 40px;
        margin-bottom: 18px;
      }
    }

    .form-email {
      row-gap: 6px;
      .email-info {
        height: 24px;
        width: 24px;
      }
    }
    .form-title {
      font-size: 25px;
    }

    .form-input-string {
      font-size: 16px;
    }

    .user-email {
      font-size: 16px;
    }

    .modal-info-div {
      width: auto;
      min-height: 0;
    }

    .modal-info-content {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }

    .modal-info-string {
      font-size: 16px;
    }

    .toggle-icon{
      top: 11px;
      right: 11px;
      height: 18px;
      width: 24px;
    }

  }
</style>
