import axios from 'axios'

const AXIOS = axios.create({
  baseURL: '/account',
  timeout: 30000
})

const MEMBERSHIP_AXIOS = axios.create({
  baseURL: '/membership',
  timeout: 30000
})

const AUTH_AXIOS = axios.create({
  baseURL: '/auth',
  timeout: 30000
})

const MESSAGES_AXIOS = axios.create({
  baseURL: '/account/messages',
  timeout: 30000
})

const VERNISSAGE_AXIOS = axios.create({
  baseURL: '/account/event',
  timeout: 30000
})

const VOTE_AXIOS = axios.create({
  baseURL: '/voteapi',
  timeout: 30000
})

export { AXIOS, AUTH_AXIOS, MEMBERSHIP_AXIOS, MESSAGES_AXIOS, VERNISSAGE_AXIOS, VOTE_AXIOS }
