<template>
  <div v-if="annualPasses && tickets">
    <template v-if="annualPasses.length > 0">
      <b-container v-if="wrapContainer">
        <hr class="title-separator" />
      </b-container>
      <hr class="title-separator" v-else />
    </template>
    <template v-for="pass in annualPasses">
      <annual-pass-view v-bind:key="pass.id * 2" :loyalty-page-info="loyaltyPageInfo" :annual-pass="pass" :wrap-container="wrapContainer"/>
      <b-container v-if="wrapContainer" v-bind:key="pass.id * 2 + 1">
        <hr />
      </b-container>
      <hr v-bind:key="pass.id * 2 + 1" v-else />
    </template>
    <template v-if="annualPasses.length === 0 && tickets.length === 0">
      <b-container v-if="wrapContainer" class="d-flex flex-column">
        <a :href="membershipPurchaseUrl"
          class="btn btn-primary buy-annual-pass-button">{{$t('Common.myErarta_buyTickets')}}</a>
        <a class="details opacity-hover" :href="$t('Common.myErarta_details_link')">{{$t('Common.myErarta_details')}}</a>
        <p class="has-ticket">{{$t('Common.myErarta_alreadyHaveTicket')}}</p>
      </b-container>
      <div class="d-flex flex-column" v-else>
        <a :href="membershipPurchaseUrl"
          class="btn btn-primary buy-annual-pass-button">{{$t('Common.myErarta_buyTickets')}}</a>
        <a class="details opacity-hover" :href="$t('Common.myErarta_details_link')">{{$t('Common.myErarta_details')}}</a>
        <p class="has-ticket">{{$t('Common.myErarta_alreadyHaveTicket')}}</p>
      </div>
    </template>
  </div>
</template>
<script>
  import moment from 'moment'
  import AnnualPassView from '@/components/AnnualPassView'
  import { mapGetters } from 'vuex'
  import common from '@/modules/common.js'
  export default {
    components: {
      'annual-pass-view': AnnualPassView
    },
    computed: {
      membershipPurchaseUrl() {
        return process.env.VUE_APP_MEMBERSHIP_PURCHASE_URL + '?' + common.joinQueryParams({
          'duration[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseDuration,
          'type[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseAnnualPassType,
          // 'autoExtension': autoExtend,
          // 'category[]': isGift ? 'gift' : 'me',
          lang: this.$root.$i18n.locale,
          // yaId: yaMetrika !== undefined ? yaMetrika.counters().filter(x => x.webvisor)[0].id : ''
        })
      },
      annualPasses() {
        if (!this.annualPassesUnsorted) {
          return null
        }
        return [...this.annualPassesUnsorted]
                   .sort((firstPass, secondPass) => {
                     let firstExpires = moment(firstPass.expires).unix()
                     let secondExpires = moment(secondPass.expires).unix()
                     return firstExpires - secondExpires
                   })
      },
      ...mapGetters({annualPassesUnsorted: 'loyalty/annualPasses', tickets: 'loyalty/tickets'})
    },
    data() {
      return {
        purchaseFormHeight: null
      }
    },
    props: {
      loyaltyPageInfo: null,
      wrapContainer: {
        type: Boolean,
        default: false
      },
    }
  }
</script>
<style lang="scss">
  iframe.purchase-iframe {
    border: 0;
    width: 100%;
    height: 80vh;
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .buy-annual-pass-button {
      width: 100%;
    }
  }
</style>
