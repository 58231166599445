<template>
  <div class="msg-list-container">
    <router-link v-for="(message, index) in messages"
         v-bind:key="message.id"
         v-bind:class="{ unread: !message.messageRead }"
         class="d-flex flex-column msg-row-container w-100"
         :to="{ name: 'MessagePage', params: { id: message.id } }">
      <hr v-if="index === 0">
      <div class="msg-container">
        <small class="msg-date">{{ message.creationDate | format }}</small>
        <h2 class="msg-subject">{{message['title_'+$i18n.locale]}}</h2>
        <div class="msg-preview">{{message['preview_'+$i18n.locale]}}</div>
      </div>
      <hr>
    </router-link>
    <b-button class="w-100" variant="light" v-if="isMoreAvailable && messages.length" @click="loadPage">{{ $t('Messages.loadMore') }}</b-button>
    <div v-if="!messages.length" class="text-center">{{ $t('Messages.empty') }}</div>
  </div>
</template>

<script>
  import messages from '@/modules/messages'
  import moment from 'moment'
  import {mapActions} from 'vuex'

  export default {
    name: 'MessageList',
    data() {
      return {
        batchSize: 25,
        batchNumber: 0,
        messages: [],
        total: 0
      }
    },
    mounted() {
      this.loadPage()
    },
    methods: {
      loadMessageList(size, index) {
        messages.getMessageList(size, index)
                .then(response => {
                  this.batchNumber++
                  this.total = response.data?.total
                  this.messages = this.messages.concat(response.data?.data)
                  return this.updateUnreadCount()
                })
      },
      loadPage() {
        this.loadMessageList(this.batchSize, this.batchNumber)
      },
      ...mapActions({updateUnreadCount: 'auth/checkUnreadMessageCount'})
    },
    computed: {
      isMoreAvailable() {
        return this.messages.length < this.total
      }
    },
    filters: {
      format: function (date) {
        return moment(date).format('DD.MM.YYYY')
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.msg-list-container {
  margin-bottom: 80px;
}

.unread {
  background-color: var(--erarta-red-transparent);
}

hr {
  border: none;
  background-color: #{$erarta-light-gray};
  height: 2px;
  width: 100%;
  margin: 0;
}

.msg-row-container {
  justify-content: space-between;
  transition: 0.3s;
}

.msg-container {
  height: 100px;
}

.msg-row-container:hover {
  cursor: pointer;
}

.msg-date, .msg-subject, .msg-preview {
  padding: 0 16px;
}

.msg-subject {
  padding-top: 9px;
}

.msg-preview {
  padding-top: 4px;
  font-size: 18px;
  line-height: 28px;
  color: #{$secondary};
  font-style: italic;
}

.msg-subject, .msg-preview {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn {
  margin-top: 40px;
  border-radius: 3px;
  color: #{$dark} !important;
}

@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .msg-list-container {
    padding-top: 25px;
  }

  .msg-row-container:not(.unread):hover {
    background-color: var(--erarta-very-light-gray);
  }
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .msg-list-container {
    margin-bottom: 40px;
  }

  .msg-list-container a:hover {
    color: #{$dark};
  }

  hr {
    height: 1px;
  }

  .msg-container {
    height: 70px;
  }

  .msg-date, .msg-subject, .msg-preview {
    padding: 0 3px;
  }

  .msg-preview {
    padding-top: 7px;
    font-size: 10px;
    line-height: 10px;
  }
}
</style>
