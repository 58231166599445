<template>
  <div class="gift-banner">
    <p>{{$t('Loyalty.giftBanner_content')}}</p>
    <img src="../assets/images/big_gift.svg">
    <span class="text-right">{{$t('Loyalty.giftBanner_priceStart')}}</span>
  </div>
</template>
<script>
  export default {
    name: 'gift-banner'
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .gift-banner {
    display: grid;
    grid-template-columns: 5fr 2fr 2fr;
    grid-column-gap: 13%;
    align-items: center;
    background-color: #{$primary};
    color: white;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .gift-banner span,, .gift-banner p {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 1;
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .gift-banner {
      grid-template-columns: 4.5fr 2fr 2fr;
      grid-column-gap: 10%;
      padding-top: 0.25rem;
      padding-bottom: 0.75rem;
    }

    .gift-banner span, .gift-banner p {
      font-size: 10px;
      padding-top: 5px;
    }
  }
</style>
