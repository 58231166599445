import axios from 'axios'
import path from 'path'

export {fetchExhibitions, fetchDocument, fetchDocuments, fetchAnnualPass, fetchDocumentWithDynamicSitePath, getExportDocumentsPath }
// eslint-disable-next-line no-undef
const dataPrefix = process.env.VUE_APP_DATA_PREFIX
// eslint-disable-next-line no-undef
const exportPrefix = process.env.VUE_APP_EXPORT_PREFIX
// eslint-disable-next-line no-undef
const exportDocumentsPath = process.env.VUE_APP_EXPORT_PATH_TO_DOCUMENTS

const annualPassPath = process.env.VUE_APP_ANNUAL_PASS_PATH

function getExportDocumentsPath() {
  return path.join(exportPrefix, exportDocumentsPath)
}

async function fetchExhibitions() {
  return axios.get(`${dataPrefix}/exhibitions.json`)
}

async function fetchDocument(filename) {
  console.debug('loading file ', filename)
  return axios.get(`${exportPrefix}${exportDocumentsPath}/${filename}`)
}

export async function fetchDocumentWithCheckCache(filename) {
  console.debug('loading file ', filename)
  return axios.get(`${exportPrefix}${exportDocumentsPath}/${filename}`, {
    headers: {
      'Cache-Control': 'must-revalidate'
    },
  })
}

async function fetchAnnualPass(name) {
  console.debug('loading annual pass ', name)
  return axios.get(`${exportPrefix}${annualPassPath}/${name}.json`)
}

async function fetchDocuments(filenames) {
  const promises = []
  for (const filename of filenames) {
    promises.push(fetchDocument(filename))
  }
  return Promise.allSettled(promises)
}

async function fetchDocumentWithDynamicSitePath(filename) {
  console.debug('loading file ', filename)
  if (filename.startsWith(exportPrefix + exportDocumentsPath)) {
    return axios.get(`${filename}`)
  } else if (filename.startsWith(exportDocumentsPath)) {
    return axios.get(`${exportPrefix}${filename}`)
  }
  return axios.get(`${exportPrefix}${exportDocumentsPath}/${filename}`)
}
