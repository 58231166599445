<template>
  <b-container>
    <BuildingPlan class="mt-auto"
      back-link="/"
      :highlighted-fragment="selectedElement"
      :highlighted-additional-fragments="additionalFragments"
      @legend-item-clicked="onLegendClicked"
    />
  </b-container>
</template>

<script>
  import BuildingPlan from '../components/building_plan/BuildingPlan'

  export default {
    name: 'BuildingPlanPage',
    components: {BuildingPlan},
    computed: {
      selectedElement() {
        if (!this.$route.hash) {
          return null
        }
        let splittedHash = this.$route.hash.split('#')
        if (splittedHash.length > 1) {
          return splittedHash[1]
        } else {
          return null
        }
      }
    },
    data() {
      return {
        additionalFragments: []
      }
    },
    methods: {
      onLegendClicked(iconId, additionalIcons) {
        this.$router.push({
          hash: '#' + iconId
        })
        this.additionalFragments = additionalIcons
      }
    }
  }
</script>

<style scoped>

</style>
