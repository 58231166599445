<template>
  <b-container class="guide-page" v-if="(annualPasses || !isNormalUser) && guideIndex">
    <back-link to="/" :nav-text="$t('AudioGuides.audio_guide_list_back')"></back-link>
    <page-title :title="$t('AudioGuides.title')"></page-title>
    <template v-if="!hasActiveAnnualPass && isNormalUser">
      <p>{{$t('AudioGuides.no_annual_pass_description')}}</p>
      <router-link class="btn btn-primary buy-annual-pass-button" to="/loyalty">{{$t('AudioGuides.bind_pass_label')}}</router-link>
    </template>
    <h2 v-if="Object.keys(primaryGuideContent).length !== 0">{{$t('AudioGuides.audio_guide_primary_title')}}</h2>
    <audio-guide-list :guide-index="guideIndex[$root.$i18n.locale]" :guide-list="primaryGuideContent" />
    <h2 v-if="Object.keys(temporaryGuideContent).length !== 0">{{$t('AudioGuides.audio_guide_temporary_title')}}</h2>
    <audio-guide-list v-if="Object.keys(temporaryGuideContent).length !== 0" :guide-index="guideIndex[$root.$i18n.locale]" :guide-list="temporaryGuideContent" />
  </b-container>
</template>
<script>
  import AudioGuideList from '@/components/audio_guide/AudioGuideList'
  import PageTitle from '@/components/common/PageTitle'
  import { mapActions, mapGetters } from 'vuex'
  import {fetchDocument, fetchDocumentWithDynamicSitePath} from '@/modules/content-loader'
  import BackLink from '@/components/common/BackLink'
  export default {
    components : {
      BackLink,
      'page-title': PageTitle,
      'audio-guide-list': AudioGuideList
    },
    mounted() {
      this.initializeContent()
    },
    data() {
      return {
        guideIndex: null,
        primaryGuides: {},
        temporaryGuides: {},
      }
    },
    computed: {
      primaryGuideContent() {
        return Object.fromEntries(Object.entries(this.primaryGuides)
                                        .map(([k,v]) => [k, v[this.$root.$i18n.locale]]))
      },
      temporaryGuideContent() {
        if (!this.temporaryGuides) {
          return null
        }
        return Object.fromEntries(Object.entries(this.temporaryGuides)
                                        .map(([k,v]) => [k, v[this.$root.$i18n.locale]]))
      },
      ...mapGetters({
        annualPasses: 'loyalty/annualPasses',
        hasActiveAnnualPass: 'loyalty/hasActiveAnnualPass',
        isNormalUser: 'auth/userIsNormal'
      })
    },
    methods: {
      initializeContent() {
        this.loadAnnualPassList()
        fetchDocument('audio_guide/index.json').then(response => this.handleAudioGuideIndex(response.data))
      },
      handleAudioGuideIndex(indexContent) {
        this.guideIndex = indexContent
        let primaryUrls = Object
          .entries(this.guideIndex[this.$root.$i18n.locale].resources)
          .filter(([,v]) => v.regular)
          .map(([k]) => k)
        Promise.all(primaryUrls.map(url => url + '.json').map(fetchDocumentWithDynamicSitePath))
          .then(content => {
            this.primaryGuides = Object.fromEntries(content
              .map((e,i) => [primaryUrls[i], e.data]))
          })

        let temporaryGuideUrls = Object
          .entries(this.guideIndex[this.$root.$i18n.locale].resources)
          .filter(([,v]) => v.regular === false) // может быть null
          .map(([k]) => k)
        Promise.all(temporaryGuideUrls.map(url => url + '.json').map(fetchDocumentWithDynamicSitePath))
          .then(content => {
            this.temporaryGuides = Object.fromEntries(content
              .map((e,i) => [temporaryGuideUrls[i], e.data]))
          })
      },
      ...mapActions({loadAnnualPassList: 'loyalty/loadAnnualPassList' }),
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .audio-guide-list {
    margin-top: 60px;
  }

  .guide-page {
    h2 {
      font-size: 36px;
      line-height: 40px;
    }

    .back-link {
      display: block;
      margin-top: 26px;
    }

    .title-container {
      padding-top: 68px;
      padding-bottom: 80px;
    }

    .buy-annual-pass-button {
      margin-bottom: 40px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {

      h2 {
        font-size: 25px;
        line-height: 28px;
      }

      .title-container {
        padding-top: 26px;
        padding-bottom: 30px;
      }

      .back-link {
        margin-top: 28px;
      }
    }
  }
</style>
