<template>
  <div class="vote-detail-body">
    <img :src="objectData.image">
    <div :class="{'vote-detail-title': true, 'remove-gaps': !(votedForCurrentObject || voteAllowed)}">
      <h2>{{ $t('Common.sequential_number') }} {{objectData.identifier}}</h2>
      <template v-if="votedForCurrentObject">
        <div class="voted-for">
          <img class="success-icon" src="../../assets/icons/success.svg">
          <span class="success-text">{{ $t('Vote.voted') }}</span>
        </div>
        <b-button class="v-c-voted vote-button-success" variant="outline-primary" @click="goToWhatsNextPage">
          {{ $t('Vote.whats-next') }}
        </b-button>
      </template>
      <b-button
        v-else-if="voteAllowed"
        class="v-c-vote vote-button"
        variant="outline-primary"
        v-b-modal="'vote-modal'">
        {{ $t('Vote.vote') }}
      </b-button>
    </div>
    <hr/>
    <h3>{{objectData.author}}</h3>
    <h2>{{objectData.title}}</h2>
    <dl>
      <dt v-if="objectData.artForm">{{$t('Vote.description-art-form')}}</dt>
      <dd v-if="objectData.artForm">{{objectData.artForm}}</dd>
      <dt v-if="objectData.technique">{{$t('Vote.description-technique')}}</dt>
      <dd v-if="objectData.technique">{{objectData.technique}}</dd>

      <dt v-if="objectData.size">{{$t('Vote.description-size')}}</dt>
      <dd v-if="objectData.size">{{objectData.size}}</dd>
      <dt v-if="objectData.creationYear">{{$t('Vote.description-publication-year')}}</dt>
      <dd v-if="objectData.creationYear">{{objectData.creationYear}}</dd>
    </dl>
    <div v-html="objectData.description"></div>

    <b-modal :header-class="votedForCurrentObject ? 'without-close': ''" id="vote-modal" @close="closeVoteModal" @hide="closeVoteModal" body-class="vote-confirm-modal p-0" hide-footer>
      <img class="award-logo" v-if="icon" :src="icon">
      <div class="modal-info-content" v-if="!votedForCurrentObject">
        <span v-if="selectedAnnualPass" class="annual-pass-desc">{{
                                                                 selectedAnnualPass.owner
                                                                 }} (#{{ selectedAnnualPass.number }})</span>
        <span class="mb-3">{{ $t('Vote.modal-only-one') }}</span>
        <span>{{ $t('Vote.modal-your-choice') }}</span>
        <span class="vote-selected-info mb-3">
          {{ objectData.title }}, {{ objectData.author }}, №{{ objectData.identifier }}
        </span>
        <span class="vote-warning">{{ $t('Vote.modal-warn') }}</span>
        <b-alert :show="error != null" @dismissed="error = null" variant="danger">{{ error }}</b-alert>
        <b-button class="b-vote" :disabled="isProcessing" variant="primary" @click="onVoteClick()">
          {{ $t('Vote.modal-vote') }}
        </b-button>
      </div>
      <div class="modal-info-content" v-else>
        <span class="modal-title v-vote-success">{{ $t('Vote.modal-vote-success') }}</span>
        <b-button class="b-vote"
          variant="primary" @click="closeVoteModal()">
          {{ $t('Vote.modal-close') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import voteApi from '@/modules/vote'
  import store from '@/store'

  export default {
    name: 'VoteDetailBody',
    props: {
      processId: {
        type: Number,
        required: true
      },
      objectData: {
        type: Object,
        required: true
      },
      roundIndex: {
        type: Number
      },
      selectedAnnualPass: {
        type: Object
      },
      privilegedMode: {
        type: Boolean,
        default: false
      },
      voteAllowed: {
        type: Boolean,
        default: true
      },
      votedForCurrentObject: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        required: false,
        default: null
      },
    },
    data() {
      return {
        isProcessing: false,
        error: null
      }
    },
    methods: {
      closeVoteModal() {
        this.error = null
        this.$root.$emit('bv::hide::modal', 'vote-modal')
      },
      onVoteClick() {
        if (this.isProcessing) {
          return
        }

        this.isProcessing = true
        this.error = null

        if (this.privilegedMode) {
          voteApi
            .performVoteWithNumber(this.processId, this.objectData.identifier, this.roundIndex, this.annualPassNumber)
            .then(() => {
              this.$emit('voted')
            })
            .catch((error) => {
              if (typeof error.response?.data === 'string') {
                this.error = error.response?.data
              } else {
                this.error = error.response?.data ?? this.$t('Vote.modal-vote-error')
              }
            })
            .finally(() => this.isProcessing = false)
        } else {
          voteApi
            .performVote(this.processId, this.objectData.identifier, this.roundIndex, this.selectedAnnualPass.annualPassGuid)
            .then(() => {
              store.dispatch('auth/checkUnreadMessageCount')
              this.$emit('voted')
            })
            .catch((error) => {
              if (typeof error.response?.data === 'string') {
                this.error = error.response?.data
              } else {
                this.error = error.response?.data ?? this.$t('Vote.modal-vote-error')
              }
            })
            .finally(() => this.isProcessing = false)
        }
      },
      goToWhatsNextPage() {
        if (this.$te('Vote.whats-next-link')) {
          window.open(this.$t('Vote.whats-next-link'))
        }
      },
    },
    computed: {}
  }
</script>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .vote-detail-body {
    & > img {
      width: auto;
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-height: 468px;
    }

    .vote-detail-title {
      display: grid;
      margin-top: 40px;
      grid-template-columns: max-content 40px 1fr 47px max-content;
      grid-template-rows: auto;
      grid-template-areas: "title none1 voted-msg none2 vote-btn";
      align-items: center;
      justify-items: center;

      h2 {
        grid-area: title;
      }

      .voted-for {
        grid-area: voted-msg;
      }

      button {
        grid-area: vote-btn;
      }
    }

    hr {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    h2 {
      font-size: 36px;
      line-height: 40px;
    }

    h3 {
      font-size: 19px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .voted-for {
      width: max-content;
      color: #{$erarta-vote-success};
      font-weight: 500;

      display: inline-flex;
      align-items: center;

      img {
        width: 44px;
        height: 46px;
        margin-bottom: 0 !important;
      }

      span {
        font-size: 14px;
        margin-left: 20px;
      }
    }

    .vote-button, .vote-button-success {
      width: 268px;
    }

    dl {
      margin-top: 40px;
      margin-bottom: 40px;

      display: grid;
      grid-template-rows: auto auto;
      grid-auto-columns: fit-content(100%);
      grid-auto-flow: column;
      row-gap: 2px;
      column-gap: 40px;

      font-size: 14px;
      line-height: 20px;

      dt {
        font-weight: 400 !important;
        color: #{$secondary};
      }

      dd {
        margin-bottom: 0;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {

      & > img {
        max-height: 280px;
      }

      .vote-detail-title {
        width: 100%;
        margin-top: 26px;
        grid-template-columns: max-content 1fr;
        grid-template-areas: "title voted-msg" "vote-btn vote-btn";
        row-gap: 20px;

        .vote-button, .vote-button-success {
          width: 100%;
        }
        h2 {
          line-height: 28px;
        }

        &.remove-gaps {
          row-gap: 0;
          margin-bottom: 26px;
        }
      }

      hr {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 25px;
        line-height: 25px;
      }

      h3 {
        font-size: 19px;
        line-height: 20px;
      }

      .voted-for {
        justify-self: right;
        max-width: 162px;

        img {
          width: 34px;
          height: 36px;
        }

        span {
          font-size: 12px;
          margin-left: 16px;
        }
      }

      dl {
        grid-template-rows: auto auto 10px auto auto;
        grid-template-columns: 1fr 1fr;
        font-size: 14px;
        line-height: 24px;
        column-gap: 20px;

        $desc-mobile-order-rows: 1, 2, 1, 2, 4, 5, 4, 5;
        $desc-mobile-order-cols: 1, 1, 2, 2, 1, 1, 2, 2;
        @for $i from 1 to 9 {
          & > *:nth-child(#{$i}) {
            grid-row: nth($desc-mobile-order-rows, $i);
            grid-column: nth($desc-mobile-order-cols, $i);
          }
        }
      }
    }

    dl:empty {
      grid-template-rows: auto auto auto auto;
      row-gap: 0;
      margin-bottom: 0;
    }
  }
</style>
