import Vue from 'vue'
import Vuex from 'vuex'
import authModule from '@/store/authModule'
import loyaltyModule from '@/store/loyalty'
import orderModule from '@/store/order'
import accountApi from '@/modules/account'
import meetingModule from '@/store/meetings'
import vernissageModule from '@/store/vernissage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    recaptchaPublicKey: null
  },
  getters: {
    recaptchaSiteKey(state) {
      return state.recaptchaPublicKey
    }
  },
  mutations: {
    setRecaptchaSiteKey(state, payload) {
      state.recaptchaPublicKey = payload
    }
  },
  actions: {
    updateRecaptchaSiteKey({commit}) {
      return accountApi.getRecaptchaPublicKey()
        .then(resp => commit('setRecaptchaSiteKey', resp.data))
    }
  },
  modules: {
    auth: authModule,
    loyalty: loyaltyModule,
    order: orderModule,
    meetings: meetingModule,
    vernissages: vernissageModule,
  }
})
