import {MEMBERSHIP_AXIOS} from '@/modules/backend-config'

export default {
  performRenewal(guid, type, duration, subscribeForAutoRenewal, email, language) {
    let params = new URLSearchParams()
    params.append('annualPassGuid', guid)
    params.append('autoExtend', subscribeForAutoRenewal)
    params.append('type', type)
    params.append('duration', duration)
    params.append('email', email)
    params.append('lang', language == null ? 'ru' : language)
    return MEMBERSHIP_AXIOS.post('/renewal', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  performTypeChange(guid, type, email, language) {
    let params = new URLSearchParams()
    params.append('annualPassGuid', guid)
    params.append('type', type)
    params.append('email', email)
    params.append('lang', language == null ? 'ru' : language)
    return MEMBERSHIP_AXIOS.post('/type_change', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  performSubscriptionWithCardAssignment(userId, number, cardGuid, language) {
    let params = new URLSearchParams()
    if (userId) {
      params.append('userId', userId)
    }
    if (number) {
      params.append('cardNumber', number)
    } else {
      console.error('Номер карты не был предоставлен для подписки на автопродление')
    }
    if (cardGuid) {
      params.append('cardGuid', cardGuid)
    } else {
      console.error('Идентификатор карты не был предоставлен для подписки на автопродление')
    }
    params.append('lang', language == null ? 'ru' : language)
    return MEMBERSHIP_AXIOS.post('/subscribe', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  performPaymentMethodChange(userId, language) {
    let params = new URLSearchParams()
    params.append('userId', userId)
    params.append('lang', language == null ? 'ru' : language)

    return MEMBERSHIP_AXIOS.post('/change_payment_method', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}
