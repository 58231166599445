import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {fetchDocument} from './content-loader'

Vue.use(VueI18n)

export {i18n, updateMessages}

const stubMessages = {}
let locale = (localStorage.getItem('lang') || navigator.language.substring(0, 2))
if (locale !== 'ru' && locale !== 'en') {
  locale = 'ru'
}

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'ru',
  stubMessages,
  escapeParameterHtml: '#'
})

// eslint-disable-next-line no-unused-vars
async function updateMessages(callback = () => {}) {
  console.debug('loading i18n...')
  const {data} = await fetchDocument('account_messages/account_messages.html.json')
  i18n.setLocaleMessage('en', data['en']?.messages)
  i18n.setLocaleMessage('ru', data['ru']?.messages)
  console.debug('locale messages en', i18n.getLocaleMessage('en'))
  console.debug('loaded messages ru', i18n.getLocaleMessage('ru'))
  callback()
}
