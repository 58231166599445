<template>
  <div class="vote-page" v-if="voteData">
    <back-link class="container" to="/" :nav-text="$t('Common.back')"/>
    <template v-if="unauthorized">
      <b-container>
        <h3 class="mt-3">Пожалуйста, авторизуйтесь для доступа к данной странице</h3>
      </b-container>
    </template>
    <template v-else>
      <vote-header class="container" :icon="voteData[this.$i18n.locale].icon" :data="voteData[this.$i18n.locale]" :has-annual-pass="true"/>
      <vote-body v-if="numberValid" :data="voteData[this.$i18n.locale]" :privileged-mode="true"
        :selected-annual-pass="annualPass"
        :input-tab="startTab"
        @annual-pass-number-reset="resetNumber" />
      <div class="number-input-container" v-else-if="dataLoaded && !numberValid">
        <b-container >
          <b-row class="justify-content-center">
            <annual-pass-number-form
              @number-submit="numberSubmit"
              :preset-number="number"
              :annual-pass-non-existent="number && annualPassNonExistent"
              :annual-pass-already-voted="annualPassAlreadyVoted"
            />
          </b-row>
        </b-container>
      </div>
    </template>
  </div>
</template>

<script>
  import voteApi from '@/modules/vote'
  import BackLink from '@/components/common/BackLink'
  import VoteHeader from '@/components/vote/VoteHeader'
  import VoteBody from '@/components/vote/VoteBody'
  import AnnualPassNumberForm from '@/components/vote/AnnualPassNumberForm'
  import moment from 'moment'
  import {fetchDocument, fetchDocumentWithCheckCache} from '@/modules/content-loader'


  async function loadIndex() {
    let resp = await fetchDocument('vote/index.json')
    return resp?.data['ru']?.structure
  }

  async function loadContent(voteId) {
    let data = await loadIndex()
    if (data != null) {
      let keys = Object.keys(data)
      if (!keys.includes(voteId)) {
        throw 'there is no such voteId in the index'
      }
      let filename = String(data[voteId])
      filename = filename.split('/')
      filename = filename.splice(filename.length - 2)
      filename = filename.join('/')
      filename = filename + '.json'
      return (await fetchDocumentWithCheckCache(filename)).data
    }
  }

  async function checkVoteExistence(voteId, annualPassNumber) {
    // проверки на NULL нет, чтобы проверить наличие авторизации у пользователя сразу при загрузке
    try {
      // TODO: обработать корректно 404 (когда активноого абонемента нет) и другие ошибки
      let response = await voteApi.checkAnnualPassValidity(annualPassNumber)
      let proc = await voteApi.getVoteWithNumber(voteId, annualPassNumber)
      return {
        unauthorized: false,
        annualPassGuid: response.data?.annualPassGuid,
        ownerName: response.data?.ownerName,
        annualPassNonExistent: false,
        voteExists: Boolean(proc.data)
      }
    } catch (error) {
      if (error?.code === 'ECONNABORTED' || error?.response?.status === 401) {
        return {
          unauthorized: true
        }
      } else if (error?.response?.status === 404) {
        return {
          unauthorized: false,
          annualPassNonExistent: true,
        }
      } else {
        return {
          unauthorized: false,
          fetchError: true
        }
      }
    }
  }

  export default {
    name: 'PrivilegedVotePage',
    components: { BackLink, VoteHeader, VoteBody, AnnualPassNumberForm },
    props: {
      number: {
        type: String
      },
      voteId: {
        type: String,
        required: true
      },
      round: {
        type: Number,
      }
    },
    data: () => ({
      voteData: null,
      annualPassGuid: null,
      ownerName: null,
      fetchError: false,
      annualPassNonExistent: false,
      annualPassAlreadyVoted: false,
      // нужен только для перезагрузки данных через live-reload
      annualPassInfoLoaded: false,
      unauthorized: false,
    }),
    async mounted() {
      await this.initializeContent()
    },
    async beforeRouteEnter(to, from, next) {
      let voteData, contentFetchError
      let voteId = to.params?.voteId
      try {
        voteData = await loadContent(voteId)
      } catch {
        contentFetchError = true
      }
      let voteExistence = await checkVoteExistence(voteId, to.query.number)
      next( (vm) => {
        vm.voteData = voteData
        vm.fetchError = Boolean(voteExistence?.fetchError) || contentFetchError
        vm.unauthorized = Boolean(voteExistence?.unauthorized)
        vm.annualPassNonExistent = Boolean(voteExistence?.annualPassNonExistent)
        vm.annualPassAlreadyVoted = Boolean(voteExistence?.voteExists)
        vm.number = to.query.number
        vm.ownerName = voteExistence.ownerName
        vm.annualPassGuid = voteExistence.annualPassGuid
        vm.annualPassInfoLoaded = true
        if (vm.voteData) {
          document.title = vm.title
        }
      })
    },
    async beforeRouteUpdate(to, from, next) {
      let voteId = to.params?.voteId
      let contentFetchError
      if (voteId !== this.voteId) {
        try {
          this.voteData = await loadContent(voteId)
          document.title = this.title
        } catch {
          contentFetchError = true
        }
      }

      let voteExistence = await checkVoteExistence(voteId, to.query.number)
      this.fetchError = Boolean(voteExistence?.fetchError) || contentFetchError
      this.ownerName = voteExistence.ownerName
      this.annualPassGuid = voteExistence.annualPassGuid
      this.unauthorized = Boolean(voteExistence?.unauthorized)
      this.annualPassNonExistent = Boolean(voteExistence?.annualPassNonExistent)
      this.annualPassAlreadyVoted = Boolean(voteExistence?.voteExists)
      next()
    },
    computed: {
      numberValid() {
        return !(!this.number || this.annualPassNonExistent || this.annualPassAlreadyVoted)
      },
      title() {
        return this.voteData[this.$root.$i18n.locale].title
      },
      dataLoaded() {
        return this.voteData != null
      },
      annualPass() {
        if (!this.number) {
          return null
        }
        return {
          owner: this.ownerName,
          number: this.number,
          annualPassGuid: this.annualPassGuid,
        }
      },
      startTab() {
        if (this.round == null) {
          let nowTime = moment()
          return this.voteData[this.$i18n.locale].voteRounds.findIndex(round => {
            return nowTime.isBetween(moment(round.startDate), moment(round.endDate))
          })
        } else {
          const inputTab = parseInt(this.round)
          return inputTab > this.voteData[this.$i18n.locale]?.voteRounds?.length - 1 ? 0 : inputTab
        }
      },
    },
    methods: {
      async initializeContent() {
        let contentFetchError
        if (!this.voteData) {
          try {
            this.voteData = await loadContent(this.voteId)
            document.title = this.title
          } catch {
            contentFetchError = true
          }
        }

        if (!this.annualPassInfoLoaded) {
          let voteExistence = await checkVoteExistence(this.voteId, this.number)
          this.fetchError = Boolean(voteExistence?.fetchError) || contentFetchError
          this.unauthorized = Boolean(voteExistence?.unauthorized)
          this.annualPassNonExistent = Boolean(voteExistence?.annualPassNonExistent)
          this.annualPassAlreadyVoted = Boolean(voteExistence?.voteExists)
          this.annualPassGuid = voteExistence.annualPassGuid
          this.ownerName = voteExistence.ownerName
        } else {
          this.fetchError = contentFetchError
        }
      },
      numberSubmit(number) {
        this.$router.push({ path: `/privileged-vote/${this.voteId}`, query: { number: number }})
      },
      resetNumber() {
        this.$router.push({ path: `/privileged-vote/${this.voteId}`})
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .vote-page {
    .back-link {
      display: block;
      margin-top: 26px;
    }

    .number-input-container {
      background-color: var(--erarta-very-light-gray);
      margin-top: 80px;
      padding-top: 76px;
      padding-bottom: 210px;
    }
  }
</style>
