<template>
  <div class="order">
    <div v-b-toggle="collapse_id" class="order-title">
      <span class="order-number">{{$t('Order.number')}}: {{order.id}}</span>
      <div class="chevron">
        <inline-svg v-if="!collapsed" class="icon-expand" :src="require('../../assets/icons/expand.svg')"/>
        <inline-svg v-if="collapsed" class="icon-collapse" :src="require('../../assets/icons/collapse.svg')"/>
      </div>
    </div>
    <b-collapse :id="collapse_id">
      <div v-if="collapsed">
        <div class="mt-3"/>
        <form v-if="order.checkPayment && order.paymentPermitted"
          class="form-inline payment-form"
          method="POST"
          :action="$t(`Order.payLink_${order.paymentType}`)" :ref="'sberbank-form-' + order.id">
          <input type="hidden" name="OrderNumber" :value="order.id">
          <input type="hidden" name="Language" value="ru">
          <button type="submit"
            class="btn btn-primary sberbank-form-submit" :data-order-id="order.id">{{ $t('Order.pay') }}
          </button>
        </form>
        <dl class="order-desc">
          <dt>{{$t('Order.date')}}</dt>
          <dd>{{ order.date }}</dd>
          <dt>{{$t('Order.status')}}</dt>
          <dd>{{$t('Order.status_' + order.status)}}</dd>
          <dt v-if="order.paymentType">{{$t('Order.payment_type')}}</dt>
          <dd v-if="order.paymentType">{{ $t('Order.payment_type_' + order.paymentType) }}</dd>
          <div class="mt-3"/>
          <div v-if="!order.annualPass">
            <dt v-if="order.deliveryType">{{$t('Order.delivery_type')}}</dt>
            <dd v-if="order.deliveryType">{{$t('Order.delivery_type_' + order.deliveryType)}}</dd>
          </div>
          <dt v-if="order.address">{{$t('Order.address')}}</dt>
          <dd v-if="order.address">{{order.address}}</dd>
          <div class="mt-3"/>
          <dt>{{$t('Order.receiver')}}</dt>
          <dd>{{order.receiver}}</dd>
          <dt>{{$t('Order.phone')}}</dt>
          <dd>{{order.phone}}</dd>
        </dl>
        <order-detail :order="order"></order-detail>
      </div>
    </b-collapse>

    <hr class="title-separator">
  </div>
</template>

<script>
import OrderDetailView from '@/components/order/OrderDetailView'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'OrderView',
  components: {
    'order-detail': OrderDetailView,
    InlineSvg
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    collapse_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      collapsed: false
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === this.collapse_id) {
        this.collapsed = isJustShown
      }
    })
  }
}
</script>

<style scoped lang="scss">
.order-title {
  display: flex;
  margin-bottom: 30px;
}
.chevron {
  align-items: center;
  margin-left: auto;
  display: flex;
}
.chevron > svg {
  height: 18px;
  width: 36px;
}
.order-desc {
  margin-bottom: 50px;
}

.order-number {
  font-size: 36px;
  color: var(--erarta-black);
  font-weight: 500;
}

.order {
.title-separator {
  margin-top: 40px;
}
}

.payment-form {
  float: right;
  width: max-content;

  button[type=submit] {
    padding: 8px 50px;
  }
}

@media (max-width: 1023px) {
  .order-number {
    font-size: 25px;
    line-height: 25px;
  }
  .chevron > svg {
    width: 12px;
    height: 6px;
  }
  .order-title {
    margin-bottom: 17px;
  }
  .order-desc {
    margin-bottom: 25px;
    font-size: 14px;
  }
}
</style>
