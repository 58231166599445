<template>
  <div class="d-flex flex-column flex-1">
    <b-container>
      <page-title :title="$t('Authentication.login_header')"></page-title>
    </b-container>
    <div class="acc-container justify-content-center d-flex">
        <b-row class="acc-form-row">
          <b-col md="auto">
            <erarta-login :from="from" />
          </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
  import Login from '@/components/Login'
  import PageTitle from '@/components/common/PageTitle'

  export default {
    name: 'LoginPage',
    components: { 'erarta-login': Login, PageTitle },
    props: {
      from: {
        type: String,
        default: '/'
      }
    }
  }
</script>
