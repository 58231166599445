<template>
  <div class="guide-exhibit-content">
    <h5>{{exhibitContent.author}}</h5>
    <h2>{{exhibitContent.title}}</h2>
    <small>аудиогид:</small>
    <h6>{{guideContent.title}}</h6>
    <div v-html="exhibitContent.description"></div>
    <trackable-audio :soundFile="exhibitContent.soundFile"></trackable-audio>
    <exhibit-nav :previous-link="previousLink" :next-link="nextLink"/>
    <search-form @submit="e => $emit('number-submitted', e)"/>
  </div>
</template>
<script>
import SearchForm from './SearchForm'
import ExhibitNavigation from './ExhibitNavigation'
import TrackableAudio from '@/components/TrackableAudio'

export default {
    components: {
      'search-form': SearchForm,
      'exhibit-nav': ExhibitNavigation,
      'trackable-audio': TrackableAudio
    },
    props: {
      guideIndex: {
        type: Object,
        required: true,
      },
      guideContent: {
        type: Object,
        required: true
      },
      exhibitContent: {
        type: Object,
        required: true
      },
      guideGuid: {
        type: String,
        required: true
      },
      previousLink: {
        type: String,
        required: false
      },
      nextLink: {
        type: String,
        required: false
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .guide-exhibit-content {
    h2 {
      margin-top: 8px;
      margin-bottom: 40px;
      font-size: 36px;
      line-height: 40px;
    }
    small {
      font-size: 14px;
      line-height: 20px;
      text-transform: lowercase;
      margin-bottom: 6px;
      color: #{$secondary};
    }
    h6 {
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      text-transform: lowercase;
      font-weight: normal;
    }
    audio {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
      display: block;
    }
    .guide-exhibit-navigation:not(:empty) {
      margin-top: 40px;
      margin-bottom: 70px;
    }
    .guide-exhibit-navigation:empty {
      margin-top: 40px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      h5 {
        font-size: 19px;
        line-height: 20px;
      }
      h2 {
        margin-bottom: 11px;
        font-size: 36px;
        line-height: 40px;
        font-size: 25px;
        line-height: 1;
      }
      small {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 2px;
      }
      h6 {
        margin-bottom: 29px;
        font-size: 14px;
        line-height: 24px;
      }

      audio {
        width: 100%;
        margin-top: 17px;
        margin-bottom: 22px;
      }
      .guide-exhibit-navigation:not(:empty) {
        margin-top: 22px;
        margin-bottom: 55px;
      }
      .guide-exhibit-navigation:empty {
        margin-top: 22px;
      }
    }
  }
</style>
