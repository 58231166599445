<template>
  <div class="guide-banner">
    <img class="banner-background" :src="backgroundImageUrl">
    <b-container>
      <back-link :to="backLink" :nav-text="backTitle"></back-link>
      <small v-if="category">{{category}}</small>
      <h1>{{title}}</h1>
    </b-container>
  </div>
</template>
<script>
  import BackLink from '@/components/common/BackLink'
  export default {
    components: {
      BackLink
    },
    props: {
      title: {
        type: String,
        required: true
      },
      backgroundImageUrl: {
        type: String,
        required: true
      },
      backTitle: {
        type: String,
        required: false
      },
      backLink: {
        type: String,
        required: false
      },
      category: {
        type: String,
        required: false
      }
    }
  }
</script>
<style lang="scss" >
  @import "@/assets/styles/_variables.scss";

  .guide-banner {
    position: relative;
    height: 520px;
    z-index: 0;
    .banner-background {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    > .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      .back-link {
        margin-top: 26px;
        width: max-content;
      }
      .back-link:not(:hover) {
        color: #{$white};
        svg {
          fill: #{$white};
        }
      }

      small {
        color: #{$primary};
        background: #{$white};
        font-size: 12px;
        font-weight: #{$font-weight-medium};
        margin-top: auto;
        margin-bottom: 4px;
        padding: 0px 10px 0px 10px;
        line-height: 30px;
        width: max-content;
      }

      h1 {
        margin-bottom: 60px;
        margin-left: -3px;
        color: #{$white};
        font-size: 64px;
        line-height: 1;
      }

    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      height: 320px;
      .back {
        font-size: 0;
        margin-top: 28px;
      }
      > .container {
        h1 {
          margin-left: -2px;
          margin-bottom: 16px;
          font-size: 32px;
        }
        small {
          margin-bottom: 11px;
        }
      }
    }
  }
</style>
