export default {
  calculateTourThreshold(objects, tourIndex) {
    return [25, 10, 5][tourIndex]
  },
  calculatePrizeMoney(placeIndex) {
    if (placeIndex === 0 && placeIndex > 4) {
      return null
    }

    return [200000, 175000, 150000, 125000][placeIndex - 1]
  },
  calculateRemovedItems(tourIndex) {
    return tourIndex * 15
  }
}
