<template>
  <b-container>
    <div class="partner-border"></div>
    <div class="text-center title">{{ $t('Common.myPossibilities_partners') }}</div>
    <div class="partners mt-4 text-center" :data-count="data.length">
      <component :key="index" :is="partner.link !== '' ? 'a' : 'div'" :href="partner.link !== '' ? partner.link : null" class="partner" v-for="(partner, index) in data">
        <img :src="partner.logo" alt="p1">
      </component>
    </div>
  </b-container>
</template>

<script>
  export default {
    name: 'Partners',
    props: {
      data: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .partner-border{
    border-top: solid 2px #C4C4C4;
  }

  .partners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .partners > .partner {
    margin-bottom: 60px;
  }

  .partner img {
    height: 90px;
  }

  .partner {
    flex: 1 0 33%;
  }

  .partners[data-count='2'] .partner, .partners[data-count='4'] .partner {
    flex: 1 0 50%;
  }

  .partners[data-count='5'] .partner {
    flex: 1 0 33%;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 42px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .partners > .partner {
      /*padding: 30px !important;*/
      margin-bottom: 30px !important;
    }

    .partner img {
      height: 41.12px;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .partners > .partner {
      margin-bottom: 30px !important;
    }
  }

</style>
