<template>
  <div class="vote-page">
    <back-link class="container" to="/" :nav-text="$t('Common.back')"/>
    <vote-header class="container" v-if="dataLoaded"
      :title="voteData[this.$i18n.locale].title"
      :icon="voteData[this.$i18n.locale].icon"
      :description="voteData[this.$i18n.locale].description"
      :has-annual-pass="annualPasses?.length > 0 || oneTimePasses?.length > 0"/>
    <vote-body v-if="dataLoaded"
      class="vote-body"
      :data="voteData[this.$i18n.locale]"
      :annual-passes="selectablePasses"
      :input-tab="startTab"
      :selected-annual-pass="selectedAnnualPass"
      @annual-pass-changed="val => { selectedAnnualPass = val }"
    />
  </div>
</template>

<script>
  import BackLink from '@/components/common/BackLink'
  import VoteHeader from '@/components/vote/VoteHeader'
  import VoteBody from '@/components/vote/VoteBody'
  import {fetchDocument, fetchDocumentWithCheckCache} from '@/modules/content-loader'
  import moment from 'moment/moment'
  import store from '@/store'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import tracker from '@/modules/track'


  async function loadIndex() {
    let resp = await fetchDocument('vote/index.json')
    return resp?.data['ru']?.structure
  }

  async function loadContent(voteId) {
    let data = await loadIndex()
    if (data != null) {
      voteId = String(voteId)
      let keys = Object.keys(data)
      if (!keys.includes(voteId)) {
        throw 'there is no such voteId in the index'
      }
      let filename = String(data[voteId])
      filename = filename.split('/')
      filename = filename.splice(filename.length - 2)
      filename = filename.join('/')
      filename = filename + '.json'
      return (await fetchDocumentWithCheckCache(filename)).data
    }
  }

  export default {
    name: 'VotePage',
    components: {BackLink, VoteHeader, VoteBody,},
    props: {
      round: {
        type: Number
      },
      voteId: {
        required: true,
        type: Number
      },
      number: {
        type: String,
        default: null
      },
    },
    data: () => ({
      selectedAnnualPass: null,
      voteData: null,
      fetchError: false,
    }),
    async beforeRouteEnter(to, from, next) {
      let voteData, fetchError
      let voteId = to.params?.voteId
      try {
        let ap = store.dispatch('loyalty/loadAnnualPassList')
        let tickets = store.dispatch('loyalty/loadTicketList')
        voteData = (await Promise.all([loadContent(voteId), ap, tickets]))[0]
      } catch {
        fetchError = true
      }

      if (fetchError) {
        next(from)
      }

      next( (vm) => {
        vm.voteData = voteData
        vm.fetchError = fetchError
        if (vm.voteData) {
          document.title = vm.title
        }
        tracker.track(to, from)
      })
    },
    async beforeRouteUpdate(to, from, next) {
      let voteId = to.params?.voteId
      if (voteId !== this.voteId) {
        try {
          this.voteData = await loadContent(voteId)
          document.title = this.title
        } catch {
          this.fetchError = true
        }

        tracker.track(to, from)
      }
      next()
    },
    async mounted() {
      if (!this.voteData) {
        await this.initializeContent()
      }
      if (!this.annualPasses) {
        await this.loadAnnualPassList()
      }
      if (!this.oneTimePasses) {
        await this.loadOneTimePassList()
      }
      if (!this.selectedAnnualPass && this.selectablePasses && this.selectablePasses.length > 0) {
        if (this.number) {
          // выбрать только абонементы, одноразовый билет тут учитывать не буду
          this.selectedAnnualPass = this.annualPasses.find(ap => ap.annualPassGuid && ap.number === this.number)
        }
        if (!this.selectedAnnualPass) {
          this.selectedAnnualPass = this.selectablePasses[0]
        }
      }
    },
    computed: {
      dataLoaded() {
        return this.voteData != null && this.passesLoaded
      },
      title() {
        return this.voteData != null ? this.voteData[this.$i18n.locale].title : null
      },
      selectablePasses() {
        if (!this.annualPasses) {
          return this.oneTimePasses
        } else if (!this.oneTimePasses) {
          return this.oneTimePasses
        }
        return this.annualPasses.concat(this.oneTimePasses)
      },
      annualPasses() {
        if (!this.annualPassesUnsorted) {
          return null
        }
        return [...this.annualPassesUnsorted]
          .sort((firstPass, secondPass) => moment(firstPass.expires).unix() - moment(secondPass.expires).unix())
      },
      oneTimePasses() {
        if (!this.oneTimePassesUnsorted) {
          return null
        }
        return [...this.oneTimePassesUnsorted]
          .sort((firstPass, secondPass) => moment(firstPass.expires).unix() - moment(secondPass.expires).unix())
      },
      startTab() {
        if (this.round == null) {
          let nowTime = moment()
          return this.voteData[this.$i18n.locale].voteRounds.findIndex(round => {
            return nowTime.isBetween(moment(round.startDate), moment(round.endDate))
          })
        } else {
          const inputTab = parseInt(this.round)
          return inputTab > this.voteData[this.$i18n.locale]?.voteRounds?.length - 1 ? 0 : inputTab
        }
      },
      ...mapGetters({annualPassesUnsorted: 'loyalty/activePasses', oneTimePassesUnsorted: 'loyalty/activeTickets'}),
      ...mapState({passesLoaded: state => state.loyalty.annualPassesLoaded && state.loyalty.ticketsLoaded}),
    },
    methods: {
      async initializeContent() {
        if (!this.voteData) {
          try {
            this.voteData = await loadContent(this.voteId)
            document.title = this.title
          } catch {
            this.fetchError = true
          }
        }
      },
      ...mapActions({
        loadAnnualPassList: 'loyalty/loadAnnualPassList',
        loadOneTimePassList: 'loyalty/loadTicketList'
      }),
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .vote-page {
    .back-link {
      display: block;
      margin-top: 26px;
    }
    .vote-body {
      margin-top: 33px;
    }


    @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .vote-body {
        margin-top: 40px;
      }
    }
  }
</style>
