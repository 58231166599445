<template>
  <div v-if="tickets" class="ticket-list">
    <template v-for="pass in tickets">
      <ticket-card v-bind:key="pass.id * 2" :loyalty-page-info="loyaltyPageInfo" :data="pass"/>
      <hr v-bind:key="pass.id * 2 + 1" />
    </template>
  </div>
</template>
<script>
  import moment from 'moment'
  import TicketCard from '@/components/TicketCard'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      'ticket-card': TicketCard
    },
    mounted() {
      this.loadTicketList()
    },
    computed: {
      tickets() {
        if (!this.ticketsUnsorted) {
          return null
        }
        return [...this.ticketsUnsorted]
          .sort((firstPass, secondPass) => {
            let firstExpires = moment(firstPass.expires).unix()
                     let secondExpires = moment(secondPass.expires).unix()
                     return firstExpires - secondExpires
                   })
      },
      ...mapGetters({ticketsUnsorted: 'loyalty/tickets'})
    },
    data() {
      return {}
    },
    methods: {
      ...mapActions({loadTicketList: 'loyalty/loadTicketList' }),
    },
    props: {
      loyaltyPageInfo: null,
      wrapContainer: {
        type: Boolean,
        default: false
      },
    }
  }
</script>
<style lang="scss">
  iframe.purchase-iframe {
    border: 0;
    width: 100%;
    height: 80vh;
  }
</style>
