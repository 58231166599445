<template>
<div class="trackable-audio">
  <audio controls controlsList="nodownload" :src="soundFile" />
</div>
</template>

<script>
export default {
  props: {
    soundFile: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    // We're going to save our players status on this object.
    audios_status: {}
  }) ,
  mounted() {
    this.trackAudio()
  },
  methods: {
    trackAudio() {
      let divisor = 10
      // We're going to save our players status on this object.

      // We need to configure the listeners
      // Let's grab all the the "audio" objects on the current page
      let audios = document.getElementsByTagName('audio')
      for (let i = 0; i < audios.length; i++) {
        // In order to have some id to reference in our status object, we are adding an id to the audio objects
        // that don't have an id attribute.
        let audioTagId
        if (!audios[i].getAttribute('id')) {
          // Generate a random alphanumeric string to use is as the id
          audioTagId = 'html5_audio_' + Math.random().toString(36).slice(2)
          audios[i].setAttribute('id', audioTagId)
        }// Current audio has alredy a id attribute, then let's use it ?
        else {
          audioTagId = audios[i].getAttribute('id')
        }
        // audio Status Object declaration
        this.audios_status[audioTagId] = {}
        // We'll save the highest percent mark played by the user in the current audio.
        this.audios_status[audioTagId].greatest_marker = 0
        // Let's set the progress markers, so we can know afterwards which ones have been already sent.
        this.audios_status[audioTagId]._progress_markers = {}
        for (let j = 0; j < 100; j++) {
          this.audios_status[audioTagId].progress_point = divisor * Math.floor(j / divisor)
          this.audios_status[audioTagId]._progress_markers[this.audios_status[audioTagId].progress_point] = false
        }
        // On page DOM, all players currentTime is 0
        this.audios_status[audioTagId].current = 0
        // Now we're setting the event listeners.
        audios[i].addEventListener('play', this.eventHandler, false)
        audios[i].addEventListener('pause', this.eventHandler, false)
        audios[i].addEventListener('ended', this.eventHandler, false)
        audios[i].addEventListener('timeupdate', this.eventHandler, false)
      }
    },

    // This is the funcion that is gonna handle the event sent by the player listeners
    eventHandler(e) {
      if ('dataLayer' in window) {
        let dataLayer = window.dataLayer
        switch (e.type) {
          // This event type is sent everytime the player updated it's current time,
          // We're using for the % of the audio played.
          case 'timeupdate': {
            // Let's set the save the current player's audio time in our status object
            this.audios_status[e.target.id].current = Math.round(e.target.currentTime)
            // We just want to send the percent events once
            let pct = Math.floor(100 * this.audios_status[e.target.id].current / e.target.duration)
            for (let j in this.audios_status[e.target.id]._progress_markers) {
              if (pct >= j && j > this.audios_status[e.target.id].greatest_marker) {
                this.audios_status[e.target.id].greatest_marker = j
              }
            }
            // current bucket hasn't been already sent to GA?, let's push it to GTM
            if (this.audios_status[e.target.id].greatest_marker && !this.audios_status[e.target.id]._progress_markers[this.audios_status[e.target.id].greatest_marker]) {
              this.audios_status[e.target.id]._progress_markers[this.audios_status[e.target.id].greatest_marker] = true
              if (dataLayer) {
                dataLayer.push({
                  event: 'audio',
                  audio: {
                    audio_action: 'Progress %' + this.audios_status[e.target.id].greatest_marker,
                    audio_file: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]).toString()
                  }
                })
              }
            }
            break
          }

          // This event is fired when user's click on the play button
          case 'play':
            if (dataLayer) {
              dataLayer.push({
                event: 'audio',
                audio: {
                  audio_action: 'play',
                  audio_file: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]).toString()
                }
              })
            }
            break
            // This event is fied when user's click on the pause button
          case 'pause':
            if (dataLayer) {
              dataLayer.push({
                event: 'audio',
                audio: {
                    audio_action: 'pause',
                  audio_file: decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]).toString()
                }
              })
            }
            break
            // If the user ends playing the audio, an Finish audio will be pushed ( This equals to % play$(document).ready( function() ed = 100 )
          case 'ended':
            if (dataLayer) {
              dataLayer.push({
                event: 'audio',
                audio: {
                  audio_action: 'finished',
                  audio_file:   decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]).toString()
                }
              })
            }
            break
          default:
            break
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
