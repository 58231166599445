<template>
  <b-form class="renewal-form" @submit.prevent="onSubmit" v-if="availableProducts">
    <b-alert class="acc-error" variant="danger" v-if="sberbankError != null && sberbankError.errorCode != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_interaction_error',{ errorCode: sberbankError.errorCode,  errorMessage: sberbankError.errorMessage }) }}</b-alert>
    <b-alert class="acc-error" variant="danger" v-else-if="sberbankError != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_http_error', { statusCode: sberbankError.statusCode })}}</b-alert>
    <b-form-group :label="$t('Loyalty.renewal_numberLabel')">
      <b-form-input :placeholder="$t('Loyalty.renewal_numberLabel')" readonly :value="value.number"/>
    </b-form-group>
    <b-form-group :label="$t('Loyalty.renewal_typeLabel')">
      <b-form-select :placeholder="$t('Loyalty.renewal_typeLabel')" v-model="v$.selectedType.$model" :options="availableTypes" />
    </b-form-group>
    <b-form-group class="price-group" v-if="typeChangeCost != null">
      <span class="price-description font-weight-medium">{{$t('Loyalty.renewal_cost_label')}}</span> <span class="font-weight-medium">{{$t('Loyalty.renewal_cost_value', [typeChangeCost])}}</span>
    </b-form-group>
    <b-button variant="primary" :disabled="!typeChangeButtonAvailable" class="d-block w-100" type="submit">{{$t('Loyalty.renewal_submit')}}</b-button>
    <b-modal ref="type-change-payment" dialog-class="renewal-modal" size="xl" v-if="paymentUrl"
      body-class="p-0"
      :hide-footer="true"
      :title="$t('Loyalty.renewal_payment_title')">
      <iframe :src="paymentUrl" class="renewal-iframe" />
    </b-modal>
  </b-form>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import {mapActions, mapGetters} from 'vuex'
  import membershipApi from '@/modules/membership'
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      loyaltyPageInfo: {
        type: Object,
        default: null,
        required: false
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(val, oldVal) {
          if ((oldVal == null && val != null || val?.type !== oldVal?.type) &&
              (this.selectedType === val?.type || this.selectedType == null)) {
            this.selectedType = this.availableTypes?.[0]?.value
          }
        }
      }
    },
    validations: {
      selectedType: {
        required
      }
    },
    setup() {
      return { v$: useVuelidate() }
    },
    computed: {
      availableTypes() {
        let titleField = this.$i18n.locale === 'ru' ? 'titleRu' : 'titleEn'
        return this.availableProducts
                      .filter(apt => apt.type !== this.value.type)
                      .map(apt => {
                        return {
                          text: apt[titleField],
                          value: apt.type
                        }
                      })
      },
      typeChangeCost() {
        return this.availableProducts
                   .filter(apt => apt.type === this.selectedType &&
                                apt.duration === 1 &&
                                // это можно и не сравнивать т.к. метод выше это уже исключает
                                this.value.type !== apt.type)
                   .map(apt => {
                     return apt.typeChangePrice
  })[0]
      },
      ...mapGetters({
        availableProducts:'loyalty/availableAnnualPassTypesForTypeChange',
        email: 'auth/userEmail'
      })
    },
    data() {
      return {
        subscribeForAutoRenewal: false,
        termsAccepted: false,
        selectedType: null,
        paymentUrl: null,
        typeChangeButtonAvailable: true,
        giftPicked: null,
        sberbankError: null
      }
    },
    methods: {
      onSubmit() {
        this.v$.$touch()
        if (this.v$.$invalid) {
          return
        }
        if (!this.typeChangeButtonAvailable) {
          return
        }
        this.sberbankError = null
        this.typeChangeButtonAvailable = false
        membershipApi.performTypeChange(this.value.annualPassGuid, this.selectedType, this.email, this.$i18n.locale)
                     .then((resp) => {
                       this.paymentUrl = resp.data.redirectUrl
                       this.$nextTick(() => {
                         this.$refs['type-change-payment'].show()
                       })
                     })
                     .catch((e) => {
                       if (e.response && typeof e.response.data === 'object' && 'sberbankErrorCode' in e.response.data) {
                         this.sberbankError = {
                           statusCode: e.response.data.sberbankStatusCode,
                           errorCode: e.response.data.sberbankErrorCode,
                           errorMessage: e.response.data.sberbankErrorMessage
                         }
                       }
                     })
                     .finally(() => {
                       this.typeChangeButtonAvailable = true
                     })
      },
      ...mapActions({
        sendGiftInfo: 'loyalty/sendGiftInfo'
      })
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  iframe.renewal-iframe {
    border: 0;
    width: 100%;
    height: 80vh;
  }

  .renewal-form {
    .custom-select,
    .form-control {
      font-weight: #{$font-weight-medium};
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: #{$white};
    }
    .form-group {
      margin-bottom: 20px;
    }
    .custom-control {
      margin-bottom: 8px;
    }
    .form-group + .price-group {
      margin-top: -8px;
    }
    .price-group {
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 24px;
      .price-description {
        font-size: 16px;
      }
      del {
        color: #{$secondary};
      }
    }

    button[type='submit'] {
      margin-top: 30px;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    .renewal-form {
      .form-group {
        margin-bottom: 26px;
      }
      .form-group + .price-group {
        margin-top: -6px;
      }
      button[type='submit'] {
        margin-top: 40px;
      }
      .price-group,
      .custom-control {
        margin-bottom: 20px;
      }
      .custom-control-input, .custom-control-label::before, .custom-control-input:checked ~ .custom-control-label::before, .custom-control-label::after, .custom-control-input:checked ~ .custom-control-label::after {
        top: 0.125rem;
      }
      .custom-control-label {
        line-height: 24px;
      }
      margin-bottom: 0;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .renewal-form {
      margin-bottom: 0;
    }
  }
</style>
