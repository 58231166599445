<template>
  <component :is="to ? 'router-link': 'a'" class="back-link" @click="to ? {} : $router.go(-1)" :to="to">
    <img v-if="icon" :src="icon">
    <inline-svg v-else :src="require('../../assets/icons/back-link.svg')"/>
    <span class="mobile-hidden" v-if="navText">{{navText}}</span>
    <slot></slot>
  </component>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

  export default {
    components: {
      InlineSvg
    },
    name: 'BackLink',
    props: {
      icon: {
        type: String
      },
      to: {
        type: [String, Object],
      },
      navText: {
        type: String
      }
    }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  .back-link {
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: #{$font-weight-medium};
    color: #{$dark};
    display: block;
    position: relative;
  }
  .back-link svg {
    fill: black;
  }
  .back-link:hover {
    color: #{$primary};
    text-decoration: none;
  }
  .back-link:hover svg {
    fill: #{$primary};
  }
  img,svg {
    margin-right: 15px;
  }
  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    img, svg {
      margin-right: 0;
    }

    .mobile-hidden {
      display: none;
    }
  }
</style>
