<template>
  <div class="title-row">
    <div class="title-container">
      <h1 class="title" v-bind:class="customClass">{{ title }}<b-badge>{{ badge }}</b-badge></h1>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'customClass', 'badge'],
  name: 'PageTitle'
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
.title-row {
  background-color: var(--erarta-white);
}

.title-container {
  padding-top: 120px;
  padding-bottom: 30px;
  pointer-events: none;
}

.main-page-title > .title-container {
  padding-top: 30px;
}

.title {
  font-size: 64px;
  margin-bottom: 0;
}

.badge {
  font-weight: #{$font-weight-medium};
  font-size: 40px;
  line-height: 50px;
  border-radius: 4px;
  padding: 0 8px;
  background-color: #{$dark};
  vertical-align: 20%;
  min-width: 50px;
  height: 50px;
  margin-left: 25px;
}

@media (max-width: 1023px) {
  .title {
    font-size: 32px;
  }
  .title-container,
  .main-page-title > .title-container{
    padding-top: 38px;
  }
  .registration {
    font-size: 28px;
  }
  .badge {
    font-size: 20px;
    line-height: 24px;
    padding: 0 6px;
    border-radius: 2px;
    vertical-align: 2.5px;
    height: 24px;
    min-width: 24px;
    margin-left: 7px;
  }
}
</style>
