<template>
  <div>
    <img :src="privilegeInfo.image">
    <span class="text-center">{{privilegeInfo.text}}</span>
  </div>
</template>
<script>
  export default {
    props: {
      privilegeInfo: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style lang="scss" scoped>

  @import "@/assets/styles/_variables.scss";
  span {
    font-size: 10px;
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    span {
      font-size: 18px;
    }
  }
</style>
