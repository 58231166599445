<template>
  <div class="floor-plan">
    <template  v-for="floor in floors">
      <b-button class="collapser px-0" @click="visibleIndices[floor.index] = !visibleIndices[floor.index]"
        variant="link" :key="floor.index * 2">
        <i18n path="AudioGuides.audio_guide_floor_plan_label" :tag="false">
          <template v-slot:index>{{floor.index}}</template>
          <template #suffix><b-icon-chevron-up v-if="visibleIndices[floor.index]"/><b-icon-chevron-down v-else/></template>
        </i18n>
      </b-button>
      <b-collapse v-model="visibleIndices[floor.index]" :key="floor.index * 2 + 1" >
      <img class="mw-100" :src="floor.image">
      <div v-html="floor.description" v-if="floor.description"/>
    </b-collapse>
    </template>
  </div>
</template>
<script>
  import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'
  export default {
    props: {
      floors: {
        type: Array,
        required: true
      }
    },
    components: {
      BIconChevronUp, BIconChevronDown
    },
    mounted() {
      this.floors?.forEach(f => {
        this.$set(this.visibleIndices, f.index, f.visible)
      })
    },
    data() {
      return {
        visibleIndices: {}
      }
    },
    watch: {
      floors: {
        handler() {
          this.floors.forEach(f => {
            this.$set(this.visibleIndices, f.index, f.visible)
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .floor-plan {
    .btn.collapser {
      margin-top: 20px;
      margin-bottom: 25px;
      display: block;
    }
    .btn.collapser:first-child {
      margin-top: 32px;
    }

    .collapse > div {
      margin-top: 10px;
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .btn.collapser {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 7px;
      }
    }
  }
</style>
