<template>
  <div class="vote-detail-page">
    <div class="overlay" v-if="dataLoaded">
      <b-container class="d-flex">
        <back-link :to="{ path: `/vote/${voteId}`, query: { number: annualPassNumber, round: roundIndex + 1 }, hash: `#obj_${roundIndex + 1}_${voteObjectId}` }" :nav-text="$t('Common.back')"/>
        <span class="vote-score ml-auto"
          v-if="currentResults || currentResults.fraction || currentResults.fraction === 0">{{ (currentResults.fraction * 100).toFixed(2) + '%' }}</span>
      </b-container>
    </div>
    <vote-detail-body class="container small-container" v-if="dataLoaded"
      :process-id="voteData[this.$i18n.locale].identifier"
      :object-data="voteObjectData"
      :selected-annual-pass="annualPass"
      :voted-for-current-object="votedForCurrentObject"
      :vote-allowed="voteAllowed"
      :round-index="roundIndex"
      :icon="voteData[this.$i18n.locale].icon"
      @voted="onVoteRegistered"
    />
    <vote-detail-footer v-if="dataLoaded"
      :object-data="voteObjectData"/>
    <img class="award-logo" v-if="dataLoaded && voteData[this.$i18n.locale].icon" :src="voteData[this.$i18n.locale].icon">
  </div>
</template>
<script>
  import {fetchDocument, fetchDocumentWithCheckCache} from '@/modules/content-loader'
  import BackLink from '@/components/common/BackLink'
  import VoteDetailFooter from '@/components/vote/VoteDetailFooter'
  import VoteDetailBody from '@/components/vote/VoteDetailBody'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import moment from 'moment'
  import voteApi from '@/modules/vote'
  import tracker from '@/modules/track'

  async function loadIndex() {
    let resp = await fetchDocument('vote/index.json')
    return resp?.data['ru']?.structure
  }

  async function loadContent(voteId) {
    let data = await loadIndex()
    if (data != null) {
      let keys = Object.keys(data)
      if (!keys.includes(voteId)) {
        throw 'there is no such voteId in the index'
      }
      let filename = String(data[voteId])
      filename = filename.split('/')
      filename = filename.splice(filename.length - 2)
      filename = filename.join('/')
      filename = filename + '.json'
      return (await fetchDocumentWithCheckCache(filename)).data
    }
  }

  export default {
    name: 'VoteDetailedPage',
    components: {BackLink, VoteDetailBody, VoteDetailFooter},
    props: {
      round: {
        type: Number,
      },
      annualPassNumber: {
        type: String,
      },
      voteId: {
        required: true,
        type: Number
      },
      voteObjectId: {
        required: true,
        type: Number
      },
    },
    data: () => ({
      currentResults: 0,
      voteData: null,
      fetchError: false,
      annualPass: null,
      votedForCurrentObject: false,
      voteAllowed: false,
    }),

    async beforeRouteEnter(to, from, next) {
      let voteData, fetchError
      let voteId = to.params?.voteId
      try {
        voteData = await loadContent(voteId)
      } catch {
        fetchError = true
      }

      if (fetchError) {
        next(from)
      }

      next( (vm) => {
        vm.voteData = voteData
        vm.fetchError = fetchError

        if (vm.voteData) {
          document.title = vm.title
        }

        tracker.track(to, from)
      })
    },
    async beforeRouteUpdate(to, from, next) {
      let voteId = to.params?.voteId
      if (voteId !== this.voteId) {
        try {
          this.voteData = await loadContent(voteId)
          document.title = this.title
        } catch {
          this.fetchError = true
        }

        tracker.track(to, from)
      }
      next()
    },
    async mounted() {
      await this.initializeContent()
      await this.loadAnnualPassList()
      let nowTime = moment()
      let canVoteInTour = nowTime.isBetween(moment(this.voteData[this.$i18n.locale].voteRounds[this.roundIndex].startDate), moment(this.voteData[this.$i18n.locale].voteRounds[this.roundIndex].endDate))
      if (!this.annualPassNumber) {
        // выбор первого непроголосовавшего абонемента
        for (const annualPass of this.annualPassesUnsorted) {
          let voteResult = await this.checkVote(this.annualPass.annualPassGuid)

          this.voteAllowed = voteResult.votingAllowed && canVoteInTour
          this.votedForCurrentObject = this.voteObjectData?.identifier === voteResult.objectCode

          this.annualPass = annualPass

          if (this.voteAllowed) {
            break
          }
        }
      } else {
        this.annualPass = this.annualPassesUnsorted
                              .find(ap => ap.number === this.annualPassNumber)
        let voteResult = await this.checkVote(this.annualPass.annualPassGuid)
        this.voteAllowed = voteResult.votingAllowed && canVoteInTour
        this.votedForCurrentObject = this.voteObjectData?.identifier === voteResult.objectCode
      }
      this.updateResults()
    },
    computed: {
      dataLoaded() {
        return this.voteData != null && this.annualPassesLoaded
      },
      title() {
        return this.voteData != null ? this.voteData[this.$i18n.locale].title : null
      },
      roundIndex() {
        let nowTime = moment()
        if (this.round == null && this.voteData[this.$i18n.locale].voteRounds) {
          // автоматический выбор текущего (доступного для голосования) тура
          return this.voteData[this.$i18n.locale].voteRounds.findIndex(round => {
            return nowTime.isBetween(moment(round.startDate), moment(round.endDate))
          })
        } else {
          return this.round
        }
      },
      voteObjectData() {
        return this.voteData[this.$i18n.locale].voteObjects
                   .find(obj => obj.identifier === this.voteObjectId)
      },
      ...mapGetters({annualPassesUnsorted: 'loyalty/activePasses'}),
      ...mapState({annualPassesLoaded: state => state.loyalty.annualPassesLoaded}),
    },
    methods: {
      async initializeContent() {
        if (!this.voteData) {
          try {
            this.voteData = await loadContent(this.voteId)
            document.title = this.title
          } catch {
            this.fetchError = true
          }
        }
      },
      async checkVote(annualPassGuid) {
        let response = await voteApi.getVote(this.voteId, annualPassGuid, this.roundIndex)
        return {
          votingAllowed: !response.data,
          objectCode: response.data?.objectCode
        }
      },
      onVoteRegistered() {
        this.votedForCurrentObject = true
        this.voteAllowed = false
        setTimeout(() => this.updateResults(), 5000)
      },
      updateResults() {
        voteApi.getCurrentResults(this.voteId)
               .then(response => this.currentResults = response.data?.tours?.[this.roundIndex]?.[this.voteObjectId] ?? 0)
      },
      ...mapActions({loadAnnualPassList: 'loyalty/loadAnnualPassList'}),
    }
  }
</script>
<style lang="scss" >
  @import "@/assets/styles/_variables.scss";

  .vote-detail-page {
    margin-top: 40px;
    position: relative;

    .overlay {
      position: absolute;
      width: 100%;
      top: 0;
    }
    .award-logo {
      height: 158px;
      margin-top: 70px;
      margin-bottom: 81px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .vote-score {
      width: 75px;
    }

    .vote-detail-footer {
      margin-top: 80px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      margin-top: 28px;

      .award-logo {
        height: 97px;
        margin-top: 50px;
        margin-bottom: 50px;
      }

      .overlay {
        position: static;
        width: 100%;
        margin-bottom: 43px;
      }

      .vote-detail-footer {
        margin-top: 40px;
      }
    }
  }
</style>
