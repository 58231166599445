<template>
  <footer class="mt-auto footer d-flex align-items-center">
    <a v-if="showScrollToTop" v-on="{click: ($event) => scrollToTop($event)}" class="round-link bottom-right js-scroll hide-tablet" href="#">
      <inline-svg class="icon-expand" :src="require('../assets/icons/arrow-round.svg')"/>
    </a>
    <b-container>
      <span>{{$t('Common.footer_copyright')}}</span>
    </b-container>
  </footer>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'

  export default {
    name: 'ErartaFooter',
    components: {InlineSvg},
    data() {
      return {
        showScrollToTop: false,
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll () {
        this.showScrollToTop = window.scrollY > 0
      },
      scrollToTop($event) {
        $event.preventDefault()
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/_variables.scss";

    .footer {
        background: #000000;
        color: #87878A;
        height: 100px;
        font-size: 12px;
    }

    .round-link.bottom-right {
        top: initial;
        bottom: 20px;
        right: 20px;
    }

    .round-link {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
        position: fixed;
        z-index: 20;
        -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    }

    .round-link svg {
      width: 20px;
    }

    .round-link:hover svg {
      fill: #{$primary};
      opacity: .8;
    }

    @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px})  {
        .footer {
            height: 93px;
            font-size: 12px;
        }
        .round-link {
            width: 40px;
            height: 40px;
        }
        .round-link svg {
            width: 16px;
        }
    }

</style>
