<template>
  <div id="app" class="container-main">
    <div v-if="!loading">
      <erarta-header v-if="!$route.meta.noHeader"/>
        <div id="body-container" class="container-wrap d-flex flex-column">
          <router-view/>
        </div>
      <erarta-footer v-if="!$route.meta.noHeader"/>
    </div>
    <div v-else>
      loading...
    </div>
  </div>

</template>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  .container-main {
    min-height: 100vh;
    margin: 0;
  }
  .container-wrap {
    /* 100vh - header - footer */
    min-height: calc(100vh - 100px - 100px);
  }
  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px})  {
    .container-wrap {
      /* 100vh - header - footer */
      min-height: calc(100vh - 93px);
      padding-top: 61px;
    }
  }
</style>

<script>
import ErartaHeader from './components/ErartaHeader.vue'
import ErartaFooter from './components/ErartaFooter.vue'
import {updateMessages} from './modules/locale'

export default {
    components: {ErartaFooter, ErartaHeader},
    data: () => ({
      loading: true
    }),
    mounted() {
      updateMessages(this.onMessagesLoaded).then(() => {
        this.loading = false
        this.changeTitle()
      })
    },
    watch: {
      '$route': function() {
        this.changeTitle()
      },
      '$root.$i18n.locale': function() {
        this.changeTitle()
      }
    },
    methods: {
      changeTitle() {
        if (typeof this.$route.meta.title === 'string') {
          document.title = this.$route.meta.title + this.$t('Common.title_suffix')
        } else if (typeof this.$route.meta.title === 'function') {
          document.title = this.$route.meta.title() + this.$t('Common.title_suffix')
        } else if (!this.$route.meta.customTitle) {
          document.title = this.$t('Common.default_title') + this.$t('Common.title_suffix')
        }
      }
    }
  }
</script>
