<template>
  <div class="d-flex flex-column flex-1">
    <b-container>
      <page-title :title="$t('Authentication.passwordRecover_change_header')"></page-title>
    </b-container>
    <div v-if="!recoveryCompleted" class="acc-container justify-content-center d-flex">
      <b-row class="acc-form-row">
        <b-col md="auto">
          <PasswordRecovery @completed="recoveryCompleted = true"/>
        </b-col>
      </b-row>
    </div>
    <b-container v-else>
      <i18n class="acc-instructions" path="Authentication.passwordRecover_change_completed_text">
        <template v-slot:br>
          <br/>
        </template>
        <template v-slot:link>
          <router-link to="/login" class="acc-form-link">{{ $t('Authentication.passwordRecover_change_completed_link') }}</router-link>
        </template>
      </i18n>
    </b-container>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import PasswordRecovery from '@/components/PasswordRecovery'

export default {
  name: 'PasswordRecoveryConfirmPage',
  components: { PasswordRecovery, PageTitle },
  data() {
    return {
      recoveryCompleted: false
    }
  }
}
</script>
