<template>
  <div>
    <b-container>
      <div>
        <page-title :title="$t('Authentication.accountActivation_header')"></page-title>
      </div>
      <div>
        <span v-if="!error && !confirmed">loading...</span>
        <i18n v-else-if="confirmed" class="acc-instructions" path="Authentication.accountActivation_text">
          <template v-slot:br>
            <br/>
          </template>
          <template v-slot:link>
            <router-link class="acc-form-link" to="/login">{{ $t('Authentication.accountActivation_link') }}</router-link>
          </template>
        </i18n>
        <span v-else>{{ error }}</span>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import account from '@/modules/account'

export default {
  name: 'RegistrationConfirmPage',
  components: {PageTitle},
  data () {
    return {
      confirmed: false,
      error: '',
      locale: this.$i18n.locale
    }
  },
  mounted() {
    let token = this.$route.query.token
    if (!token) {
      this.$router.push('/login')
    } else {
      this.confirm(token)
    }
  },
  methods: {
    confirm(token) {
      account.confirmRegistration(token, this.locale)
        .then(() => this.confirmed = true)
        .catch(error => this.error = error.response?.data?.message)
    }
  }
}
</script>
