<template>
  <div v-if="dataLoaded" class="building-plan-wrap">
    <back-link :nav-text="$t('Common.back')" :to="backLink" />
    <page-title :title="$t('BuildingPlan.header')" class="building-plan-title"></page-title>
    <div class="phone-rotate" v-show="showPhoneRotate">
      <span>{{$t('BuildingPlan.rotate_screen')}}</span>
    </div>
    <div class="building-plan" id="buildingPlanWrapper" ref="buildingPlanSvgContainer" v-on:click="hideAnimation()">
      <inline-svg :src="require('../../assets/images/buildingPlan.svg')"
                @loaded="buildingPlanLoaded($event)"/>
      <transition name="hand">
        <div v-if="showHand" class="hand-animated" ref="hand">
          <img src="../../assets/images/building_plan/animated/hand_animated.svg" alt="hand"/>
        </div>
      </transition>
    </div>
    <b-container class="clear legend-container">
      <b-row>
        <b-col>
          <p class="info text-center">{{$t('BuildingPlan.legend_info')}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="10" offset-md="2">
          <b-container class="p-0">
            <b-row>
              <b-col lg="6" md="7" sm="6" xs="12">
                <legend-item v-for="element in legendElements.slice(0, elemInColumnCount)"
                  @legend-item-clicked="(iconId, additionalIconIds) => $emit('legend-item-clicked', iconId, additionalIconIds)"
                  :element="element" :key="element.icon" />
              </b-col>
              <b-col xl="4" lg="4" md="5" sm="6" xs="12">
                <legend-item v-for="element in legendElements.slice(elemInColumnCount, legendElements.length)"
                  @legend-item-clicked="(iconId, additionalIconIds) => $emit('legend-item-clicked', iconId, additionalIconIds)"
                  :element="element" :key="element.icon" />
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    <div v-for="element in buildingPlan.elements" :key="element.icon">
      <modal-content v-if="!!element.content" :ref="`modal-content-parent-${element.icon}`" :element="element"/>
    </div>
    <exhibitions :exhibitions="exhibitions"/>
    <div v-for="(exhibition, index) in exhibitions" :key="`exhibition-${index}`">
      <modal-exhibition v-for="icon in exhibition.icons" :ref="`modal-content-parent-${icon}`"
                        :exhibition="exhibition" :key="`modal-exhibition-${icon}`"
                        :exhibition-days-left="buildingPlan.exhibitionDaysLeft"/>
    </div>
  </div>
  <div v-else>
    <!-- TODO заменить на индикатор загрузки -->
    loading...
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Vue from 'vue'
import SVGImage from '@/components/building_plan/SVGImage'
import LegendItem from '@/components/building_plan/LegendItem'
import ModalContent from '@/components/building_plan/ModalContent'
import BackLink from '@/components/common/BackLink'
import {fetchDocument, fetchExhibitions} from '@/modules/content-loader'
import PageTitle from '@/components/common/PageTitle'
import Exhibitions from '@/components/building_plan/Exhibitions'
import ModalExhibition from '@/components/building_plan/ModalExhibition'
import buildingPlan from '@/modules/buildingPlan'
import {mapActions, mapGetters, mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'BuildingPlan',
  components: {
    InlineSvg,
    LegendItem,
    ModalContent,
    BackLink,
    PageTitle,
    Exhibitions,
    ModalExhibition
  },
  beforeMount() {
    fetchDocument('building_plan/building_plan.html.json').then((doc) => {
      this.buildingPlanJson = doc.data
      this.buildingPlanJsonLoaded = true
    })
    fetchExhibitions().then((doc) => {
      this.exhibitionsJson = doc.data
      this.exhibitionsJsonLoaded = true
    })
  },
  props: {
    highlightedFragment: {
      type: String,
    },
    highlightedAdditionalFragments: {
      type: Array,
    },
    backLink: {
      type: String,
    }
  },
  data() {
    return {
      buildingPlanJsonLoaded: false,
      exhibitionsJsonLoaded: false,
      buildingPlanJson: {},
      exhibitionsJson: {},
      activeModal: 0,
      showHand: true,
      showPhoneRotate: this.isPortraitScreenOrientation(),
    }
  },
  computed: {
    ...mapGetters({
      activePasses:'loyalty/activePasses',
    }),
    ...mapState({
      annualPassesLoaded: state => state.loyalty.annualPassesLoaded
    }),
    dataLoaded () {
      return this.annualPassesLoaded && this.buildingPlanJsonLoaded && this.exhibitionsJsonLoaded
    },
    buildingPlan () {
      return this.buildingPlanJson[this.$i18n.locale] ?? []
    },
    exhibitions () {
      let notSorted = this.exhibitionsJson[this.$i18n.locale].exhibitions ?? []
      return notSorted.sort(function (a, b) {
        return moment(a.dateStart, 'DD.MM.YYYY').diff(moment(b.dateStart, 'DD.MM.YYYY'))
      })
    },
    legendElements () {
      return this.buildingPlan.elements.filter(elem => !!elem.legend
        && this.existAnnualPassesWithAttrs(elem.annualPassAttributes))
    },
    iconIDList () {
      let iconList = []
      this.buildingPlan.elements.forEach( (element) => {
        if (this.existAnnualPassesWithAttrs(element.annualPassAttributes)) {
          iconList.push(element.icon)
          if (element.additionalIcons != null) {
            Array.prototype.push.apply(iconList, element.additionalIcons)
          }
        }
      })
      this.exhibitions.forEach( (exhibition) => {
        if (exhibition.icons.length > 0) {
          Array.prototype.push.apply(iconList, exhibition.icons)
        }
      })
      return iconList
    },
    elemInColumnCount () {
      return Number((this.legendElements.length / 2).toFixed(0))
    }
  },
  watch: {
    '$root.$i18n.locale': function() {
      this.changeLocaleElement()
    },
    highlightedFragment: function() {
      buildingPlan.highlightIcon(this.highlightedFragment, this.highlightedAdditionalFragments)
    },
    highlightedAdditionalFragments: function() {
      buildingPlan.highlightIcon(this.highlightedFragment, this.highlightedAdditionalFragments)
    }
  },
    methods: {
      ...mapActions({loadAnnualPassList: 'loyalty/loadAnnualPassList' }),
      buildingPlanLoaded(svg) {
      this.changeLocaleElement()
      let parent = this
      let g_tags = svg.getElementsByTagName('g')

      let container = this.$refs.buildingPlanSvgContainer
      container.scrollLeft = (container.scrollWidth - window.innerWidth) / 2
      this.$refs.hand.style.left = container.scrollWidth / 2 - 23 + 'px'

      function addImageElement(elem, isStatic, isHighlight) {
        let SVGImageComp = Vue.extend(SVGImage)
        let svgImage = new SVGImageComp({
          propsData: {
            id: elem.getAttribute('id'),
            isStatic: isStatic,
            contentExist: isContentForElementExist(elem.getAttribute('id')),
            isHighlight: isHighlight
          },
          parent: parent
        }).$mount()
        elem.append(svgImage.$el)
      }

      function isContentForElementExist(id) {
        return parent.buildingPlan.elements.filter(element =>
          element.icon === id && element.content != null).length > 0
          || parent.exhibitions.filter(exhibition => exhibition.icons.includes(id)).length > 0
      }

      Array.prototype.forEach.call(g_tags, (el => {
        let elementHighlighted = false
        if (this.highlightedFragment) {
          elementHighlighted = (this.highlightedFragment + '_animated') === el.getAttribute('id')
        }
        if(this.iconIDList.includes(el.getAttribute('id'))) {
          addImageElement(el, true, false)
        } else if (el.getAttribute('id').endsWith('animated')
            && this.iconIDList.includes(el.getAttribute('id').replace(/_animated/g, ''))) {
          addImageElement(el, false, elementHighlighted)
        }
      }))
    },
    isPortraitScreenOrientation() {
      let angle = screen.orientation ? screen.orientation.angle : window.orientation
      return Math.abs(angle) !== 90
    },
    existAnnualPassesWithAttrs(attrs) {
      if (typeof attrs === 'undefined') {
        return true
      }
      return this.activePasses.some(ap => {
        return attrs?.some(attr => {
          return ap && ap.attributes && ap.attributes[attr] != null && ap.attributes[attr]
        })
      })
    },
    handleOrientationChange() {
      this.showPhoneRotate = this.isPortraitScreenOrientation()
      if (this.$refs.hand) {
        let container = this.$refs.buildingPlanSvgContainer
        this.$refs.hand.style.left = (container.scrollWidth / 2 - 23) + 'px'
      }
    },
    showModal: function(id) {
      this.$refs['modal-content-parent-'+id][0].$refs['modal'].show()
    },
    hideAnimation() {
      buildingPlan.stopHighlightIcon()
      this.hideHand()
    },
    hideHand() {
      this.showHand = false
    },
    changeLocaleElement() {
      if (this.$root.$i18n.locale === 'ru') {
        document.getElementById('info_EN').setAttribute('opacity', '0')
        document.getElementById('info_RU').setAttribute('opacity', '1')
      } else {
        document.getElementById('info_EN').setAttribute('opacity', '1')
        document.getElementById('info_RU').setAttribute('opacity', '0')
      }
    }
  },
  mounted() {
    this.loadAnnualPassList()
    window.addEventListener(
      'resize',
      this.handleOrientationChange
    )
  },
}

</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";
  .building-plan {
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .back-link {
    margin-bottom: -50px;
    height: 25px;
    margin-top: 25px;

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      margin-bottom: -50px;
      height: 25px;
      margin-top: 25px;
    }
  }
  .building-plan::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .building-plan svg {
    min-width: 600px;
  }
  .phone-rotate {
    display: none;
  }
  .hand-animated {
    display: none;
  }
  .hand-enter,
  .hand-leave-to {
    visibility: hidden;
    opacity: 0;
  }
  .hand-enter-active,
  .hand-leave-active {
    transition: all 1s;
  }
  .info {
    margin: 3rem;
    font-size: 18px;
  }
  .legend-container {
    margin-bottom: 80px;
  }
  @media (min-width: 1280px) {
    .legend-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 1023px) {
    .building-plan {
      overflow-x: scroll;
      width: 100vw;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .hand-animated {
      display: block;
      position: absolute;
      bottom: 0;
      width: 60px;
    }
    .info {
      margin: 25px 0;
      font-size: 14px;
    }
    .legend-container {
      margin-bottom: 35px;
    }
    .building-plan-title {
      margin-top: 40px;
    }
  }
  @media (max-width: 639px) {
    .info {
      margin: 25px 0;
      font-size: 14px;
    }
    .phone-rotate {
      display: table;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 20px;
    }
    .phone-rotate:before {
      content: '';
      display: table-cell;
      width: 40px;
      height: 40px;
      background: url(../../assets/icons/phone-rotate.svg) no-repeat center;
    }
    .phone-rotate span {
      padding-left: 15px;
      display: table-cell;
      vertical-align: middle;
    }
  }
</style>
<style>
@media (min-width: 640px) and (max-width: 1023px) {
  .building-plan-wrap .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .building-plan-wrap .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .building-plan-wrap .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .building-plan-wrap .offset-lg-1 {
    margin-left: 0;
  }
  .building-plan-wrap .offset-md-1 {
    margin-left: 0;
  }
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
}
@media (min-width: 640px) and (max-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
}
</style>
