<template>
  <div v-if="isActive" ref="bannerContainer" class="top-banner">
    <div class="container">
      <div class="row">
        <div class="row col-md-11" v-html="banner[this.$i18n.locale].text"></div>
        <div class="col-md-1">
          <span class="closeTopBanner" @click="closeTopBanner">&times;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchDocumentWithCheckCache} from '@/modules/content-loader'
import moment from 'moment'

export default {
  name: 'Banner',
  data() {
    return {
      banner: {},
      isActive: false,
      ro: new ResizeObserver(this.onBannerResize),
      height: 0,
      padding: 0
    }
  },
  mounted() {
    fetchDocumentWithCheckCache('banner/banner.html.json').then((doc) => {
      this.banner = doc.data
      const currentDate = moment()
      const startDate = moment.unix(parseInt(this.banner[this.$i18n.locale].startDate) / 1000)
      const endDate = moment.unix(parseInt(this.banner[this.$i18n.locale].endDate) / 1000)
      this.isActive = this.banner[this.$i18n.locale].active && currentDate.isBetween(startDate, endDate) && this.$cookies.get('bannerClosed') !== 'true'
    }).finally(() => {
      this.$nextTick(() => {
        if (this.isActive) {
          this.padding = window.getComputedStyle(document.getElementById('body-container')).getPropertyValue('padding-top')
          this.padding = parseInt(this.padding.substring(0, this.padding.length - 2))
          this.ro.observe(this.$refs.bannerContainer)
          this.onBannerResize()
        }
      })
    })
  },
  methods: {
    closeTopBanner() {
      this.$cookies.set('bannerClosed', true, this.banner[this.$i18n.locale].delay + 'h', '/')
      if (this.$refs.bannerContainer) {
        this.isActive = false
        this.ro.unobserve(this.$refs.bannerContainer)
      }
      document.getElementById('body-container').style.removeProperty('padding-top')
    },
    onBannerResize() {
      if (this.isActive) {
        if (this.height !== this.$refs.bannerContainer.offsetHeight) {
          if (window.innerWidth <= 1024) {
            document.getElementById('body-container').style.paddingTop = this.padding + this.$refs.bannerContainer.offsetHeight + 'px'
          } else {
            document.getElementById('body-container').style.removeProperty('padding-top')
          }
        }
        this.height = this.$refs.bannerContainer.offsetHeight
      }
    },
  },
  beforeDestroy() {
    if (this.$refs.bannerContainer) {
      this.ro.unobserve(this.$refs.bannerContainer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.top-banner {
  width: 100%;
  min-height: 100px;
  background: #{$primary};
  font-family: 'Graphik LCG';
  color: #{$white};
  padding-top: 30px;
  font-size: 16px;
  z-index: 1010 !important;
}

.closeTopBanner {
  position: absolute;
  top: -30px;
  right: -30px;
  font-size: 60px;
  font-weight: 100;
  cursor: pointer;
  font-family: 'Graphik LCG';
  z-index: 1010 !important;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .top-banner {
    padding-top: 15px;
  }

  .closeTopBanner {
    position: absolute;
    top: -30px;
    right: -30px;
    font-size: 60px;
    font-weight: 100;
    cursor: pointer;
    font-family: 'Graphik LCG';
    z-index: 1010 !important;
  }

}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.top-banner p {
  display: inline-block;
  padding-right: 0;
}

.top-banner p a {
  color: #{$white};
  text-decoration: underline;
}

.top-banner p a:hover {
  color: #{$erarta-light-gray};
}
</style>
