import moment from 'moment'
import store from '@/store'

export default {
  async track(to, from) {
    if ('dataLayer' in window) {
      let dataLayer = window.dataLayer
      if (dataLayer) {
        // на этот момент переход уже произошёл
        let hasAuthentication = to.meta.authRequired
        let lastAnnualPass
        if (hasAuthentication && store.getters['auth/userIsNormal']) {
          await store.dispatch('loyalty/loadAnnualPassList')
          lastAnnualPass = store.getters['loyalty/annualPasses'].sort((firstPass, secondPass) => {
            let firstExpires = moment(firstPass.expires).unix()
            let secondExpires = moment(secondPass.expires).unix()
            return secondExpires - firstExpires
          })[0]?.['number']
        }
        if (lastAnnualPass) {
          dataLayer.push({
            event: 'page_view',
            user_id: lastAnnualPass
          })
        } else {
          dataLayer.push({ event: 'page_view' })
        }
      }
    }
    if ('ym' in window) {
      window.ym(process.env.VUE_APP_YA_ID, 'hit', to.path, {referer: from.path})
    }
  }
}
