<template>
  <b-container>
    <page-title title="Страница находится в разработке"></page-title>
  </b-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
export default {
  name: 'DevelopPage',
  components: {
    PageTitle,
  },
}
</script>

<style scoped>

</style>
