<template>
  <div class="guide-content">
    <template v-for="(track, index) in guideContent.previewSound">
      <trackable-audio :key="track.file" :soundFile="track.file"></trackable-audio>
      <div :key="index * 2 + 1" v-html="track.description" />
    </template>
    <div v-html="guideContent.previewText"></div>
  </div>
</template>
<script>
import TrackableAudio from '@/components/TrackableAudio'

export default {
    components: {
      'trackable-audio': TrackableAudio,
    },
    props: {
      guideContent: {
        type: Object,
        required: true
      },
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .guide-demo-content {
    audio {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 57px;
    }

  }
</style>
