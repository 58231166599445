import {VOTE_AXIOS} from '@/modules/backend-config'

export default {
  performVote(processId, objectCode, roundIndex, annualPassId) {
    return VOTE_AXIOS.post(`/voting/${processId}/${objectCode}`, {
      tour: roundIndex,
      annualPassId: annualPassId,
    })
  },
  performVoteWithNumber(processId, objectCode, roundIndex, annualPassNumber) {
    return VOTE_AXIOS.post(`/voting/${processId}/${objectCode}`, {
      tour: roundIndex,
      annualPassNumber: annualPassNumber,
    })
  },
  getVoteWithNumber(processId, annualPassNumber, roundIndex) {
    return VOTE_AXIOS.get(`/voting/${processId}/checkVote`, {
      params: {
        'annualPassNumber': annualPassNumber,
        'tour': roundIndex,
      }
    })
  },
  getVote(processId, annualPassId, roundIndex) {
    return VOTE_AXIOS.get(`/voting/${processId}/checkVote`, {
      params: {
        'annualPassId': annualPassId,
        'tour': roundIndex,
      }
    })
  },
  performVoteWithOneTimePassNumber(processId, objectCode, roundIndex, oneTimePassNumber) {
    return VOTE_AXIOS.post(`/voting/${processId}/${objectCode}`, {
      tour: roundIndex,
      oneTimePassNumber,
    })
  },
  getVoteWithOneTimePassNumber(processId, oneTimePassNumber, roundIndex) {
    return VOTE_AXIOS.get(`/voting/${processId}/checkVote`, {
      params: {
        'oneTimePassNumber': oneTimePassNumber,
        'tour': roundIndex,
      }
    })
  },
  getAvailableObjects(processId) {
    return VOTE_AXIOS.get(`/voting/${processId}/objects`)
  },
  getCurrentResults(processId) {
    return VOTE_AXIOS.get(`/voting/${processId}/results`, {
      params: {
        includeVoteCount: true
      }
    })
  },
  checkAnnualPassValidity(number) {
    return VOTE_AXIOS.get(`/annualPass/${number}/active`)
  }
}
