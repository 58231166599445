<template>
  <b-container class="guide-page" v-if="guideIndex">
    <page-title :title="$t('AudioGuides.title')"></page-title>
    <h2 v-if="Object.keys(primaryGuideContent).length !== 0">{{$t('AudioGuides.audio_guide_primary_title')}}</h2>
    <audio-guide-list :guide-index="guideIndex[$root.$i18n.locale]" :guide-list="primaryGuideContent" guide-path-prefix="/privateAudioGuide" />
    <h2 v-if="Object.keys(temporaryGuideContent).length !== 0">{{$t('AudioGuides.audio_guide_temporary_title')}}</h2>
    <audio-guide-list v-if="Object.keys(temporaryGuideContent).length !== 0" :guide-index="guideIndex[$root.$i18n.locale]" :guide-list="temporaryGuideContent" />
  </b-container>
</template>
<script>
  import AudioGuidePage from './AudioGuidePage'
  import {fetchDocument} from '@/modules/content-loader'

  export default {
    extends: AudioGuidePage,
    methods: {
      initializeContent() {
        fetchDocument('private_guide/audio_guide/index.json')
          .then(response => this.handleAudioGuideIndex(response.data))
      }
    }
  }
</script>
