import {AUTH_AXIOS, AXIOS, MESSAGES_AXIOS, VOTE_AXIOS} from './backend-config'
import store from '../store/index'

var updatePromise = null
var isRefreshing = false
const auth = {
  login(form) {
    let loginInner = () => {
      updatePromise = AUTH_AXIOS.post('/login', {
        username: form.email,
        password: form.password,
        rememberMe: form.rememberMe
      })
      return updatePromise
    }
    let prom
    if (updatePromise != null) {
      prom = updatePromise.finally(loginInner)
    }
    else {
      prom = loginInner()
    }
    return prom.finally(() => {
      if (prom === updatePromise) {
        isRefreshing = false
        updatePromise = null
      }
    })
  },
  managerLogin(form) {
    let loginInner = () => {
      let prom = AUTH_AXIOS.post('/manager_login', {
        username: form.username,
        password: form.password,
      })
      updatePromise = prom
      return prom
    }
    let prom
    if (updatePromise != null) {
      prom = updatePromise.finally(loginInner)
    }
    else {
      prom = loginInner()
    }
    return prom.finally(() => {
      if (prom === updatePromise) {
        isRefreshing = false
        updatePromise = null
      }
    })
  },
  logout() {
    let prom
    if (updatePromise != null) {
      prom = updatePromise.finally(() => {
        updatePromise = AUTH_AXIOS.post('/logout')
        return updatePromise
      })
    }
    else {
      prom = updatePromise = AUTH_AXIOS.post('/logout')
    }
    return prom.finally(() => {
      if (prom === updatePromise) {
        isRefreshing = false
        updatePromise = null
      }
    })
  },
  refresh() {
    let currentPromise
    if (updatePromise == null) {
      isRefreshing = true
      updatePromise = currentPromise = AUTH_AXIOS.post('/refresh')
      currentPromise.finally(() => {
        updatePromise = null
        isRefreshing = false
      })
    }
    else if (updatePromise != null && !isRefreshing) {
      currentPromise = updatePromise = updatePromise.finally(() => { return AUTH_AXIOS.post('/refresh') })
      currentPromise.finally(() => {
        if (currentPromise === updatePromise) {
          updatePromise = null
          isRefreshing = false
        }
      })
    }
    return updatePromise
  },
  checkSession() {
    let currentPromise
    if (updatePromise == null) {
      isRefreshing = true
      updatePromise = currentPromise = AUTH_AXIOS.post('/session')
      currentPromise.finally(() => {
        updatePromise = null
        isRefreshing = false
      })
    }
    else if (updatePromise != null && !isRefreshing) {
      currentPromise = updatePromise = updatePromise.finally(() => { return AUTH_AXIOS.post('/session') })
      currentPromise.finally(() => {
        if (currentPromise === updatePromise) {
          updatePromise = null
          isRefreshing = false
        }
      })
    }
    return updatePromise
  }
}

export default auth


AXIOS.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    return auth.refresh().then(() => {
      return AXIOS.request(error.config)
    })
  }
  return Promise.reject(error)
})

MESSAGES_AXIOS.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    return auth.refresh().then(() => {
      return MESSAGES_AXIOS.request(error.config)
    })
  }
  return Promise.reject(error)
})

AUTH_AXIOS.interceptors.response
  .use(
    response => store.dispatch({
      type: 'auth/updateAuthData',
      payload: response.data
    }).then(() => response),
    error => {
      return Promise.reject(error)
    })

VOTE_AXIOS.interceptors.response.use(response => response, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    return auth
      .refresh()
      .catch(err => {
        error.authResponse = err?.response
        error.authRequest = err?.request
        throw error
      })
      .then(() => {
      return VOTE_AXIOS
        .request(error.config)
    })
  }
  return Promise.reject(error)
})
