<template>
  <div class="d-flex flex-column flex-1">
    <b-container class="email-and-password-page">
      <back-link to="/" :nav-text="$t('Common.back')" />
      <page-title :title="$t('Common.email_and_password_header')"></page-title>
    </b-container>
    <div class="acc-container justify-content-center d-flex">
      <MyEmailAndPassword/>
    </div>
  </div>
</template>

<script>
  import MyEmailAndPassword from '@/components/MyEmailAndPassword'
  import PageTitle from '@/components/common/PageTitle'
  import BackLink from '@/components/common/BackLink'

  export default {
    name: 'EmailAndPasswordPage',
    components: {MyEmailAndPassword, PageTitle, BackLink},

  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .form-email-password {
    margin-top: 81px;
    margin-bottom: 69px;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
    .form-email-password {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .email-and-password-page {
    .back-link {
      display: block;
      margin-top: 26px;
    }

    .title-container {
      padding-top: 68px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {

      .title-container {
        padding-top: 26px;
      }

      .back-link {
        margin-top: 28px;
      }
    }
  }
</style>
