<template>
  <div class="d-flex guide-exhibit-header">
    <div class="d-flex plate-code">
      <svg viewBox="0 0 24 24" width="24" height="24">
        <rect :fill="plateColor" x="1" y="1" width="22" height="22" stroke="#444444" rx="3" ry="3" stroke-width="2" />
      </svg>
      {{plateCode}}
    </div>
    <div class="d-flex location-label" v-if="location">
      <img :src="require('@/assets/icons/geolocation.svg')">
      {{location}}
    </div>
  </div>
</template>
<script>

  export default {
    props: {
      plateCode: {
        type: String,
        required: true
      },
      plateColor: {
        type: String,
        required: false,
        default: 'white'
      },
      location: {
        type: String,
        required: false
      },
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-exhibit-header {
    
    
    > * {
      align-items: center;
    }

    .location-label {
      margin-left: auto;
      color: #{$erarta-dark-gray};
      font-size: 16px;
      line-height: 1.5;
      font-weight: #{$font-weight-medium};
      svg, img {
        width: 16px;
        margin-right: 9px;
      }
    }

    .plate-code {
      font-size: 36px;
      font-weight: #{$font-weight-medium};
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      align-items: center;
      
      > *:not(:last-child) {
        margin-right: 56px;
      }

      .start-excursion {
        margin-left: auto;
      }
    }
    img, svg {
      width: 24px;
      height: auto;
      margin-right: 16px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      img, svg {
        margin-right: 8px;
      }
      .plate-code {
        font-size: 25px;
        line-height: 28px;
      }
      .location-label {
        font-size: 13px;
      }
    }
  }
</style>
