<template>
  <div v-if="loggedIn" class="d-flex flex-column container mx-auto">
    <div v-if="haveActivePass" class="my-erarta-links d-flex flex-row align-items-start">
      <router-link class="link-button align-items-center opacity-hover" to="/messages">
        <div class="message-icon-wrapper">
          <b-badge class="message-badge">{{unreadMessageCount}}</b-badge>
          <img class="mr-3 link-button-icon link-button-icon-message" src="../assets/icons/message.svg" alt="message" width="30">
        </div>
        <div>
          <span class="link-button-text">
            {{ $t('Common.myErarta_personalMessages') }}
          </span>
        </div>
      </router-link>
      <router-link class="link-button align-items-center opacity-hover gap-link" to="/loyalty">
        <img class="mr-3 link-button-icon link-button-icon-annual" src="../assets/icons/ticket.svg" alt="ticket"
             width="30">
        <div>
          <span class="link-button-text">
            {{ $t('Common.myErarta_tickets') }}
          </span>
        </div>
      </router-link>
    </div>
    <div v-else-if="isNormalUser && annualPasses?.length === 0 && tickets?.length === 0" class="d-flex flex-column">
      <a class="btn btn-primary buy-annual-pass-button" :href="membershipPurchaseUrl">{{$t('Common.myErarta_buyTickets')}}</a>
      <a class="details opacity-hover" :href="$t('Common.myErarta_details_link')">{{$t('Common.myErarta_details')}}</a>
      <span class="has-ticket">{{$t('Common.myErarta_alreadyHaveTicket')}}</span>
      <router-link class="link-ticket" to="/loyalty">
        <div class="font-weight-medium d-flex align-items-center">
          <span style="margin-right: 6px">{{$t('Common.myErarta_link')}}</span>
          <inline-svg class="icon-expand" :src="require('../assets/icons/my-erarta-arrow.svg')"/>
        </div>
      </router-link>
    </div>
  </div>
  <div v-else class="d-flex flex-column container mx-auto">
    <span class="not-auth-info">{{$t('Common.myPossibilities_userWithTicketAndAuthPossibilities')}}</span>
  </div>
</template>

<script>
  import InlineSvg from 'vue-inline-svg'
  import {fetchDocument} from '@/modules/content-loader'
  import common from '@/modules/common.js'
  import {mapGetters} from 'vuex'

  export default {
    name: 'MyErarta',
    components: {InlineSvg},
    props: {
      haveActivePass: {
        type: Boolean,
        default: false
      }
    },
    beforeMount() {
      fetchDocument('annual_pass_config/annual_pass_config.html.json').then((doc) => {
        this.loyaltyPageInfo = doc.data
      })
    },
    data() {
      return {
        purchaseFormHeight: null,
        loyaltyPageInfo: null
      }
    },
    computed: {
      membershipPurchaseUrl() {
        return process.env.VUE_APP_MEMBERSHIP_PURCHASE_URL + '?' + common.joinQueryParams({
          'duration[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseDuration,
          'type[]': this.loyaltyPageInfo?.[this.$root.$i18n.locale].defaultPurchaseAnnualPassType,
          // 'autoExtension': autoExtend,
          // 'category[]': isGift ? 'gift' : 'me',
          lang: this.$root.$i18n.locale,
          // yaId: yaMetrika !== undefined ? yaMetrika.counters().filter(x => x.webvisor)[0].id : ''
        })
      },
      ...mapGetters({ unreadMessageCount: 'auth/unreadMessageCount', isNormalUser: 'auth/userIsNormal' }),
      loggedIn() {
        return this.$store.getters['auth/authData'].access_token
      },
      ...mapGetters({annualPasses: 'loyalty/annualPasses', tickets: 'loyalty/tickets'}),
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  h1 {
    color: #{$dark};
  }

  .link-button {
    display: grid;
    grid-template-columns: auto auto;
    height: 36px;
    font-weight: 500;
    line-height: 24px;
    font-size: 19px;
    color: #{$secondary};
  }

  .link-button-icon {
    display: inline-block;
    grid-column-start: 1;
  }

  .link-button-text {
    display: inline !important;
    grid-column-start: 2;
    border-bottom: solid 1px #{$secondary};
  }

  .my-erarta-links {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .gap-link {
    margin-left: 30px;
  }

  .link-ticket {
    text-decoration: none !important;
    font-size: 16px;
    margin-bottom: 42px;
  }

  a {
    color: #{$primary};
  }

  .link-ticket:hover div {
    opacity: 0.7;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
  }

  .message-icon-wrapper{
    position: relative;
  }

  .message-badge {
    position: absolute;
    right: 8px;
    bottom: 13px;
    background-color: #{$dark};
    border-radius: 2px;
    font-size: 15px;
    line-height: 17px;
    font-weight: #{$font-weight-medium};
    min-width: 17px;
    min-height: 17px;
    padding: 0 2px;
  }

  .not-auth-info {
    margin-top: 16px;
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 28px;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .my-erarta-links {
      flex-direction: column !important;
      margin-bottom: 30px;
    }

    .gap-link {
      margin-left: 0px;
      margin-top: 10px;
    }

    .has-ticket {
      margin-bottom: 22px;
    }

    .link-button {
      grid-template-columns: 70px auto;
      line-height: 19px;
      font-size: 14px;
      height: auto;
    }

    .link-button-icon-message{
      width: 31px;
    }

    .link-button-icon-annual{
      width: 31px;
    }
    .link-ticket {
      margin-bottom: 50px;
    }

    .message-badge {
      right: 33px;
      bottom: 14px;
      background-color: #{$dark};
      border-radius: 2px;
      line-height: 15px;
      min-width: 15px;
      min-height: 15px;
    }

    .not-auth-info {
      margin-top: 13px;
      margin-bottom: 33px;
      font-size: 16px;
      line-height: 24px;
    }

  }
</style>
