<template>
  <b-form class="bind-form" @submit.prevent="onSubmit">
    <b-form-group>
      <b-form-input :placeholder="$t('Loyalty.binding_number_placeholder')" v-model="v$.form.number.$model"
        :state="v$.form.number.$dirty ? !v$.form.number.$error: null"
        :aria-describedby="$refs['number-description'] ? $refs['number-description'].id : null"/>
      <b-form-text ref="number-description">{{$t('Loyalty.binding_number_description')}}</b-form-text>
    </b-form-group>
    <b-button variant="primary" class="d-block w-100" type="submit" :disabled="!formEnabled">{{$t('Loyalty.binding_submit')}}</b-button>
    <b-alert class="acc-error mt-2" variant="danger" v-if="annualPassNonExistent" :dismissible="false" :show="true">{{ $t('Vote.pass-non-existent', {annualPassNumber: presetNumber}) }}</b-alert>
    <b-alert class="acc-error mt-2" variant="danger" v-if="annualPassAlreadyVoted" :dismissible="false" :show="true">{{ $t('Vote.pass-already-voted',{annualPassNumber: presetNumber}) }}</b-alert>
  </b-form>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import {required, minLength} from '@vuelidate/validators'

  export default {
    validations: {
      form: {
        number: {
          required,
          minLength: minLength(7),
        }
      }
    },
    props: {
      formEnabled: {
        type: Boolean,
        default: true,
      },
      processId: {
        type: String
      },
      presetNumber: {
        type: String
      },
      annualPassNonExistent: {
        type: Boolean,
        default: false,
      },
      annualPassAlreadyVoted: {
        type: Boolean,
        default: false,
      },
    },
    setup () {
      return { v$: useVuelidate() }
    },
    data() {
      return {
        submissionError: false,
        form: {
          number: this.presetNumber
        }
      }
    },
    watch: {
      presetNumber: function(newVal) {
        this.number = newVal
      }
    },
    methods: {
      onSubmit() {
        this.v$.$touch()
        this.submissionError = false
        if (this.v$.$invalid || !this.formEnabled) {
          return
        }
        this.$emit('number-submit', this.form.number)
      },
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .bind-form {
    .form-group {
      margin-bottom: 30px;
    }
    .btn-primary.disabled, .btn-primary:disabled {
      opacity: 1;
    }
    margin-bottom: 0;
  }

  small.form-text {
    font-size: 14px;
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .bind-form {
      margin-bottom: 0;
    }
  }
</style>
