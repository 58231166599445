export default {
  highlightIcon(mainIconId, additionalIconIds) {
    let iconImages = document.getElementsByClassName('icon-image-animated')
    Array.prototype.forEach.call(iconImages, el => {
      el.setAttribute('opacity', '0')
    })
    let highlightIcons = [mainIconId]
    Array.prototype.push.apply(highlightIcons, additionalIconIds)
    Array.prototype.forEach.call(highlightIcons, (iconId) => {
      document.getElementById('iconImage_' + iconId + '_animated')
        .setAttribute('opacity', '1')
    })

    let scrollIntoMiddle = (elId) => {
      const element = document.getElementById(elId)
      const buildingPlanWrapper = document.getElementById('buildingPlanWrapper')
      if (element) {
        const elementRect = element.getBoundingClientRect()
        const absoluteElementTop = elementRect.top + elementRect.height / 2 + window.pageYOffset
        const middle = absoluteElementTop - (window.innerHeight / 2)
        window.scrollTo({
          top: middle,
          behavior: 'smooth'})
        buildingPlanWrapper.scrollLeft = (buildingPlanWrapper.scrollLeft + elementRect.left) / 2
      }
    }

    scrollIntoMiddle('iconImage_' + mainIconId + '_animated')

  },
  stopHighlightIcon(excludeElementId) {
    let iconImages = document.getElementsByClassName('icon-image-animated')
    Array.prototype.forEach.call(iconImages, el => {
      if (typeof excludeElementId === 'undefined'
            || document.getElementById(excludeElementId.replace(/_animated/g, '') + '_animated').getAttribute('opacity') === '0') {
          el.setAttribute('opacity', '0')
      }
    })
  }
}
