<template>
  <div class="row legend-item" v-on:click="$emit('legend-item-clicked', element.icon, element.additionalIcons)">
    <div class="img-wrap">
      <img class="icon mr-3"
         :src="require('../../assets/images/building_plan/static/' + element.icon + '_legend.svg')"
         :alt="element.icon">
    </div>
    <span>{{ element.legend }}</span>
  </div>
</template>

<script>
import buildingPlan from '@/modules/buildingPlan'

export default {
  name: 'LegendItem',
  props: {
    element: {
      type: Object
    },
  },
  methods: {
    highlightIcon(mainIconId, additionalIconIds) {
      buildingPlan.highlightIcon(mainIconId, additionalIconIds)
    }
  }
}
</script>

<style scoped>
  .legend-item {
    min-height: 60px;
    display: table;
    padding-right: 20px;
  }
  .legend-item .icon, .legend-item span {
    cursor: pointer;
  }
  .legend-item .icon {
    float: left;
  }
  .legend-item span {
    display: table-cell;
    vertical-align: middle;
    font-size: 18px;
    line-height: 20px;
  }
  .legend-item .img-wrap {
    width: 60px;
    display: table-cell;
    vertical-align: middle;
  }
  @media (min-width: 1024px) {
    .legend-item {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1023px) {
    .legend-item .img-wrap {
      width: 40px;
    }
    .legend-item .icon {
      width: 26px;
    }
    .legend-item span {
      font-size: 12px;
      line-height: 14px;
    }
    .legend-item {
      min-height: 50px;
    }
  }
  @media (max-width: 768px) {
    .legend-item .icon.mr-3 {
      margin-right: 0 !important;
    }
  }
</style>
