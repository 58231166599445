<template>
  <b-form @submit.prevent="onSubmit">
    <b-alert class="acc-error" variant="danger" :show="error != null && errorMessage != null" @dismissed="!error">{{ $t(errorMessage) }}</b-alert>
    <b-form-group>
      <b-form-input v-model.trim="form.email"
                    :placeholder="$t('Authentication.login_field_email_placeholder')" type="email"/>
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.required.$invalid : null">
        {{$t('Authentication.login_field_email_errors_mandatory') }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.email.$invalid : null">
        {{$t('Authentication.login_field_email_errors_invalid') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.password"
                    :placeholder="$t('Authentication.login_field_password_placeholder')" :type="passwordField.type"/>
        <img class="toggle-icon" @mouseover="passwordField.hover = true" @mouseleave="passwordField.hover = false"
             @click="passwordField = switchVisibility(passwordField)" :src="passwordIcon(passwordField)">
      </div>
      <b-form-invalid-feedback :state="v$.form.password.$dirty ? !v$.form.password.required.$invalid : null">
        {{$t('Authentication.login_field_password_errors_mandatory') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <span class="acc-description password-info">{{ $t('Authentication.login_password_info') }}</span>
    <b-form-group class="text-left">
      <b-form-checkbox class="form-checkbox" v-model="form.rememberMe" :value="true" :unchecked-value="false">
        <span class="remember-me">{{$t('Authentication.login_rememberMe') }}</span>
      </b-form-checkbox>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="isProcessing" class="acc-btn-submit">{{$t('Authentication.login_signIn') }}</b-button>
    <b-form-row>
      <b-col cols="auto" class="mr-auto">
        <router-link class="acc-form-link" to="/recoverPassword">{{$t('Authentication.login_forgotPassword') }}</router-link>
      </b-col>
      <b-col cols="auto">
        <router-link class="acc-form-link" to="/signup">{{$t('Authentication.login_signUp') }}</router-link>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {email, required} from '@vuelidate/validators'
import {mapActions} from 'vuex'

export default {
    name: 'Login',
    setup () {
      return { v$: useVuelidate() }
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
          rememberMe: true
        },
        isProcessing: false,
        error: null,
        passwordField: {
          type: 'password',
          hover: false,
          visible: false
        }
      }
    },
    props: {
      from: {
        type: String,
        default: '/'
      }
    },
    validations: {
      form: {
        email: {
          required,
          email
        },
        password: {
          required
        }
      }
    },
    computed: {
      errorMessage() {
        if (this.error == null) {
          return null
        }
        const fields = {
          'USER_NOT_FOUND': 'Authentication.login_error_user_not_found',
          'SERVICE_UNAVAILABLE': 'Ошибка авторизации. Сервис временно недоступен'
        }
        return fields[this.error]
      }
    },
    methods: {
      onSubmit() {
        this.error = null
        this.isProcessing = true
        this.v$.$touch()
        if (this.v$.$invalid) {
          this.isProcessing = false
          return
        }
        this.login(this.form)
          .then(() => this.$router.push(this.from))
            .catch(err => {
              this.error = err.response?.status === 403 ? 'USER_NOT_FOUND' : 'SERVICE_UNAVAILABLE'
            })
            .finally(() => this.isProcessing = false)
      },
      switchVisibility(field) {
        field.type = field.type === 'password' ? 'text' : 'password'
        return field
      },
      passwordIcon(passwordField) {
        if (passwordField.type === 'password') {
          if (passwordField.hover === true) {
            return require('@/assets/icons/eye-hover.svg')
          } else {
            return require('@/assets/icons/eye.svg')
          }
        } else {
          if (passwordField.type === 'text') {
            if (passwordField.hover === true) {
              return require('@/assets/icons/eye-slash-hover.svg')
            } else {
              return require('@/assets/icons/eye-slash.svg')
            }
          }
        }
      },
      ...mapActions({login: 'auth/login'})
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.password-info {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.remember-me {
  font-size: 14px;
  line-height: 20px;
}

.acc-btn-submit {
  margin-bottom: 20px;
}

.form-checkbox {
  margin-bottom: 5px;
}

.password-input{
  position: relative;
}

.toggle-icon{
  top: 20px;
  right: 20px;
  height: 20px;
  width: 26px;
  display: inline-block !important;
  position: absolute;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .acc-btn-submit {
    margin-bottom: 18px;
  }

  .form-checkbox {
    margin-bottom: 10px;
  }

  .toggle-icon{
    top: 11px;
    right: 11px;
    height: 18px;
    width: 24px;
  }
}
</style>
