<template>
  <b-form @submit.prevent="onSubmit">
    <b-alert class="acc-error" variant="danger" :show="error != null && errorMessage != null" @dismissed="!error">{{ $t(errorMessage) }}</b-alert>
    <span class="acc-description">{{ $t('Authentication.signUp_description') }}</span>
    <b-form-group>
      <b-form-input v-model.trim="form.email" :placeholder="$t('Authentication.signUp_field_email_placeholder')" type="email" />
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.required.$invalid : null">{{ $t('Authentication.signUp_field_email_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.email.$invalid : null">{{ $t('Authentication.signUp_field_email_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.password" :placeholder="$t('Authentication.signUp_field_password_placeholder')" :type="passwordFieldNew.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldNew.hover = true" @mouseleave="passwordFieldNew.hover = false"
             @click="passwordFieldNew = switchVisibility(passwordFieldNew)" :src="passwordIcon(passwordFieldNew)">
      </div>
      <b-form-invalid-feedback :state="v$.form.password.$dirty ? !v$.form.password.required.$invalid : null">{{ $t('Authentication.signUp_field_password_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.password.$dirty ? !v$.form.password.minLength.$invalid : null">{{ $t('Authentication.signUp_field_password_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.repeatPassword" :placeholder="$t('Authentication.signUp_field_repeatPassword_placeholder')" :type="passwordFieldRep.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldRep.hover = true" @mouseleave="passwordFieldRep.hover = false"
             @click="passwordFieldRep = switchVisibility(passwordFieldRep)" :src="passwordIcon(passwordFieldRep)">
      </div>
      <b-form-invalid-feedback :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.required.$invalid : null">{{ $t('Authentication.signUp_field_repeatPassword_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.sameAsPassword.$invalid : null">{{ $t('Authentication.signUp_field_repeatPassword_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="isProcessing" class="acc-btn-submit">{{ $t('Authentication.signUp_confirm') }}</b-button>
    <div>
      <span>{{ $t('Authentication.alreadySignedUp_text') }} <router-link class="acc-form-link" to="/login">{{ $t('Authentication.alreadySignedUp_link') }}</router-link></span>
    </div>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {email, minLength, required, sameAs} from '@vuelidate/validators'
import account from '@/modules/account'

export default {
  name: 'Registration',
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
        repeatPassword: '',
        captchaToken: '',
        locale: ''
      },
      isProcessing: false,
      error: null,
      passwordFieldNew: {
        type: 'password',
        hover: false,
        visible: false
      },
      passwordFieldRep: {
        type: 'password',
        hover: false,
        visible: false
      }
    }
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(6)
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs(this.form.password)
        }
      }
    }
  },
  computed: {
    errorMessage() {
      if (this.error == null) {
        return null
      }
      const fields = {
        'USER_ALREADY_REGISTERED': 'Authentication.signUp_error_user_already_registered',
        'USER_NOT_FOUND': 'Authentication.signUp_error_user_not_found',
      }
      return fields[this.error]
    }
  },
  methods: {
    openLink(link) {
      window.open(link)
    },
    onSubmit() {
      this.error = null
      this.v$.$touch()
      if (this.v$.$invalid || this.isProcessing) {
        return
      }
      this.isProcessing = true
      this.$recaptcha('signup')
          .then(response => {
          this.form.captchaToken = response
          this.form.locale = this.$i18n.locale
          return account.signUp(this.form)
        })
        .then(() => this.$emit('completed'))
        .catch(err => this.error = err.response?.data?.errorKind)
        .finally(() => this.isProcessing = false)
    },
    switchVisibility(field) {
      field.type = field.type === 'password' ? 'text' : 'password'
      return field
    },
    passwordIcon(passwordField) {
      if (passwordField.type === 'password') {
        if (passwordField.hover === true) {
          return require('@/assets/icons/eye-hover.svg')
        } else {
          return require('@/assets/icons/eye.svg')
        }
      } else {
        if (passwordField.type === 'text') {
          if (passwordField.hover === true) {
            return require('@/assets/icons/eye-slash-hover.svg')
          } else {
            return require('@/assets/icons/eye-slash.svg')
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.acc-btn-submit {
  margin-bottom: 35px;
}

.form-checkbox {
  margin-top: 20px;
  margin-bottom: 5px;
}

.password-input{
  position: relative;
}

.toggle-icon{
  top: 20px;
  right: 20px;
  height: 20px;
  width: 26px;
  display: inline-block !important;
  position: absolute;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .acc-btn-submit {
    margin-bottom: 25px;
  }
  .form-checkbox {
    margin-top: 21px;
    margin-bottom: 11px;
  }
  .toggle-icon{
    top: 11px;
    right: 11px;
    height: 18px;
    width: 24px;
  }
}
</style>
