import authApi from '@/modules/authentication'
import accountApi from '@/modules/account'
import messagesApi from '@/modules/messages'

const state = () => ({
  authData: {
    access_token: null,
    refresh_token: null,
    expires: null,
    token_type: ''
  },
  userData: {
    id: null,
    email: null,
    sberbankBindingIdSet: false,
    unreadMessageCount: 0
  }
})

const getters = {
  authData (state) {
    return state.authData
  },
  innerAuthData(state) {
    if (state.authData?.access_token == null) {
      return null
    }
    let tokens = state.authData?.access_token.split('.')
    if (tokens.length < 2) {
      return null
    }
    return JSON.parse(atob(tokens[1]))
  },
  userId(state, getters) {
    return getters.innerAuthData?.sub ? Number.parseInt(getters.innerAuthData?.sub) : null
  },
  userEmail(state) {
    return state.userData?.email
  },
  unreadMessageCount(state) {
    return state.userData?.unreadMessageCount > 0 ? state.userData?.unreadMessageCount : null
  },
  userIsNormal(state, getters) {
    if (!getters.innerAuthData?.groups) {
      if (!state.authData) {
        console.error('no groups')
      }
      return false
    }
    return getters.innerAuthData.groups.findIndex(val => val === 'siteUser') !== -1
  },
  roles(state, getters) {
    return getters.innerAuthData?.groups ?? []
  }
}

const actions = {
  updateAuthData ({ commit }, payload) {
    commit('saveAuthenticationInfo', payload.payload)
  },
  login({ dispatch }, formPayload) {
    return authApi.login(formPayload)
      .then(() => dispatch('updateUserData'))
  },
  managerLogin({ commit }, formPayload) {
    return authApi.managerLogin(formPayload)
                  .then((resp) => {
                    commit('saveARMUserData', resp.data)
                  })
  },
  updateUserData({commit}) {
    return accountApi.getUserInfo()
      .then((resp) => commit('saveUserData', resp.data))
  },
  logout({commit}) {
    return authApi.logout()
                  .then(() => {
                    commit('saveAuthenticationInfo', {})
                    commit('saveUserData')
                  })
  },
  checkUserSession({ getters, dispatch}) {
    return authApi.checkSession()
                  .then(() => {
                    if (getters.userIsNormal) {
                      return dispatch('updateUserData')
                    }
                  })
  },
  checkUnreadMessageCount({commit}) {
    return messagesApi.getUnreadCount()
      .then(response => commit('setUnreadMessageCount', response.data))
  }
}

const mutations = {
  saveAuthenticationInfo (state, payload) {
    state.authData = {
      access_token: payload.session?.access_token ?? payload.access_token,
      refresh_token: payload.session?.refresh_token ?? payload.refresh_token,
      expires: payload.session?.expires ?? payload.expires,
      token_type: payload.session?.token_type ?? payload.token_type,
      email: payload.user_data?.email,
      active: payload.user_data?.active
    }
  },
  saveUserData(state, payload) {
    state.userData = {
      id: payload?.id,
      email: payload?.email,
      sberbankBindingIdSet: payload?.sberbankBindingIdSet,
      unreadMessageCount: payload?.unreadMessageCount != null ? payload?.unreadMessageCount : null,
    }
  },
  saveARMUserData(state, payload) {
    state.userData = {
      username: payload?.user_data.login,
    }
  },
  setUserBinding(state) {
    state.userData.sberbankBindingIdSet = true
  },
  setUnreadMessageCount(state, payload) {
    state.userData.unreadMessageCount = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
