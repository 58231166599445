<template>
  <div>
    <b-modal ref="modal-reserve" size="xl" hide-footer dialog-class="modal-reserve">
      <iframe :style="{ height: height + 'px'}" class="reserve-iframe" scrolling="no"
              :src="reserveUrl" frameborder="0"></iframe>
    </b-modal>
    <b-modal id="my-possibilities-info-modal" body-class="p-0" hide-footer>
      <div class="modal-info-div">
        <div class="modal-info-content">
          <img class="modal-info-icon" :src="require('../assets/icons/info.svg')" alt="info-icon">
          <span v-if="loggedIn" class="modal-info-string">{{ $t('Common.myPossibilities_ticketRequired')}}</span>
          <span v-else class="modal-info-string">{{ $t('Common.myPossibilities_AuthAndTicketRequired')}}</span>
        </div>
      </div>
    </b-modal>
    <b-modal id="my-possibilities-offline-bar-required-modal" body-class="p-0" hide-footer>
      <div class="modal-info-div">
        <div class="modal-info-content">
          <img class="modal-info-icon" :src="require('../assets/icons/info.svg')" alt="info-icon">
          <span class="modal-info-string">{{ $t('Common.myPossibilities_offlineBarRequired')}}</span>
        </div>
      </div>
    </b-modal>
    <div class="container mx-auto my-possibilities-title">
      <span v-if="loggedIn" class="list-title" :data-available="`${available || (loggedIn && !isNormalUser)}`">{{ $t('Common.myPossibilities_title') }}</span>
      <a v-else href="/login">
        <span class="list-title">{{ $t('Common.myPossibilities_title') }}</span>
      </a>
      <img v-if="!(available || (loggedIn && !isNormalUser))" v-b-modal="'my-possibilities-info-modal'" class="icon-info-main"
        :src="require('../assets/icons/info.svg')" alt="info">
    </div>
    <div class="accordion d-flex flex-column possibilities-list" role="tablist">
      <div role="tab" :class="`d-flex flex-column ${(selectedIndex === index) && isSectionAvailable(section) ? 'tab-selected' : ''} tab-item-title`"
        v-for="(section, index) in sections" :key="section.name"
        :data-available="`${isSectionAvailable(section)}`"
        :data-hidden="`${isSectionHidden(section)}`">
        <span :id="`anchor-${index}`" class="anchor"></span>
        <div class="list-item container mx-auto">
          <div class="list-item-body" v-b-toggle="`accordion-${index}`">
            <span v-if="loggedIn" class="list-item-title">{{ section.name }}</span>
            <a class="list-item-title list-item-title-non-authorized" v-else href="/login">
              {{ section.name }}
            </a>
            <div class="list-item-icon">
              <inline-svg class="icon-expand" :src="require('../assets/icons/expand.svg')"/>
              <inline-svg v-b-modal="'my-possibilities-offline-bar-required-modal'"
                          class="icon-info" :src="require('../assets/icons/info.svg')"/>
              <inline-svg class="icon-collapse" :src="require('../assets/icons/collapse.svg')"/>
            </div>
          </div>
        </div>
        <b-collapse appear class="mb-3" :id="`accordion-${index}`"
                    v-if="!isSectionHidden(section)"
                    accordion="my-possibilities" :visible="`#${section.subsectionId}` === preSelectedSection"
                    role="tabpanel"
                    @shown="selectedIndex = index; navigate(`anchor-${index}`, section.subsectionId)"
                    @show="selectedIndex = index"
                    @hide="selectedIndex = -1">
          <div class="section-content collapse-body mt-4 container" v-if="!section.isFeedback">
            <a class="collapse-image mb-5" v-if="!!section.content.image && !!section.content.imageLink && section.content.imageLink!==''"
               :href="section.content.imageLink">
              <img class="collapse-image" :src="section.content.image" alt="tab-image"></a>
            <img v-else-if="!!section.content.image" class="collapse-image mb-5" :src=
              "section.content.image" alt="tab-image">
            <div v-if="!!section.content.description" class="collapse-desc dynamic-content" @click="handleClicks"
                 v-html="section.content.description"></div>
            <div v-if="!!section.content.giftInfo" class="collapse-gift mt-2 mb-2 mt-md-5 mb-md-5 d-table">
              <div class="item-icon d-table-cell">
                <img v-if="!section.showPrivilegedContent" class="mr-3" src="../assets/icons/info.svg"
                     alt="info">
                <img v-else class="mr-3" src="../assets/icons/gift.svg" alt="gift">
              </div>
              <div class="item-text d-table-cell v-align-middle" v-html="section.content.giftInfo"/>
            </div>
            <div v-if="!!section.content.discountInfo" class="collapse-discount mt-2 mb-2 mt-md-5 mb-md-5 d-table">
              <div class="item-icon d-table-cell">
                <img v-if="!section.showPrivilegedContent" class="mr-3" src="../assets/icons/info.svg"
                     alt="info">
                <img v-else class="mr-3" src="../assets/icons/discount.svg" alt="discount">
              </div>
              <div class="item-text d-table-cell v-align-middle" v-html="section.content.discountInfo"/>
            </div>
            <div v-if="!!section.content.alertInfo" class="collapse-alert mt-2 mb-2 mt-md-5 mb-md-5 d-table">
              <div class="item-icon d-table-cell">
                <img class="mr-3" src="../assets/icons/info.svg" alt="info">
              </div>
              <div class="item-text d-table-cell v-align-middle" v-html="section.content.alertInfo"/>
            </div>
          </div>
          <div class="section-content collapse-feedback-body mt-4 container" v-if="section.isFeedback">
            <div class="feedback-item mt-md-3 mb-md-5" v-for="(element) in section.elements" :key="element.text">
              <div class="feedback-image d-table-cell">
                <a :href=element.link target="_blank" class="feedback-link opacity-1">
                  <img :src="element.image" alt="tab-image"/>
                </a>
              </div>
              <div class="feedback-span d-table-cell">
                <a :href=element.link target="_blank" class="opacity-1">{{ element.text }}</a>
              </div>
            </div>
          </div>
          <div class="section-content mt-4 container" v-if="registerForVernissageSubsectionId === section.subsectionId">
            <div class="mt-3 mb-4 event-title-container" v-if="meetings?.length">
              <h2 class="text-center event-title">{{$t('Vernissage.meetings-list-title')}}</h2>
              <button class="slider-button btn-reversed" @click="meetingOffset--" :disabled="meetingOffset <= 0"><img src="../assets/icons/search_go.svg"></button>
              <button :disabled="meetingOffset >= ((meetings?.length || 0) - eventColumnCount)" @click="meetingOffset++" class="slider-button"><img src="../assets/icons/search_go.svg"></button>
            </div>
            <register-meeting :available="available" :offset="meetingOffset"
              @swiped-left="meetingsSwipedLeft"
              @swiped-right="meetingsSwipedRight" />
            <div class="mt-3 mb-4 event-title-container" v-if="vernissages?.length">
              <h2 class="text-center event-title">{{$t('Vernissage.vernissage-list-title')}}</h2>
              <button class="slider-button btn-reversed" :disabled="vernissageOffset <= 0" @click="vernissageOffset--" ><img src="../assets/icons/search_go.svg"></button>
              <button :disabled="vernissageOffset >= ((vernissages?.length || 0) - eventColumnCount)" @click="vernissageOffset++" class="slider-button"><img src="../assets/icons/search_go.svg"></button>
            </div>
            <RegisterForVernisage :available="available" :offset="vernissageOffset" :allowed-annual-pass-types="clubAnnualPassTypes"
              @swiped-left="vernissageSwipedLeft"
              @swiped-right="vernissageSwipedRight" />
          </div>
        </b-collapse>
      </div>
    </div>
    <b-container class="login-container">
      <router-link class="btn btn-primary w-100" v-if="!loggedIn" to="/login">{{$t('Authentication.login_signIn')}}</router-link>
    </b-container>
    <Partners :data="partners" class="mt-3"/>
  </div>
</template>

<script>
  import Partners from './Partners.vue'
  import InlineSvg from 'vue-inline-svg'
  import common from '@/modules/common.js'
  import {mapGetters, mapState} from 'vuex'
  import RegisterForVernisage from './RegisterForVernissage.vue'
  import RegisterMeeting from './RegisterMeeting.vue'
  import moment from 'moment'

  export default {
    name: 'MyPossibilities',
    components: {RegisterForVernisage, RegisterMeeting, Partners, InlineSvg},
    props: {
      data: {
        type: Object
      },
      available: {
        type: Boolean,
        default: false
      },
      preSelectedSection: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        selectedIndex: -1,
        height: 100,
        reserveUrl: null,
        vernissageOffset: 0,
        meetingOffset: 0,
      }
    },
    created () {
      moment.locale(this.$i18n.locale)
    },
    computed: {
      ...mapGetters({
        anyActiveClubPassOrElseAnyActivePass: 'loyalty/anyActiveClubPassOrElseAnyActivePass',
        activePasses: 'loyalty/activePasses',
        activeTickets: 'loyalty/activeTickets',
        existAnnualPassesWithAttrs: 'loyalty/existAnnualPassesWithAttrs',
        isNormalUser: 'auth/userIsNormal',
        roles: 'auth/roles',
      }),
      ...mapState({
        meetings: state => state.meetings.meetings,
        vernissages: state => state.vernissages.vernissages,
        clubAnnualPassTypes: state => state.loyalty.annualPassTypes?.filter(apt => apt.club).map(apt => apt.type)
      }),
      registerForVernissageSubsectionId: function() {
        return this.data[this.$i18n.locale]?.registerForVernissageSubsectionId
      },
      sections: function () {
        const data = this.data[this.$i18n.locale]

        if (data) {
          let sections = data?.subsections?.map(tabItem => {
            const showPrivilegedContent = this.anyAnnualPassHavePrivileges(tabItem?.extendedContent?.annualPassTypes)
            return {
              ...tabItem,
              content: showPrivilegedContent ? tabItem.extendedContent : tabItem.defaultContent,
              isFeedback: false,
              showPrivilegedContent
            }
          })
          if (data.feedback != null) {
            sections.push({
              name: data.feedback.title,
              subsectionId: data.feedback.subsectionId,
              elements: data.feedback.element.map(item => ({
                ...item,
              })),
              hide: data.feedback.hide,
              isFeedback: true
            }
            )
          }
          return sections
        }
        return []
      },
      partners: function () {
        return this.data[this.$i18n.locale]?.partners
      },
      loggedIn() {
        return Boolean(this.$store.getters['auth/authData'].access_token)
      },
      eventColumnCount() {
        let breakPointProp = getComputedStyle(document.body)
          .getPropertyValue('--breakpoint-md')
        return (window.matchMedia(`(min-width: ${breakPointProp})`)?.matches) ? 3 : 1
      }
    },
    methods: {
      receiveMessage(event) {
        switch (event.data.command) {
          case 'resize':
            this.height = event.data.height
            break
          case 'close':
            this.closeModal()
        }
      },
      openModal(el) {
        const reserveUrl = new URL(el.target.attributes['data-url'].value)
        const activeClubPass = this.anyActiveClubPassOrElseAnyActivePass
        if (activeClubPass != null) {
          reserveUrl.searchParams.append('passNumber', activeClubPass)
        }
        this.reserveUrl = reserveUrl
        this.$refs['modal-reserve'].show()
      },
      closeModal() {
        this.$refs['modal-reserve'].hide()
      },
      handleClicks(event) {
        common.handleClicks(event)
      },
      navigate(anchorId, tabId) {
        const element = document.getElementById(anchorId)
        if (element && element.scrollIntoView) {
          element.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'})
        }
        history.pushState(
          {},
          null,
          this.$route.path + '#' + tabId
        )
      },
      anyAnnualPassHavePrivileges(privileges) {
        if (privileges === null || privileges === undefined
            || this.activePasses === null || this.activePasses === undefined) return false
        for (const ap of this.activePasses) {
          if (privileges && privileges.includes(ap.type)) {
            return true
          }
        }
        return false
      },
      isSectionAvailable(section) {
        return this.available || (this.loggedIn && !this.isNormalUser && section.allowedARMRoles?.filter(val => this.roles.includes(val))?.length > 0)
            || (Boolean(section.subsectionShownForOneTimePasses) && this.activeTickets?.length > 0)
      },
      isSectionHidden(section) {
        return section.hide || (!this.isSectionAvailable(section)) || (!this.existAnnualPassesWithAttrs(section.annualPassAttributes))
      },
      vernissageSwipedRight(/*offset*/) {
        if (this.vernissageOffset < ((this.vernissages?.length || 0) - this.eventColumnCount))
          this.vernissageOffset++
      },
      vernissageSwipedLeft(/*offset*/) {
        if (this.vernissageOffset > 0)
          this.vernissageOffset--
      },
      meetingsSwipedRight(/*offset*/) {
        if (this.meetingOffset < ((this.meetings?.length || 0) - this.eventColumnCount))
          this.meetingOffset++
      },
      meetingsSwipedLeft(/*offset*/) {
        if (this.meetingOffset > 0)
          this.meetingOffset--
      },
    },
    mounted() {
      window.addEventListener('message', this.receiveMessage)
    },
    updated() {
      const reserveBtn = document.getElementById('reserveBtn')
      if (reserveBtn) {
        reserveBtn.onclick = this.openModal
      }
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .modal-info-div {
    width: 100%;
    min-height: 458px;
    padding-top: 180px;
  }

  .my-possibilities-title {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  .modal-info-content {
    display: grid;
    grid-template-rows: repeat(2, auto);
  }

  .modal-info-icon {
    display: block;
    height: 33px;
    width: 33px;
    margin-left: 0;
    margin-right: auto;
  }

  .modal-info-string {
    margin-top: 22px;
  }

  .icon-info-main {
    width: 33px;
    height: 33px;
  }

  .anchor {
    scroll-margin-top: 100px
  }

  .list-item-body.not-collapsed {
    padding-top: 15px;
  }

  .list-title {
    font-size: 36px;
    color: #{$dark};
    font-weight: #{$font-weight-medium};
    line-height: 36px;
  }

  .event-title-container {
    display: flex;
  }

  .event-title {
    font-size: 30px;
    color: #{$dark};
    font-weight: #{$font-weight-medium};
    line-height: 30px;
  }

  .list-item {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    text-transform: lowercase;
    margin-bottom: 8px;
  }

  .possibilities-list {
    margin-top: 40px;
    margin-bottom: 42px;
  }

  .list-item-body {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 4fr .2fr 1fr;
    justify-items: center;
    align-items: center;

    text-decoration: none !important;
    color: #{$dark} !important;
  }

  .list-item-title {
    font-size: 24px;
    justify-self: start;
    grid-column-start: 2;
    grid-column-end: 3;
    font-weight: #{$font-weight-medium};
  }

  .list-item-icon {
    grid-column-start: 3;
  }

  .icon-info {
    width: 33px;
    height: 33px;
    display: none;
  }

  .slider-button {
    &.btn-reversed {
      margin-left: auto;
      img {
        transform: rotateZ(180deg);
      }
    }

    border: none;
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;

    img {
      width: 40px;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  .item-icon {
    width: 44px;
    height: 44px;
    grid-column-start: 1;
    vertical-align: middle;
  }
  .item-icon img{
    width: 44px;
    height: 44px;
  }

  .item-text {
    grid-column-start: 2;
  }

  .icon-expand, .icon-collapse {
    width: 22px;
    height: 11px;
  }

  .list-item {
    .list-item-body {
      &[aria-expanded="true"] {
        .icon-collapse {
          display: initial;
        }

        .icon-expand {
          display: none;
        }
      }

      &[aria-expanded="false"] {
        .icon-collapse {
          display: none;
        }

        .icon-expand {
          display: initial;
        }
      }
    }
  }

  .tab-item-title[data-available='true'][data-hidden='true'] {
    .list-item-body {
      .icon-expand,
      .icon-collapse {
        display: none;
      }

      .icon-info {
        display: inherit;
      }
    }
  }

  .tab-item-title[data-hidden='true'] *,
  *[data-available='false'], *[data-available='false'] * {
    color: #{$erarta-light-gray};
  }

  .tab-selected {
    background-color: #{$erarta-very-light-gray};
    transition: background-color 0.1s;
    margin-bottom: 10px;
  }

  .collapse {
    overflow: clip;
  }

  .collapse-body {
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr 4fr .2fr 1fr;
  }

  .collapse-body p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .collapse-image {
    grid-column-start: 1;
    grid-column-end: 5;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
  }

  .collapse-desc {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 4;
    line-height: 1.56;
  }

  .collapse-gift {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    font-size: 16px;
    line-height: 1.2;
  }

  .collapse-discount {
    grid-row-start: 4;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 4;
    font-size: 16px;
    line-height: 1.2;
  }

  .collapse-alert {
    grid-row-start: 5;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 4;
    font-size: 16px;
    line-height: 1.2;
  }

  .collapse-feedback-body {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  .feedback-item {
    display: grid;
    grid-template-rows: 1fr 0.31fr;
  }

  .feedback-image {
    height: 200px;
    width: 200px;
    grid-row-start: 1;
    grid-row-end: 1;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .feedback-span {
    grid-row-start: 2;
    grid-row-end: 2;
    width: 240px;
    margin: 0 auto;
    text-align: center;

    a {
      color: #{$dark} !important;
      display: inline-block;
      line-height: 1.56;
      vertical-align: top;
      font-size: 18px;
    }
  }

  a:focus-visible, a:visited {
    outline: none;
  }

  .login-container {
    margin-bottom: 54px;
  }

  @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
    .login-container {
      max-width: 459px;
    }
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .my-possibilities-title {
      justify-content: space-between;
    }

    .modal-info-icon {
      display: block;
      height: 24px;
      width: 24px;
      margin-left: 0;
      margin-right: auto;
    }

    .icon-info-main, .icon-info {
      height: 24px;
      width: 24px;
    }

    .list-title {
      font-size: 25px;
      line-height: 25px;
    }

    .possibilities-list {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .list-item-title {
      grid-column-start: 1;
      grid-column-end: 5;
      font-size: 16px;
      letter-spacing: -0.03em;
    }

    .list-item-icon {
      min-width: 22px;
      display: inline-grid;
      grid-column-start: 5;
      justify-content: center;
    }

    .collapse-desc {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    .collapse-gift {
      grid-column-start: 1;
      grid-column-end: 5;
      font-size: 14px;
    }

    .collapse-discount {
      grid-column-start: 1;
      grid-column-end: 5;
      font-size: 14px;
    }

    .collapse-alert {
      grid-column-start: 1;
      grid-column-end: 5;
      font-size: 14px;
    }

    .item-icon img{
      height: 22px;
      width: 22px;
    }

    .collapse-feedback-body {
      display: grid;
      grid-template-columns: repeat(2, auto);
    }

    .feedback-image {
      height: 130px;
      width: 130px;
    }

    .feedback-span {
      max-width: 130px;
    }

    .feedback-span a {
      font-size: 13px;
      line-height: 1.45;
    }

    .event-title-container {
      align-items: center;
      justify-content: space-between;
    }

    .event-title {
      font-size: 16px;
      line-height: 16px;
    }

    .icon-expand, .icon-collapse {
      width: 12px;
      height: 6px;
    }

    .anchor {
      scroll-margin-top: 60px
    }
    .icon-info-main {
      height: 24px;
      width: 24px;
    }
    .feedback-span a {
      font-size: 10px;
      line-height: 1.3;
    }
    .anchor {
      scroll-margin-top: 60px
    }
    .section-content{
      margin-top: 0 !important;
    }
    .collapse-image{
      margin-bottom: 10px !important;
    }

    .login-container {
      margin-top: 22px;
      margin-bottom: 40px;
    }

    .slider-button {
      img {
        width: 20px;
      }

      order: 1;

      &.btn-reversed {
        order: -1;
        margin-left: 0;
      }
    }
  }

</style>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  *:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .section-content {
    a {
      color: #{$primary} !important;

      &:hover {
        opacity: 0.7;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
      }
    }

    .arrow-link::after {
      content: url("../assets/icons/my-erarta-arrow.svg");
      padding-left: 10px;
      display: inline-block;
    }
  }

  iframe.reserve-iframe {
    border: none;
    width: 100%;
  }

  .item-text p {
    margin-bottom: 0 !important;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .section-content {
    p,li, ul {
      display: inline-block;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    .modal-reserve {
      width: 72% !important;;
      max-width: 72% !important;;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    .modal-xl.modal-reserve {
      width: 72% !important;
      max-width: 72% !important;
    }
  }

  .tab-item-title {
    &[data-available='false'] {
      .icon-expand > path,
      .icon-collapse > path {
        fill: #{$erarta-light-gray};
      }
    }
  }

  .list-item-title-non-authorized {
    color: #{$dark} !important;
  }
</style>
