<template>
  <div class="order-detail" v-if="order.items">
    <div class="order-detail-header">
      <div class="order-item-title">{{$t('Order.order_items')}}</div>
      <div class="order-item-desc">{{$t('Order.description')}}</div>
      <div class="order-item-count">{{$t('Order.count')}}</div>
      <div class="order-item-price">{{$t('Order.price')}}</div>
    </div>
    <hr class="order-detail-separator">
    <div class="order-detail-body">
      <template v-for="item in order.items">
        <order-detail-row v-bind:key="item.id * 2" :item="item" :isAnnualPass="order.annualPass"></order-detail-row>
      </template>
    </div>
    <div class="order-detail-bottom">
      <dl>
        <div class="urgent_price" v-if="order.urgentPayment">
          <dt class="title">{{ $t('Order.urgent_price') }}</dt>
          <dd><span>{{ order.urgentPayment }}</span></dd>
        </div>
        <div class="gift_wrap_price" v-if="order.giftWrapCost">
          <dt class="title">{{ $t('Order.gift_wrap_price') }}</dt>
          <dd><span>{{ order.giftWrapCost }}</span></dd>
        </div>
        <div class="delivery_price" v-if="order.deliveryPrice && order.deliveryPrice !== 0">
          <dt class="title">{{ $t('Order.delivery_price') }}</dt>
          <dd><span>{{ order.deliveryPrice }}</span></dd>
        </div>
        <div class="total_price">
          <dt class="title">{{ $t('Order.total_price') }}</dt>
          <dd><span class="full_price" v-if="order.discount && order.discount !== 0">{{ order.totalPrice }}</span><span
            class="price"> {{ order.totalPrice - order.discount }}</span></dd>
        </div>
        <div class="discount" v-if="order.discount && order.discount !== 0">
          <dt class="title">{{ $t('Order.discount') }}</dt>
          <dd><span>{{order.discount}}</span></dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import OrderDetailRowView from '@/components/order/OrderDetailRowView'

export default {
  name: 'OrderDetailView',
  components: {
    'order-detail-row' : OrderDetailRowView
  },
  props: {
    order: {
      type: Object,
      required: true
    },
  }
}
</script>

<style lang="scss">
.orders {
  .price {
    font-weight: 500;
  }
  .order-detail-header {
    font-weight: 500;
    font-size: 30px;
  }
  .order-detail-separator {
    border-top: 2px solid #C4C4C4;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .order-detail-header {
    display: flex;
  }
  .order-item-title {
    width: 32%;
  }
  .order-item-desc {
    width: 40%;
  }
  .order-item-count {
    text-align: center;
    width: 13%;
  }
  .order-item-price {
    width: 15%;
  }

  .order-detail-bottom {
    text-align: end;
  }

  .delivery_price, .urgent_price, .gift_wrap_price {
    font-size: 24px;
    margin-top: 10px;
    line-height: 24px;
  }

  .delivery_price .title {
    font-weight: 500;
  }

  .discount {
    font-size: 24px;
    margin-top: 30px;
    line-height: 24px;
  }

  .discount .title {
    font-weight: 400;
  }

  .total_price {
    font-size: 36px;
    margin-top: 42px;
    line-height: 36px;
  }

  .order-detail-bottom span::after {
    content: ' ₽';
  }

  .full_price {
    color: #87878A;
    text-decoration-line: line-through;
  }
  @media (max-width: 1023px) {
    .order-detail-header {
      display: none;
    }
    .order-detail-separator {
      border-top: 1px solid #C4C4C4 !important;
    }
    .order-detail-bottom {
      text-align: start;
      padding-bottom: 10px;
    }
    .delivery_price, .urgent_price, .gift_wrap_price {
      font-size: 17px;
      margin-top: 2px;
      line-height: 20px;
    }
    .total_price {
      margin-top: 10px;
      font-size: 25px;
      line-height: 25px;
    }
    .discount {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      .title {
        font-weight: unset !important;
      }
      .title::after {
        content: " ";
      }
    }
    dl {
      margin-bottom: 0;
    }
    .order {
      .title-separator {
        margin-top: 30px !important;
      }
    }
    .order-item-title {
      width: 100%;
    }
    .order-item-desc {
      width: 100%;
    }
    .order-item-count {
      width: 100%;
    }
    .order-item-price {
      width: 100%;
    }
  }
}
</style>
