<template>
    <b-form class="bind-form" @submit.prevent="onSubmit">
        <b-form-group>
            <b-form-input :placeholder="$t('Loyalty.binding_number_placeholder')" v-model="v$.form.number.$model"
                :state="v$.form.number.$dirty ? !v$.form.number.$error: null"
                :aria-describedby="$refs['number-description'] ? $refs['number-description'].id : null"/>
            <b-form-text ref="number-description">{{$t('Loyalty.binding_number_description')}}</b-form-text>
        </b-form-group>
        <b-form-group>
            <b-form-input
                :state="v$.form.ownerName.$dirty ? !v$.form.ownerName.$error: null"
                :placeholder="$t('Loyalty.binding_name_placeholder')" v-model="v$.form.ownerName.$model"  :aria-describedby="$refs['name-description'] ? $refs['name-description'].id : null"/>
            <b-form-text ref="name-description">{{$t('Loyalty.binding_name_description')}}</b-form-text>
            <b-form-invalid-feedback :state="!submissionError">{{$t('Loyalty.binding_error')}}</b-form-invalid-feedback>
        </b-form-group>
        <b-button variant="primary" class="d-block w-100" type="submit" :disabled="!formEnabled">{{$t('Loyalty.binding_submit')}}</b-button>
    </b-form>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core'
    import {required, minLength} from '@vuelidate/validators'
    import { mapActions } from 'vuex'

    export default {
      validations: {
        form: {
          number: {
            required,
            minLength: minLength(7)
          },
          ownerName: {
            required
          }
        }
      },
      props: {
        offset: {
          type: Number,
          default: 0,
        }
      },
      setup () {
        return { v$: useVuelidate() }
      },
      data() {
        return {
          submissionError: false,
          formEnabled: true,
          form: {
            ownerName: null,
            number: null
          }
        }
      },
      methods: {
        onSubmit() {
          this.v$.$touch()
          this.submissionError = false
          if (this.v$.$invalid || !this.formEnabled) {
            return
          }
          this.formEnabled = false
          this.bindAnnualPass({
            number: this.form.number.trim(),//обрезано т.к. кое-кому не нравится
            ownerName: this.form.ownerName
          }).then((annualPass) => {
            this.form.number = null
            this.form.ownerName = null
            this.v$.form.$reset()
            this.$emit('pass-bound', annualPass)
          }).catch(() => {
            this.v$.form.$reset()
            this.submissionError = true
          }).finally(() => {
            this.formEnabled = true
          })
        },
        ...mapActions({bindAnnualPass: 'loyalty/bindAnnualPass' }),
      }
    }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .bind-form {
    .form-group {
      margin-bottom: 30px;
    }
    .btn-primary.disabled, .btn-primary:disabled {
      opacity: 1;
    }
    margin-bottom: 0;
  }

  small.form-text {
    font-size: 14px;
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .bind-form {
      margin-bottom: 0;
    }
  }
</style>
