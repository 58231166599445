<template>
  <b-tabs v-model="startTab" @activate-tab="onTabChange">
    <b-tab v-for="(round, index) in data.voteRounds" :key="index" :title="round.title" :disabled="!isTabAvailable(round, index)">
      <vote-round v-if="isTabAvailable(round, index)"
        :input-objects="data.voteObjects"
        :available-objects="availableObjects[index]"
        :current-results="currentResults[index]"
        :process-id="data.identifier"
        :round="round"
        :round-index="index"
        :last-round="(index + 1) == data.voteRounds.length"
        :prize="data.voteRounds[index].prize"
        :annual-passes="annualPasses"
        :privileged-mode="privilegedMode"
        :selected-annual-pass="selectedAnnualPass"
        :icon="data.icon"
        @annual-pass-number-reset="$emit('annual-pass-number-reset')"
        @annual-pass-changed="val => $emit('annual-pass-changed', val)"
        @voted="updateResults"></vote-round>
    </b-tab>
  </b-tabs>
</template>

<script>
import VoteRound from '@/components/vote/VoteRound'
import moment from 'moment'
import voteApi from '@/modules/vote'

export default {
  name: 'VoteBody',
  components: {VoteRound},
  props: {
    data: {
      type: Object
    },
    annualPasses: {
      type: Array,
      default: () => []
    },
    selectedAnnualPass: {
      type: Object,
    },
    privilegedMode: {
      type: Boolean,
      default: false
    },
    inputTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rounds: {},
      availableObjects: [],
      currentResults: {},
      startTab: this.inputTab
    }
  },
  async mounted() {
    try {
      let resp = await voteApi.getAvailableObjects(this.data.identifier)
      this.availableObjects = resp.data?.map(round => round.map(item => item.objectCode))
    } catch (e) { /*noop*/ }
    this.updateResults()
  },
  methods: {
    isRoundActual(round) {
      return moment().isBetween(moment(round.startDate), moment(round.endDate))
    },
    isTabAvailable(round, index) {
      // Вкладка доступна, если:
      return (this.selectedAnnualPass || this.annualPasses.length > 0) // Есть право на голосование
        && (this.isRoundActual(round) || moment().isAfter(moment(round.endDate))) // И вкладка соответствует текущему, либо прошедшим турам
        || index === 0 // Первую вкладку с картинами отображаем всегда, даже если права проголосовать нет
    },
    onTabChange(newIndex) {
      history.replaceState(
        {},
        null,
        this.$route.path + '?round=' + (newIndex + 1))
    },
    updateResults() {
      voteApi.getCurrentResults(this.data.identifier)
        .then(response => this.currentResults = response.data?.tours)
    }
  },
  computed: {}
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.tabs .nav-tabs {
  border: none;
  max-width: 1240px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

.tabs .nav-tabs .nav-item {
  margin-right: 43px;
}

.tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 0;
  line-height: 24px;
  font-size: 24px;
  letter-spacing: 0.002em;
  color: #{$secondary};
  font-weight: 500;
  margin: 0;
}

.nav-tabs .nav-link.active {
  color: #{$dark} !important;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {

  .tabs .nav-tabs {
    display: inline-block;
  }

  .tabs .nav-tabs .nav-item {
    margin: 0;
  }

  .tabs .nav-tabs .nav-item:not(:last-child) {
    padding: 0 0 4px 0;
  }

  .tabs .nav-tabs .nav-item .nav-link {
    border: none;
    line-height: 28px;
    font-size: 20px;
    letter-spacing: 0.002em;
    color: #{$secondary};
    font-weight: 500;
  }

  .vote-filter-label input {
    font-size: 18px;
  }

}

</style>
