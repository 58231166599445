<template>
  <b-form @submit.prevent="onSubmit">
    <b-alert class="acc-error" variant="danger" :show="error != null && errorMessage != null" @dismissed="!error">{{ $t(errorMessage) }}</b-alert>
    <span class="acc-description">{{ $t('Authentication.passwordRecover_description') }}</span>
    <b-form-group>
      <b-form-input v-model.trim="form.email" :placeholder="$t('Authentication.passwordRecover_field_email_placeholder')" type="email" />
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.required.$invalid : null">{{ $t('Authentication.passwordRecover_field_email_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.email.$dirty ? !v$.form.email.email.$invalid : null">{{ $t('Authentication.passwordRecover_field_email_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="isProcessing">{{ $t('Authentication.passwordRecover_send') }}</b-button>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {email, required} from '@vuelidate/validators'
import account from '@/modules/account'

export default {
  name: 'PasswordRecoveryRequest',
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        email: '',
        captchaToken: '',
        locale: ''
      },
      isProcessing: false,
      error: null
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  computed: {
    errorMessage() {
      if (this.error == null) {
        return null
      }
      const fields = {
        'USER_NOT_FOUND': 'Authentication.passwordRecover_error_user_not_found',
        'USER_INACTIVE': 'Authentication.passwordRecover_error_user_inactive',
      }
      return fields[this.error]
    }
  },
  methods: {
    onSubmit() {
      this.error = null
      this.v$.$touch()
      if (this.v$.$invalid || this.isProcessing) {
        return
      }
      this.isProcessing = true
      this.$recaptcha('recoverPassword')
          .then(response => {
            this.form.captchaToken = response
            this.form.locale = this.$i18n.locale
            return account.recoverPassword(this.form)
          })
          .then(() => this.$emit('completed'))
          .catch(err => this.error = err.response?.data?.errorKind)
          .finally(() => this.isProcessing = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.acc-description {
  color: var(--erarta-black);
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
  letter-spacing: 0;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .acc-description {
    font-size: 16px;
    margin-bottom: 35px;
  }
}
</style>
