<template>
  <b-container>
    <back-link :nav-text="$t('Messages.backLink')" />
    <message :id="$route.params.id"/>
  </b-container>
</template>

<script>
  import Message from '@/components/Message'
  import BackLink from '@/components/common/BackLink'

  export default {
    name: 'MessagePage',
    components: {Message, BackLink}
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .back-link {
    margin-bottom: -50px;
    height: 25px;
    margin-top: 25px;

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      margin-bottom: -50px;
      height: 25px;
      margin-top: 25px;
    }
  }
</style>
