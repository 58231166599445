import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '../views/MainPage'
import LoginPage from '@/views/LoginPage'
import LoyaltyPage from '@/views/LoyaltyPage'
import BuildingPlanPage from '@/views/BuildingPlanPage'
import store from '../store/index'
import RegistrationPage from '@/views/RegistrationPage'
import PasswordRecoveryRequestPage from '@/views/PasswordRecoveryRequestPage'
import DevelopPage from '@/views/DevelopPage'
import OrdersPage from '@/views/OrdersPage'
import RegistrationConfirmPage from '@/views/RegistrationConfirmPage'
import PasswordRecoveryConfirmPage from '@/views/PasswordRecoveryConfirmPage'
import EmailAndPasswordPage from '@/views/EmailAndPasswordPage'
import AudioGuidePage from '@/views/AudioGuidePage'
import AudioGuideDetailPage from '@/views/AudioGuideDetailPage'
import AudioGuideExhibitDetailPage from '@/views/AudioGuideExhibitDetailPage'
import AudioGuideHowtoPage from '@/views/AudioGuideHowtoPage'
import {i18n} from '@/modules/locale'
import MessageListPage from '@/views/MessageListPage'
import MessagePage from '@/views/MessagePage'

import PrivateAudioGuidePage from '@/views/PrivateAudioGuidePage'
import PrivateAudioGuideDetailPage from '@/views/PrivateAudioGuideDetailPage'
import PrivateAudioGuideExhibitDetailPage from '@/views/PrivateAudioGuideExhibitDetailPage'
import VotePage from '@/views/VotePage'
import VoteDetailedPage from '@/views/VoteDetailedPage'
import PrivilegedVotePage from '@/views/PrivilegedVotePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      authRequired: false,
      title: () => i18n.t('Authentication.login_title')
    },
    props: route => ({
      // null воспринимается как факт установки свойства
      from: route.query.from ? route.query.from : undefined
    })
  },
  {
    path: '/private_login',
    name: 'PrivateLoginPage',
    component: () => import(/* webpackChunkName: "privateLoginPage" */ '@/views/PrivateLoginPage'),
    meta: {
      authRequired: false,
      title: () => i18n.t('Authentication.private_login_title')
    },
    props: route => ({
      // null воспринимается как факт установки свойства
      from: route.query.from ? route.query.from : undefined
    })
  },
  {
    path: '/signup',
    name: 'RegistrationPage',
    component: RegistrationPage,
    meta: {
      authRequired: false,
      customTitle: true,
    }
  },
  {
    path: '/confirmRegistration',
    name: 'RegistrationConfirmPage',
    component: RegistrationConfirmPage,
    meta: {
      authRequired: false,
      title: () => i18n.t('Authentication.accountActivation_title')
    }
  },
  {
    path: '/recoverPassword',
    name: 'PasswordRecoveryRequestPage',
    component: PasswordRecoveryRequestPage,
    meta: {
      authRequired: false,
      title: () => i18n.t('Authentication.passwordRecover_title'),
    }
  },
  {
    path: '/confirmPasswordRecovery',
    name: 'PasswordRecoveryConfirmPage',
    component: PasswordRecoveryConfirmPage,
    meta: {
      authRequired: false,
      title: () => i18n.t('Authentication.passwordRecover_change_title')
    }
  },
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
    meta: {
      authRequired: false,
      title: () => i18n.t('Common.myErarta_title')
    }
  },
  {
    path: '/loyalty',
    name: 'Loyalty',
    component: LoyaltyPage,
    meta: {
      authRequired: true,
      title: () => i18n.t('Loyalty.title')
    }
  },
  {
    path: '/buildingPlan',
    name: 'BuildingPlanPage',
    component: BuildingPlanPage,
    meta: {
      authRequired: true,
      title: () => i18n.t('BuildingPlan.title')
    }
  },
  {
    path: '/emailAndPassword',
    name: 'EmailAndPasswordPage',
    component: EmailAndPasswordPage,
    meta: {
      authRequired: true,
      title: () => i18n.t('Common.email_and_password_title')
    }
  },
  {
    path: '/messages',
    name: 'MessageListPage',
    component: MessageListPage,
    meta: {
      authRequired: true,
      title: () => i18n.t('Messages.title')
    }
  },
  {
    path: '/messages/:id',
    name: 'MessagePage',
    component: MessagePage,
    meta: {
      authRequired: true,
      title: () => i18n.t('Messages.title')
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersPage,
    meta: {
      authRequired: true,
      title: () => i18n.t('Order.title')
    }
  },
  {
    path: '/audioGuide',
    name: 'AudioGuideList',
    component: AudioGuidePage,
    meta: {
      authRequired: true,
      title: () => i18n.t('AudioGuides.title')
    }
  },
  {
    path: '/audioGuide/howto',
    name: 'AudioGuideHowtoPage',
    component: AudioGuideHowtoPage,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/audioGuide/:guideId',
    name: 'AudioGuideDetail',
    component: AudioGuideDetailPage,
    meta: {
      authRequired: true,
      customTitle: true
    }
  },
  {
    path: '/audioGuide/:guideId/:exhibitCode',
    name: 'AudioGuideExhibitDetail',
    component: AudioGuideExhibitDetailPage,
    meta: {
      authRequired: true,
      customTitle: true
    }
  },
  {
    path: '/develop',
    name: 'DevelopPage',
    component: DevelopPage,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/privateAudioGuide',
    name: 'PrivateAudioGuideList',
    component: PrivateAudioGuidePage,
    meta: {
      authRequired: false,
      title: () => i18n.t('AudioGuides.title')
    }
  },
  {
    path: '/privateAudioGuide/:guideId',
    name: 'PrivateAudioGuideDetail',
    component: PrivateAudioGuideDetailPage,
    meta: {
      authRequired: false,
      customTitle: true,
      props: {
        indexPath: 'private_guide/audio_guide/index.json'
      }
    },
    props: {
      indexPath: 'private_guide/audio_guide/index.json',
      guidePathPrefix: '/privateAudioGuide'
    }
  },
  {
    path: '/privateAudioGuide/:guideId/:exhibitCode',
    name: 'PrivateAudioGuideExhibitDetail',
    component: PrivateAudioGuideExhibitDetailPage,
    meta: {
      authRequired: false,
      customTitle: true,
      props: {
        indexPath: 'private_guide/audio_guide/index.json'
      }
    },
    props: {
      indexPath: 'private_guide/audio_guide/index.json',
      guidePathPrefix: '/privateAudioGuide'
    }
  },
  {
    path: '/vote/:voteId(\\d+)',
    name: 'Vote',
    component: VotePage,
    meta: {
      authRequired: true,
      customTitle: true
    },
    props: route => ({
      voteId: route.params.voteId != null ? parseInt(route.params.voteId) : null,
      round: route.query.round != null ? (parseInt(route.query.round) - 1) : null,
      number: route.query.number,
    }),
  },
  {
    path: '/vote/:voteId(\\d+)/theater-results',
    name: 'VoteTheaterModeResults',
    component: () => import(/* webpackChunkName: "voteTheaterResultsPage" */ '@/views/VoteTheaterModeResultsPage'),
    meta: {
      authRequired: false,
      customTitle: false,
      noHeader: true,
      title: () => i18n.t('Vote.results-page-title')
    },
    props: route => ({
      mode: route.query.mode != null ? parseInt(route.query.mode) : 0,
      voteId: route.params.voteId != null ? parseInt(route.params.voteId) : null,
    }),
  },
  {
    path: '/vote/:voteId(\\d+)/theater-notification',
    name: 'VoteTheaterNotificationPage',
    component: () => import(/* webpackChunkName: "voteTheaterNotificationPage" */ '@/views/VoteTheaterNotificationPage'),
    meta: {
      authRequired: false,
      customTitle: false,
      noHeader: true,
      title: () => i18n.t('Vote.results-page-title')
    },
    props: route => ({
      voteId: route.params.voteId != null ? parseInt(route.params.voteId) : null,
    }),
  },
  {
    path: '/vote/:voteId(\\d+)/:voteObjectId(\\d+)',
    name: 'VoteDetail',
    component: VoteDetailedPage,
    meta: {
      authRequired: true,
      customTitle: true
    },
    props: route => ({
      voteId: route.params.voteId != null ? parseInt(route.params.voteId) : null,
      voteObjectId: route.params.voteObjectId != null ? parseInt(route.params.voteObjectId) : null,
      round: route.query.round != null ? (parseInt(route.query.round) - 1) : null,
      annualPassNumber: route.query.annualPassNumber
    }),
  },
  {
    path: '/privileged-vote/:voteId(\\d+)',
    name: 'Privileged vote',
    component: PrivilegedVotePage,
    props: route => ({
      // null воспринимается как факт установки свойства
      number: route.query.number ? route.query.number : undefined,
      voteId: route.params.voteId
    }),
    meta: {
      authRequired: false,
      customTitle: true
    }
  },
  {
    path: '*',
    redirect: MainPage
  }
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // используются костыли
    if (to.path === '/buildingPlan') {
      return null
    }
    return new Promise((resolve) => {
      if (to.hash) {
        setTimeout(() => {
          let el = document.querySelector(to.hash)
          let offset = el ? parseFloat(getComputedStyle(el).scrollMarginTop) : 0
          resolve({
            selector: to.hash,
            behavior: 'smooth',
            offset: { y: offset }
          })}, 200)
      } else if (savedPosition) {
        setTimeout(() => {
          savedPosition.behavior = 'smooth'
          resolve(savedPosition)
        }, 200)
      } else {
        resolve()
      }
    })
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    window.scrollTo(0, 0)
  }

  let lang = to.query.lang
  if (lang === 'ru' || lang === 'en') {
    i18n.locale = lang
  }

  let hasAuthentication = true
  try {
    await store.dispatch('auth/checkUserSession')
  } catch {
    hasAuthentication = false
  }

  if (hasAuthentication && store.getters['auth/userIsNormal']) {
    await Promise.all([store.dispatch('auth/checkUnreadMessageCount'),
                 store.dispatch('loyalty/loadAnnualPassTypeList'),
                 store.dispatch('loyalty/loadAnnualPassList'),
                 store.dispatch('loyalty/loadTicketList')])
  }

  if (to.meta.authRequired && !hasAuthentication) {
    next({name: 'LoginPage', query: {from: to.fullPath}})
  } else if (!to.meta.authRequired && hasAuthentication && !['MainPage', 'VoteTheaterModeResults', 'VoteTheaterNotificationPage'].includes(to.name)) {
    next('/')
  } else {
    next()
  }
})

export default router
