<script>
  import { BIconCheckSquareFill, BIconExclamationSquareFill, BIconXSquareFill } from 'bootstrap-vue'
  export default {
    props: {
      statusMessage: {
        type: String,
        required: false
      }
    },
    components: { BIconCheckSquareFill, BIconExclamationSquareFill, BIconXSquareFill },
    render(h) {
      if (this.statusMessage === 'expires') {
        return h('b-icon-exclamation-square-fill', {
          attrs: {
            variant: 'warning'
          }
        })
      } else if (this.statusMessage === 'active') {
        return h('b-icon-check-square-fill', {
          attrs: {
            variant: 'success'
          }
        })
      }
      return h('b-icon-x-square-fill', {
        attrs: {
          variant: 'danger'
        }
      })
    }
  }
</script>
