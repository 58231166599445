import accountApi from '@/modules/account'


const state = () => ({
  orders: null,
  ordersLoaded: false,
  ordersLoadPromise: null
})

const getters = {
  orders: state => state.orders
}

const mutations = {
  saveOrderList(state, payload) {
    state.orders = payload
    state.ordersLoaded = true
  },
  ordersUpdatePending(state, payload) {
    state.ordersLoadPromise = payload
  }
}

const actions = {
  loadOrderList({state, commit}) {
    if (state.orders == null || !state.ordersLoaded) {
      if (state.ordersLoadPromise == null) {
        let ordersLoadPromise = accountApi.getOrderList()
        .then(resp => {
          commit('saveOrderList', resp.data)
          return resp.data
        })
        .finally(() => {
          commit('ordersUpdatePending', null)
        })
        commit('ordersUpdatePending', ordersLoadPromise)
        return ordersLoadPromise
      }
      else {
        return state.ordersLoadPromise
      }
    }
    return Promise.resolve(state.orders)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
