<template>
  <div class="guide-content">
    <div class="tour-guide-content" v-if="guideContent.regular" v-html="guideContent.tourGuide.description"></div>
    <search-form v-if="guideContent.regular" @submit="e => $emit('number-submitted', e)"/>
    <template v-for="(track, index) in guideContent.audioTracks">
      <h2 v-if="track.title" :key="index * 2">{{track.title}}</h2>
      <trackable-audio :key="track.file" :soundFile="track.file"></trackable-audio>
      <div v-if="track.description" :key="index * 2 + 1" v-html="track.description" />
    </template>
    <!-- TODO: влепить план этажей -->
  </div>
</template>
<script>
import SearchForm from './SearchForm'
import TrackableAudio from '@/components/TrackableAudio'

export default {
    components: {
      'search-form': SearchForm,
      'trackable-audio': TrackableAudio
    },
    props: {
      guideIndex: {
        type: Object,
        required: true,
      },
      guideContent: {
        type: Object,
        required: true
      },
      guideGuid: {
        type: String,
        required: true
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .guide-content {
    h2:first-child {
      margin-top: 80px;
    }

    audio {
      width: 100%;
      margin-top: 64px;
      margin-bottom: 47px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      display: flex;
      flex-direction: column;

      h2:first-child {
        margin-top: 30px;
      }
      > .tour-guide-content {
        order: -1;

        > *:last-child {
          margin-bottom: 0;
        }
      }

      > .guide-search-form {
        order: -2;
      }
      .guide-search-form {
        margin-bottom: 20px;
      }

      audio {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
      }

      audio:first-child {
        margin-top: 20px;
      }
    }
  }
</style>
