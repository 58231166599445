<template>
  <div class="vote-detail-footer">
    <template v-for="(video, index) in objectData.videos">
      <h3 class="container small-container" :key="index * 2 + 1">{{video.title}}</h3>
      <b-container :key="index * 2">
        <iframe class="yt-frame" :src="video.link" frameborder="0"></iframe>
      </b-container>
    </template>
    <div class="small-container container" v-for="(track, index) in objectData.audioTracks" :key="index + 1024">
      <h3 >{{track.title}}</h3>
      <trackable-audio :soundFile="track.file"></trackable-audio>
      <div v-html="track.description"></div>
    </div>
  </div>
</template>

<script>

  import TrackableAudio from '@/components/TrackableAudio'

  export default {
    components: {
      'trackable-audio': TrackableAudio
    },
    props: {
      objectData: {
        required: true,
        type: Object
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .yt-frame {
    width: 100%;
    height: auto;
  }

  .vote-detail-footer {
    h3 {
      font-size: 36px;
      line-height: 39px;
    }

    iframe {
      margin-top: 40px;
      aspect-ratio: 16 / 9;
    }

    & > .container.small-container:not(:first-child) {
      margin-top: 80px;
    }

    audio {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      h3 {
        font-size: 25px;
        line-height: 25px;
      }

      & > .container.small-container:not(:first-child) {
        margin-top: 40px;
      }

      iframe {
        margin-top: 20px;
      }

      audio {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

  }
</style>
