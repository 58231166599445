import {VERNISSAGE_AXIOS} from '@/modules/backend-config'

export default {
  eventRegister(meetingGuid, visitorsRequest) {
    return VERNISSAGE_AXIOS.post(`/${meetingGuid}/register`, visitorsRequest)
  },
  getEventRegistrationCount(guid) {
    return VERNISSAGE_AXIOS.get(`/${guid}/registrationCount`)
  },
  getEventRegistrationCountList(meetingGuidList) {
    return VERNISSAGE_AXIOS.get('/countRegistrations', {
      guids: meetingGuidList
    })
  }

}
