<template>
  <b-form class="renewal-form" @submit.prevent="onSubmit" v-if="availableProducts">
    <b-alert class="acc-error" variant="danger" v-if="sberbankError != null && sberbankError.errorCode != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_interaction_error',{ errorCode: sberbankError.errorCode,  errorMessage: sberbankError.errorMessage }) }}</b-alert>
    <b-alert class="acc-error" variant="danger" v-else-if="sberbankError != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_http_error', { statusCode: sberbankError.statusCode })}}</b-alert>
    <b-form-group :label="$t('Loyalty.renewal_numberLabel')">
      <b-form-input :placeholder="$t('Loyalty.renewal_numberLabel')" readonly :value="value.number"/>
    </b-form-group>
    <b-form-group :label="$t('Loyalty.renewal_typeLabel')">
      <b-form-select :placeholder="$t('Loyalty.renewal_typeLabel')" v-model="v$.selectedType.$model" :options="availableExtensibleTypes" />
    </b-form-group>
    <b-form-group :label="$t('Loyalty.renewal_duration_label')">
      <b-form-input :placeholder="$t('Loyalty.renewal_duration_label')" readonly :value="$t('Loyalty.renewal_duration_annual')" />
    </b-form-group>
    <b-form-group class="price-group" v-if="renewalCost != null">
      <span class="price-description font-weight-medium">{{$t('Loyalty.renewal_cost_label')}}</span> <template v-if="specialOfferEnabled"><del>{{$t('Loyalty.renewal_cost_value', [renewalBaseCost])}}</del></template> <span class="font-weight-medium">{{$t('Loyalty.renewal_cost_value', [renewalCost])}}</span>
    </b-form-group>
    <b-form-checkbox v-model="v$.termsAccepted.$model"
      :aria-describedby="$refs['personal-agreement-accept-text'] ? $refs['personal-agreement-accept-text'].id : null"
      :state="v$.termsAccepted.$dirty ? !v$.termsAccepted.$error : null">
      <i18n path="Loyalty.renewal_personalAgreement_agreement" :tag="false">
        <template #firstTerm>
          <a target="_blank" :href="$t('Loyalty.userAgreement_link')">{{$t('Loyalty.renewal_personalAgreement_userAgreement')}}</a>
        </template>
        <template #secondTerm>
          <a target="_blank" :href="$t('Loyalty.passUsage_rules_link')">{{$t('Loyalty.renewal_personalAgreement_passUsageAgreement')}}</a>
        </template>
      </i18n>
    </b-form-checkbox>
    <b-form-invalid-feedback
      :state="v$.termsAccepted.$dirty ? !v$.termsAccepted.$error : null"
      ref="personal-agreement-accept-text">{{$t('Loyalty.renewal_personalAgreement_mandatory')}}</b-form-invalid-feedback>
    <b-form-checkbox v-model="v$.subscribeForAutoRenewal.$model"
      :state="v$.subscribeForAutoRenewal.$dirty ? !v$.subscribeForAutoRenewal.$error : null"
      class="mt-2">
      <i18n path="Loyalty.renewal_autoRenewal_agreement">
        <template #term>
          <a target="_blank" :href="$t('Loyalty.renewal_rules_link')">{{$t('Loyalty.renewal_autoRenewal_term')}}</a>
        </template>
      </i18n>
    </b-form-checkbox>
    <b-button variant="primary" class="d-block w-100" type="submit" :disabled="!renewalButtonAvailable">{{$t('Loyalty.renewal_submit')}}</b-button>
    <b-modal ref="renewal-payment" dialog-class="renewal-modal" size="xl" v-if="paymentUrl"
      body-class="p-0"
      :hide-footer="true"
      :title="$t('Loyalty.renewal_payment_title')">
      <iframe :src="paymentUrl" class="renewal-iframe" />
    </b-modal>
  </b-form>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import {required} from '@vuelidate/validators'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'
  import membershipApi from '@/modules/membership'
  export default {
    props: {
      value: {
        type: Object,
        required: true
      },
      loyaltyPageInfo: {
        type: Object,
        default: null,
        required: false
      },
    },
    setup () {
      return { v$: useVuelidate() }
    },
    watch: {
      value: {
        immediate: true,
        handler(val, oldVal) {
          if (oldVal == null && val != null || val?.type !== oldVal?.type) {
            this.selectedType = val.type
          }
        }
      }
    },
    validations: {
      selectedType: {
        required
      },
      termsAccepted: {
        required,
        isTrue: (val) => val,
      },
      subscribeForAutoRenewal: {
        required
      }
    },
    computed: {
      annualPassExpired() {
        return moment(this.value.expires).isBefore(moment())
      },
      availableExtensibleTypes() {
        let titleField = this.$i18n.locale === 'ru' ? 'titleRu' : 'titleEn'
        return this.availableProducts.map(apt => {
          return {
            text: apt[titleField],
            value: apt.type
          }
        })
      },
      availableProducts() {
        let products = this.extensibleProducts
        if (!this.annualPassExpired) {
          // тут запрет выводить товары других типов
          products = products?.filter(apt => apt.type === this.value?.type && apt.renewalAvailable)
        }
        if (!this.value?.retiree) {
          return products
        }
        // ищу только непродлеваемые типы абонементов, т.к. они уже есть в списке выше
        let discountedPassProducts = this.allProducts?.filter(apt => apt.type === this.value?.type && !apt.renewalAvailable)
        return products?.concat(discountedPassProducts) ?? discountedPassProducts
      },
      specialOfferEnabled() {
        return this.value?.type === this.selectedType &&
               this.value?.specialPrice != null &&
               !moment(this.value.specialPrice.expires).isBefore(moment()) &&
               this.value.specialPrice.renewalPrice != null &&
               this.value.specialPrice.renewalPrice < this.renewalBaseCost
      },
      renewalCost() {
        return this.specialOfferEnabled ? this.value?.specialPrice?.renewalPrice : this.renewalBaseCost
      },
      renewalBaseCost() {
        return this.availableProducts
                   .filter(apt => apt.type === this.selectedType && apt.duration === 1)
                   .map(apt => {
                     if (this.annualPassExpired) {
                       return this.subscribeForAutoRenewal ? apt.expiredProlongationCheckedPrice : apt.expiredProlongationPrice
                     } else {
                       return this.subscribeForAutoRenewal ? apt.prolongationCheckedPrice : apt.prolongationPrice
                     }
  })[0]
      },
      ...mapGetters({
        extensibleProducts:'loyalty/extensibleAnnualPassTypes',
        allProducts: 'loyalty/allAnnualPassTypes',
        email: 'auth/userEmail'
      })
    },
    data() {
      return {
        subscribeForAutoRenewal: false,
        termsAccepted: false,
        selectedType: this.value?.type,
        paymentUrl: null,
        renewalButtonAvailable: true,
        giftPicked: null,
        sberbankError: null
      }
    },
    methods: {
      onSubmit() {
        this.v$.$touch()
        if (this.v$.$invalid) {
          return
        }
        if (!this.renewalButtonAvailable) {
          return
        }
        this.sberbankError = null
        this.renewalButtonAvailable = false
        if (this.loyaltyPageInfo.gift.types.includes(this.selectedType) && this.subscribeForAutoRenewal) {
          this.$emit('gift')
        }
        membershipApi.performRenewal(this.value.annualPassGuid, this.selectedType, 1, this.subscribeForAutoRenewal,  this.email, this.$i18n.locale)
                     .then((resp) => {
                       this.paymentUrl = resp.data.redirectUrl
                       this.$nextTick(() => {
                         this.$refs['renewal-payment'].show()
                       })
                       //TODO: добавить каким то образом реакцию на создание нового абонемента (выполняется асинхронно) - через очередь например
                     })
                     .catch((e) => {
                       if (e.response && typeof e.response.data === 'object' && 'sberbankErrorCode' in e.response.data) {
                         this.sberbankError = {
                           statusCode: e.response.data.sberbankStatusCode,
                           errorCode: e.response.data.sberbankErrorCode,
                           errorMessage: e.response.data.sberbankErrorMessage
                         }
                       }
                     })
                     .finally(() => {
                       this.renewalButtonAvailable = true
                     })
      },
      ...mapActions({
        sendGiftInfo: 'loyalty/sendGiftInfo'
      })
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  iframe.renewal-iframe {
    border: 0;
    width: 100%;
    height: 80vh;
  }

  .renewal-form {
    .custom-select,
    .form-control {
      font-weight: #{$font-weight-medium};
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: #{$white};
    }
    .form-group {
      margin-bottom: 20px;
    }
    .custom-control {
      margin-bottom: 8px;
    }
    .form-group + .price-group {
      margin-top: -8px;
    }
    .price-group {
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 24px;
      .price-description {
        font-size: 16px;
      }
      del {
        color: #{$secondary};
      }
    }

    button[type='submit'] {
      margin-top: 30px;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, md)}) {
    .renewal-form {
      .form-group {
        margin-bottom: 26px;
      }
      .form-group + .price-group {
        margin-top: -6px;
      }
      button[type='submit'] {
        margin-top: 40px;
      }
      .price-group,
      .custom-control {
        margin-bottom: 20px;
      }
      .custom-control-input, .custom-control-label::before, .custom-control-input:checked ~ .custom-control-label::before, .custom-control-label::after, .custom-control-input:checked ~ .custom-control-label::after {
        top: 0.125rem;
      }
      .custom-control-label {
        line-height: 24px;
      }
      margin-bottom: 0;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .renewal-form {
      margin-bottom: 0;
    }
  }
</style>
