<template>
  <b-container class="message-list-page">
    <div class="d-flex flex-column justify-content-center">
      <back-link to="/" :nav-text="$t('Common.back')" />
      <page-title :title="$t('Messages.header')" :badge="$store.getters['auth/unreadMessageCount']"></page-title>
      <MessageList/>
    </div>
  </b-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle'
import MessageList from '@/components/MessageList'
import BackLink from '@/components/common/BackLink'

export default {
  name: 'MessageListPage',
  components: {MessageList, PageTitle, BackLink}
}
</script>
<style lang="scss">
@import "@/assets/styles/_variables.scss";
.message-list-page {
  .back-link {
    display: block;
    margin-top: 26px;
  }

  .title-container {
    padding-top: 68px;
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {

    .title-container {
      padding-top: 26px;
    }

    .back-link {
      margin-top: 28px;
    }
  }
}
</style>
