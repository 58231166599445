<template>
  <div class="fixed-top sticky-top">
    <div class="shadow-div"/>
    <Banner/>
  <b-navbar toggleable="md" class="header header-underline ">
    <div class="d-flex justify-content-between container nav-container">
      <div class="nav-logo">
        <router-link to="/"><img class="logo" :src="require(`../assets/images/erarta-logo-${this.$i18n.locale}.svg`)" alt="logo"></router-link>
      </div>

      <div class="nav-menu">
        <LocaleSwitcher class="locale-switcher"/>

        <!-- Меню десктопной версии -->
        <b-nav-item-dropdown ref="menu-dropdown" @shown="onMenuShown" @hidden="onMenuHidden" right toggle-class="px-0" menu-class="menu" class="nav-menu-dropdown">
          <template #button-content>
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </template>
          <b-dropdown-item to="/">{{$t('Common.header_menu_erarta')}}</b-dropdown-item>
          <template v-if="loggedIn && isNormalUser">
            <b-dropdown-item to="/loyalty">{{$t('Common.header_menu_tickets')}}</b-dropdown-item>
            <b-dropdown-item to="/orders">{{$t('Common.header_menu_orders')}}</b-dropdown-item>
            <b-dropdown-item to="/messages">
              {{$t('Common.header_menu_messages')}} <b-badge class="message-badge">{{unreadMessageCount}}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item to="/emailAndPassword">{{$t('Common.header_menu_emailAndPassword')}}</b-dropdown-item>
            <b-dropdown-item class="logout-item" @click="logout">{{$t('Common.header_menu_exit')}}</b-dropdown-item>
            <b-dropdown-divider class=""/>
          </template>
          <template v-else>
            <b-dropdown-item disabled>{{$t('Common.header_menu_tickets')}}</b-dropdown-item>
            <b-dropdown-item disabled>{{$t('Common.header_menu_orders')}}</b-dropdown-item>
            <b-dropdown-item disabled>
              {{$t('Common.header_menu_messages')}}
            </b-dropdown-item>
            <b-dropdown-item disabled>{{$t('Common.header_menu_emailAndPassword')}}</b-dropdown-item>
            <b-dropdown-item v-if="!loggedIn" to="/login">{{$t('Authentication.login_signIn')}}</b-dropdown-item>
            <b-dropdown-item v-else class="logout-item" @click="logout">{{$t('Common.header_menu_exit')}}</b-dropdown-item>
            <b-dropdown-divider class=""/>
          </template>
          <b-dropdown-item link-class="external" target="_blank" :href="$t('Common.header_menu_museum_url')">
            {{$t('Common.header_menu_museum')}}
          </b-dropdown-item>
          <b-dropdown-item link-class="external" target="_blank" :href="$t('Common.header_menu_shop_url')">
            {{$t('Common.header_menu_shop')}}
          </b-dropdown-item>
          <b-dropdown-item link-class="external" target="_blank" :href="$t('Common.header_menu_restaurant_url')">
            {{$t('Common.header_menu_restaurant')}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
    </div>
  </b-navbar>
  </div>
</template>

<script>
  import LocaleSwitcher from './LocaleSwitcher'
  import {mapActions, mapGetters} from 'vuex'
  import Banner from '@/components/Banner'

  export default {
    name: 'ErartaHeader',
    components: {LocaleSwitcher, Banner},
    data() {
      return {
        menuOpened: false
      }
    },
    computed: {
      loggedIn() {
        return Boolean(this.$store.getters['auth/authData'].access_token)
      },
      unreadMessageCount() {
        return this.$store.getters['auth/unreadMessageCount']
      },
      ...mapGetters({
        isNormalUser: 'auth/userIsNormal'
      })
    },
    beforeDestroy() {
      this.onMenuHidden()
    },
    methods: {
      logout() {
        this.requestLogout()
          .then(() => document.location.reload())
      },
      onMenuShown() {
        let breakPointProp = getComputedStyle(this.$refs['menu-dropdown'].$el)
          .getPropertyValue('--breakpoint-md')
        if (!window.matchMedia(`(min-width: ${breakPointProp})`)?.matches) {
          this.$refs['menu-dropdown']
              .$set(this.$refs['menu-dropdown'], 'listenForClickOut', false)
          // также добавить запрет на скролл
          if (!document.body.classList.contains('menu-shown')) {
            document.body.classList.add('menu-shown')
          }
          if (!document.documentElement.classList.contains('menu-shown')) {
            document.documentElement.classList.add('menu-shown')
          }
        }
        this.menuOpened = true
      },
      onMenuHidden() {
        document.body.classList.remove('menu-shown')
        document.documentElement.classList.remove('menu-shown')
        this.menuOpened = false
      },
      ...mapActions({
        requestLogout: 'auth/logout'
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .logo {
    width: auto;
    height: 64px;
  }

  .toggle-icon {
    width: 24px;
    height: 14px;
    margin-bottom: 2px;
  }

  .nav-toggle {
    border: none;
  }

  nav li {
    list-style: none;
  }

  .locale-switcher {
    font-size: 24px;
    margin-right: 41px;
  }

  .header {
    min-height: 100px;
    border-bottom: solid 1px var(--erarta-light-gray);
  }

  .menu-icon {
    height: 26px;
    width: 46px;
  }

  .dropdown-wrapper {
    width: fit-content;
  }

  .nav-menu {
    display: flex;
    align-items: center;
  }

  .message-badge {
    background-color: #{$dark};
    border-radius: 2px;
    font-size: 18px;
    line-height: 21px;
    font-weight: #{$font-weight-medium};
    padding: 0 2px;
    min-width: 21px;
    min-height: 21px;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .nav-logo {
      padding: 0 0 0 20px;
    }

    .logo {
      width: auto;
      height: 42px;
    }

    .locale-switcher {
      font-size: 17px;
      margin-right: 20px;
    }

    .header {
      min-height: 60px;
    }

    .navbar {
      padding: 9px 0 !important;
    }

    .message-badge {
      line-height: 21px;
      padding: 0 2px;
      vertical-align: 2.5px;
    }
  }

</style>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .navbar {
    padding: 0 !important;
  }

  .dropdown-toggle::after {
    content: none !important;
  }

  .dropdown-toggle:focus-visible {
    outline: none !important;
  }

  .menu {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0 1px 15px #e0dede !important;
  }

  .dropdown-item.active, .dropdown-item:active,
  .dropdown-item:hover, .dropdown-item:focus{
    background-color: transparent !important;
    color: #{$primary} !important;
  }
  @media(hover: hover) and (not (pointer: coarse)) and (min-width: #{map-get($grid-breakpoints, md)}) {
    .dropdown-item:hover {
      background-color: transparent !important;
      color: #{$primary} !important;
    }
  }
  /* Свернутое меню */
  a.nav-link[aria-expanded='false'] #header-menu-close {
    display: none;
  }

  a.nav-link[aria-expanded='false'] #header-menu-open {
    display: initial;
  }

  /* Развернутое меню */
  a.nav-link[aria-expanded='true'] #header-menu-close {
    display: initial;
  }

  a.nav-link[aria-expanded='true'] #header-menu-open {
    display: none;
  }

  a.nav-link {
    padding: 5px;
  }

  ul.dropdown-menu {
    padding: 21px 40px 30px 40px;
    margin-top: 33px;
    .dropdown-item {
      line-height: 20px;
      padding: 10px 0 10px 0;
      white-space: pre-line;
    }
    .logout-item {
      margin-top: 9px;
    }
    .dropdown-divider {
      margin-top: 6px;
      margin-bottom: 5px;
    }
  }
  .shadow-div {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background-color: rgba(0, 0, 0, .7);
    display: none;
  }


  @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
    ul.dropdown-menu {
      width: 285px;
    }
  }
  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {

    ul.dropdown-menu {
      margin-top: 0;
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 61px;
      background: #{$white};
      z-index: 9999;
      transition: none;
      padding: 7px 20px 0 20px;
      .dropdown-item {
        font-weight: #{$font-weight-medium};
        font-size: 25px;

        white-space: normal;
        line-height: 26px;
        padding: 7px 0 7px 0;
      }

      .logout-item {
        margin-top: 21px;
      }

      .dropdown-divider {
        margin-top: 10px;
        margin-bottom: 9px;
      }
    }

  }

  .erarta-mobile-dropdown-item {
    padding: 2px 0;
  }
</style>
