<template>
  <div v-if="orders">
    <template v-for="order in orders">
      <order-view v-bind:key="order.id" :order="order" :collapse_id="'collapse-' + order.id" />
    </template>
    <template v-if="orders.length === 0">
      <div class="no-orders">
        У вас пока нет заказов
      </div>
    </template>
  </div>
</template>

<script>
import OrderView from '@/components/order/OrderView'
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted() {
    this.loadOrderList()
  },
  components: {
    'order-view': OrderView
  },
  computed: {
    ...mapGetters({orders: 'order/orders'})
  },
  methods: {
    ...mapActions({loadOrderList: 'order/loadOrderList' }),
  }
}
</script>

<style scoped>

</style>
