<template>
  <b-modal :ref="`modal`" size="lg" body-class="p-0" :hide-footer="true">
    <div class="exhibitions__image-wrap">
      <div v-if="exhibition.icons.length > 0" class="icons">
        <span v-for="icon in exhibition.icons" :key="icon">
          <img :src="require(`../../assets/images/building_plan/static/${icon}_modal.svg`)" :alt="icon"/>
        </span>
      </div>
      <div v-if="!!exhibition.floor" class="floor">
        {{exhibition.floor}}
      </div>
      <img class="exhibitions__image" v-if="!!exhibition.image" :src="exhibition.image"/>
    </div>
    <div class="bg-gray exhibitions__modal-body">
      <h3 class="exhibitions__title">{{exhibition.title}}</h3>
      <div class="exhibitions__item-date">
        {{ reformatDate(exhibition.dateStart, "DD.MM.YYYY", 'DD MMMM') }}  — {{ reformatDate(exhibition.dateEnd, "DD.MM.YYYY", 'DD MMMM YYYY') }}
      </div>
      <div class="exhibitions__item-age" v-if="!!exhibition.ageLimit">
        {{exhibition.ageLimit}}
      </div>
      <div class="exhibitions__item-days-left" v-if="daysLeft < exhibitionDaysLeft">
        {{
          getNoun(daysLeft,
            $t('BuildingPlan.days_left_one', [daysLeft]),
            $t('BuildingPlan.days_left_two', [daysLeft]),
            $t('BuildingPlan.days_left_five', [daysLeft]))
        }}
      </div>
    </div>
    <div v-if="!!exhibition.link" class="link" >
      <a :href="exhibition.link" class="external" target="_blank">{{$t('BuildingPlan.museum_link')}}</a>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    exhibition: {
      type: Object,
      default: null,
      required: true
    },
    exhibitionDaysLeft: {
    }
  },
  computed: {
    daysLeft() {
      return moment(this.exhibition.dateEnd, 'DD.MM.YYYY').diff(moment(), 'days') + 1
    }
  },
  methods: {
    getNoun(number, one, two, five) {
      let n = Math.abs(number)
      n %= 100
      if (n >= 5 && n <= 20) {
        return five
      }
      n %= 10
      if (n === 1) {
        return one
      }
      if (n >= 2 && n <= 4) {
        return two
      }
      return five
    },
    reformatDate(value, initialFormat, targetFormat) {
      return moment(value, initialFormat).format(targetFormat)
    }
  }
}
</script>

<style scoped>
  .exhibitions__image-wrap {
    position: relative;
  }
  .bg-gray {
    background-color: #F5F5F6;
  }
  h3.exhibitions__title {
    font-size: 19px;
    margin: 0 !important;
  }
  .exhibitions__modal-body {
    padding: 15px;
  }
  .icons {
    position: absolute;
    padding: 16px;
    z-index: 1;
  }
  .icons span {
    margin-right: 5px;
  }
  .icons img {
    width: 46px
  }
  .floor {
    position: absolute;
    color: #fff;
    font-size: 19px;
    font-weight: 500;
    bottom: 0;
    padding-left: 16px;
    padding-bottom: 12px;
  }
  .exhibitions__image {
    max-width: 100%;
    width: 100%;
    max-height: 274px;
    object-fit: cover;
  }
  .exhibitions__item-days-left {
    margin-top: 27px;
    color: var(--erarta-red);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .2px;
  }
  .link {
    margin-top: 27px;
    border-top: 2px solid #CCCED0;
    padding-top: 20px;
  }
  .link a {
    justify-content: center;
  }
  .link .external {
    font-size: 18px;
  }
  .exhibitions__item-date, .exhibitions__item-age {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: .2px;
    color: #49494a;
    margin-top: 10px;
  }

  @media (max-width: 1023px) {
    .exhibitions__modal-body {
      padding: 12px;
    }
    .exhibitions__item-date, .exhibitions__item-age {
      margin-top: 8px;
    }
    .link {
      margin-bottom: 25px;
      border-top: 1px solid #CCCED0;
    }
    .icons {
      padding: 12px;
    }
    .icons img {
      width: 40px
    }
    .floor, h3.exhibitions__title {
      font-size: 17px;
    }
    .link .external {
      font-size: 16px;
    }
    .exhibitions__image {
      max-height: 210px;
    }
  }

</style>

<style>
.modal-header{
  .height: 40px;
}
@media (max-width: 1023px) {
  .modal-header {
    height: unset;
    min-height: 68px;
  }
}
</style>
