<template>
  <div>
    <span v-if="tutorial" class="close-tutorial">&times;</span>
    <b-modal :header-class="votedForId ? 'without-close': ''" :id="'vote-modal-'+ roundIndex" @close="closeVoteModal" @hide="closeVoteModal" body-class="vote-confirm-modal p-0" hide-footer>
      <img class="award-logo" v-if="icon" :src="icon">
      <div class="modal-info-content" v-if="!votedForId">
        <span v-if="selectedAnnualPass" class="annual-pass-desc">{{
                                                                 selectedAnnualPass.owner
                                                                 }} (#{{ selectedAnnualPass.number }})</span>
        <span class="mb-3">{{ $t('Vote.modal-only-one') }}</span>
        <span>{{ $t('Vote.modal-your-choice') }}</span>
        <span v-if="selectedObject" class="vote-selected-info mb-3">
          {{ `${selectedObject.title}, ${selectedObject.author}, ${$t('Common.sequential_number')} ${selectedObject.identifier}` }}
        </span>
        <span class="vote-warning">{{ $t('Vote.modal-warn') }}</span>
        <b-alert :show="error != null" @dismissed="error = null" variant="danger">{{ error }}</b-alert>
        <b-button :disabled="isProcessing" variant="primary" @click="onVoteClick()">
          {{ $t('Vote.modal-vote') }}
        </b-button>
      </div>
      <div class="modal-info-content" v-else>
        <span class="modal-title v-vote-success">{{ $t('Vote.modal-vote-success') }}</span>
        <b-button variant="primary" @click="closeVoteModal()">
          {{ $t('Vote.modal-close') }}
        </b-button>
      </div>
    </b-modal>
    <div class="container vote-filter" ref="vote-filter">
      <label class="vote-filter-label" :class="{'dim':tutorial}">
        <b-form-input type="number" :placeholder="$t('Vote.search')" v-model="searchText" @input="search()" min="1"
          :disabled="annualPasses.length < 1 && !privilegedMode"></b-form-input>
      </label>
      <b-select v-if="!privilegedMode" class="vote-annual-pass-selector" :value="selectedAnnualPass" @change="val => $emit('annual-pass-changed', val)" :disabled="annualPasses.length < 1">
        <template #first>
          <b-form-select-option :value="null" disabled>{{ $t('Vote.ticket') }}</b-form-select-option>
        </template>
        <b-form-select-option v-for="annualPass in annualPasses" :key="annualPass.id" :value="annualPass">
          <template v-if="annualPass.annualPassGuid">
            {{ $t('Vote.annual-pass-option', [annualPass.number, annualPass.owner]) }}
          </template>
          <template v-else>
            {{ $t('Vote.one-time-pass-option', [annualPass.number]) }}
          </template>
        </b-form-select-option>
      </b-select>
      <template v-else><span>{{$t('Vote.current-pass', [selectedAnnualPass.number, selectedAnnualPass.owner])}}</span> <b-button @click="$emit('annual-pass-number-reset')" >{{$t('Vote.reset-pass')}}</b-button></template>
    </div>
    <div class="object-card pb-5">
      <div v-if="dataLoaded" class="container object-grid" ref="object-grid">
        <masonry v-if="objects.length > 0"
                 :cols="{default: 3, 1024: 1}"
                 :gutter="45">
          <div v-for="(object, index) in objects" class="object" :key="object.identifier" :id="`obj_${roundIndex + 1}_${object.identifier}`">
            <div class="vote-score"
              :class="{'finish-result': roundFinished, 'round-lost': lastRound ? (index >= thresholdIndex): !object.passedToNextTour}"
            >
              <template v-if="roundFinished">
                <span v-if="lastRound ? (index === 0) : object.passedToNextTour">{{$t(`Vote.results-won[${roundIndex}]`)}}</span>
                <span v-else-if="lastRound && index > 0 && index < thresholdIndex">{{$t(`Vote.results-round-lost[${roundIndex}]`, [ prizeFormatter.format(calculatePrizeMoney(index)) ])}}</span>
                <span v-else>{{$t(`Vote.results-round-lost[${roundIndex}]`, [ prizeFormatter.format(prize) ])}}</span>
              </template>
              <span v-if="object.fraction || object.fraction === 0">{{ (object.fraction * 100).toFixed(2) + '%' }}</span>
            </div>
            <router-link v-if="!privilegedMode" :to="{ path: `/vote/${processId}/${object.identifier}`, query: { round: roundIndex + 1, annualPassNumber: selectedAnnualPass ? selectedAnnualPass.number : null }}" ><img :src="object.image"/></router-link>
            <img v-else :src="object.image"/>

            <router-link v-if="!privilegedMode" :to="{ path: `/vote/${processId}/${object.identifier}`, query: { round: roundIndex + 1, annualPassNumber: selectedAnnualPass ? selectedAnnualPass.number : null }}" >
              <span class="object-title">{{ object.title }}</span>
            </router-link>
            <span class="object-title" v-else>{{ object.title }}</span>

            <span class="object-author">{{ object.author }}</span>
            <span class="object-identifier">{{ `${$t('Common.sequential_number')} ${object.identifier}` }}</span>

            <div class="vote-already-voted" v-if="votedForId && votedForId === object.identifier">
              <div class="voted-for">
                <img class="success-icon" src="../../assets/icons/success.svg">
                <span class="success-text">{{ $t('Vote.voted') }}</span>
              </div>
              <b-button variant="outline-secondary" class="v-c-voted vote-button-success" @click="goToWhatsNextPage">
                {{ $t('Vote.whats-next') }}
              </b-button>
            </div>
            <b-button
              v-else-if="isRoundActual && !votedForId && selectedAnnualPass"
              class="v-c-vote vote-button"
              variant="outline-primary"
              v-b-modal="'vote-modal-'+ roundIndex"
              @click="selectedObject = object">
              {{ $t('Vote.vote') }}
            </b-button>
          </div>
        </masonry>
        <span v-else class="no-vote-objects">{{ $t('Vote.nothing-found') }}</span>
      </div>
      <div v-else class="d-flex justify-content-center pt-5">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import VueMasonry from 'vue-masonry-css'
import Vue from 'vue'
import voteApi from '@/modules/vote'
import store from '@/store'
import moment from 'moment'
import voteUtil from '@/modules/voteCalculation.js'

Vue.use(VueMasonry)
export default {
  name: 'VoteRound',
  props: {
    inputObjects: {
      type: Array
    },
    roundIndex: {
      type: Number
    },
    lastRound: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    processId: null,
    annualPasses: {
      type: Array
    },
    selectedAnnualPass: {
      type: Object,
    },
    privilegedMode: {
      type: Boolean,
      default: false
    },
    prize: {},
    round: {
      type: Object
    },
    availableObjects: {
      type: Array,
      default: () => []
    },
    currentResults: {
      type: Object,
    }
  },
  data() {
    return {
      searchText: '',
      selectedObject: null,
      objects: null,
      votedForId: null,
      tutorial: false,
      isProcessing: false,
      prizeFormatter:  new Intl.NumberFormat('ru', {
        maximumFractionDigits: 0
      }),
      error: null,
    }
  },
  created() {
  },
  mounted() {
    if (this.selectedAnnualPass?.annualPassGuid) {
      this.checkVote(this.selectedAnnualPass.annualPassGuid)
    } else if (this.selectedAnnualPass?.number) {
      this.checkVoteForOneTimePass(this.selectedAnnualPass.number)
    }
    document.addEventListener('click', this.stopTutorial)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.stopTutorial)
  },
  methods: {
    search() {
      if (!this.searchText) {
        this.objects = this.actualObjects.slice()
      } else {
        this.objects = []
        let searchData = parseInt(this.searchText)
        this.actualObjects.forEach(item => {
          if (parseInt(item.identifier) === searchData) {
            this.objects.push(item)
          }
        })
      }
      this.sortObjects()
    },
    sortObjects() {
      this.objects = this.objects.sort(function (a, b) {
        if (a.fraction > b.fraction) {
          return -1
        }
        if (a.fraction < b.fraction) {
          return 1
        }
        return 0
      })
    },
    closeVoteModal() {
      this.error = null
      this.$root.$emit('bv::hide::modal', 'vote-modal-' + this.roundIndex)
      if (this.votedForId && this.roundIndex === 0) {
        this.$refs['object-grid'].scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    },
    onVoteClick() {
      if (this.isProcessing) {
        return
      }
      this.searchText = ''
      this.isProcessing = true
      this.error = null

      if (this.privilegedMode) {
        voteApi.performVoteWithNumber(this.processId, this.selectedObject.identifier, this.roundIndex, this.selectedAnnualPass.number)
               .then(() => {
                 this.votedForId = this.selectedObject.identifier
                 setTimeout(() => this.$emit('voted'), 5000)
               })
               .catch((error) => {
                 if (typeof error.response?.data === 'string') {
                   this.error = error.response?.data
                 } else {
                   this.error = error.response?.data ?? this.$t('Vote.modal-vote-error')
                 }
               })
               .finally(() => this.isProcessing = false)
      } else {
        let votePromise = this.selectedAnnualPass.annualPassGuid
                        ? voteApi.performVote(this.processId, this.selectedObject.identifier, this.roundIndex, this.selectedAnnualPass.annualPassGuid)
                        : voteApi.performVoteWithOneTimePassNumber(this.processId, this.selectedObject.identifier, this.roundIndex, this.selectedAnnualPass.number)

        votePromise.then(() => {
          this.votedForId = this.selectedObject.identifier
          store.dispatch('auth/checkUnreadMessageCount')
          setTimeout(() => this.$emit('voted'), 5000)
        }).catch((error) => {
          if (typeof error.response?.data === 'string') {
            this.error = error.response?.data
          } else {
            this.error = error.response?.data ?? this.$t('Vote.modal-vote-error')
          }
        }).finally(() => this.isProcessing = false)
      }
    },
    checkVote(annualPassGuid) {
      voteApi.getVote(this.processId, annualPassGuid, this.roundIndex)
        .then(response => {
          this.votedForId = response.data?.objectCode
          this.showTutotial()
        })
        .catch(error => this.error = error.response?.data)
    },
    checkVoteForOneTimePass(number) {
      voteApi.getVoteWithOneTimePassNumber(this.processId, number, this.roundIndex)
        .then(response => {
          this.votedForId = response.data?.objectCode
          this.showTutotial()
        })
        .catch(error => this.error = error.response?.data)
    },
    showTutorial() {
      if (!this.votedForId &&
          this.annualPasses?.length > 0 &&
          this.isRoundActual && this.$cookies.get('tutorialCompleted') !== 'true' && this.roundIndex === 0) {
        setTimeout(() => {
          if (this.$refs['vote-filter'] !== undefined) {
            this.tutorial = true
            document.getElementsByClassName('shadow-div')[0].style.display = 'block'
            this.$refs['vote-filter'].scrollIntoView({behavior: 'smooth', block: 'center'})
            this.$cookies.set('tutorialCompleted', true, moment(this.round.endDate).toDate())
          }
        }, 2000)
      }
    },
    stopTutorial() {
      if (this.tutorial) {
        document.getElementsByClassName('shadow-div')[0].style.display = 'none'
        this.tutorial = false
      }
    },
    findSelectedObject(objectCode) {
      return this.objects.find(obj => obj.identifier === objectCode)
    },
    goToWhatsNextPage() {
      window.open(this.$t('Vote.whats-next-link'))
    },
    calculatePrizeMoney(place) {
      return voteUtil.calculatePrizeMoney(place)
    }
  },
  computed: {
    dataLoaded() {
      return this.objects != null && this.annualPasses != null
    },
    roundFinished() {
      return moment(this.round.endDate).isBefore(moment())
    },
    isRoundActual() {
      return moment().isBetween(moment(this.round.startDate), moment(this.round.endDate))
    },
    actualObjects() {
      let result = this.inputObjects.filter(item => this.availableObjects?.includes(item.identifier))
        .map(item => Object.assign({}, item))

      result.forEach(object => {
        let res = this.currentResults?.[object.identifier]
        object.fraction = res?.fraction ?? 0
        object.score = res?.voteCount ?? 0
        object.passedToNextTour = res?.passedToNextTour ?? false
      })

      return result
    },
    thresholdIndex() {
      return voteUtil.calculateTourThreshold(this.objects, this.roundIndex)
    }
  },
  watch: {
    selectedAnnualPass: function (newValue) {
      if (newValue?.annualPassGuid) {
        this.checkVote(newValue.annualPassGuid)
      } else if (newValue?.number) {
        this.checkVoteForOneTimePass(newValue.number)
      }
    },
    actualObjects: function (newValue) {
      this.objects = newValue.slice()
      this.sortObjects()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .dim {
    /* For Internet Explorer */
    box-shadow: 0 0 0 10000px rgba(0, 0, 0, .7) !important;

    /* For other browsers */
    box-shadow: 0 0 0 1000vmax rgba(0, 0, 0, .7) !important;
  }

  .close-tutorial {
    position: fixed;
    top: 30px;
    right: 66px;
    z-index: 100000;
    color: white;
    font-size: 60px;
    font-weight: 100;
    line-height: 1;
  }

  .no-vote-objects {
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }

  .vote-filter {
    margin-top: 45px;
    margin-bottom: 60px;
  }

  input {
    border: none;
    background-color: #{$erarta-very-light-gray};
    padding: 10px 50px;
    height: 50px;
    font-size: 18px;
  }

  .vote-filter-label {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    cursor: text;
    z-index: 1;
  }

  .vote-filter-label::before {
    content: "";
    position: absolute;
    inset: 18px;
    width: 14px;
    height: 14px;
    background: url("../../assets/icons/search.svg");
    background-size: cover;
  }

  .vote-filter-label::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 19px;
    bottom: 19px;
    width: 20px;
    height: 12px;
    background-size: cover;
    background-color: #{$dark};
    -webkit-mask-image: url("../../assets/icons/search_go.svg");
    mask-image: url("../../assets/icons/search_go.svg");
  }

  .vote-annual-pass-selector {
    width: 370px;
    height: 50px;
    font-size: 14px;
    border: none;
    position: relative;
    z-index: 1;
    font-weight: 500;
    background: #{$erarta-very-light-gray} url("../../assets/icons/selector-triangle.svg") right 0.75rem center/8px 10px no-repeat;
  }

  .object-card {
    width: 100%;
    background-color: #{$erarta-very-light-gray};
  }

  .object-grid {
    padding-top: 80px;
  }

  .object {
    background-color: #{$white};
    padding: 16px;
    display: block;
    position: relative;
    scroll-margin-top: calc(max(200px, 10vh));
  }

  .object:not(:last-of-type) {
    margin-bottom: 44px;
  }

  .object img {
    width: 100%;
    margin-bottom: 17px;
  }

  .object span {
    display: block;
    margin-bottom: 5px;
  }
  .object-title {
    font-size: 19px;
    font-weight: 500;
  }

  .object-author {
    font-size: 18px;
  }

  .object-identifier {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .vote-score {
    position: absolute;
    top: 0;
    right: 0;
    display: flex !important;
    align-items: center;
    span {
      margin-left: 14px;
      margin-bottom: 0;
    }
    span:last-child {
      width: 60px;
      margin-left: auto;
    }
    &.finish-result {
      width: 100%;

      &.round-lost {
        background-color: #{$erarta-vote-round-lost};
      }
    }
  }

  .vote-already-voted {
    width: 100%;
    color: #{$erarta-vote-success};
    font-weight: 500;
    margin-top: 36px;

    .voted-for {
      padding-left: 25%;
      padding-right: 25%;
      text-align: center;

      img {
        width: 44px;
        height: 46px;
        margin-bottom: 0 !important;
      }

      span {
        font-size: 12px;
        margin-top: 9px;
      }
    }
  }

  .vote-button {
    width: 100%;
    margin-top: 15px;
  }

  .vote-button-success {
    width: 100%;
    margin-top: 31px;
  }

  /* modal end */

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {

    .close-tutorial {
      position: fixed;
      top: 5px;
      right: 17px;
      z-index: 100000;
      color: white;
      font-size: 50px;
      font-weight: 300;
      line-height: 1;
    }

    .vote-button {
      margin-top: 12px;
      font-size: 14px;
    }

    .v-title {
      font-size: 17px;
    }

    .vote-annual-pass-selector {
      height: 40px;
      width: 100%;
    }

    .vote-filter {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .object {
      padding: 12px;
    }

    .object-title {
      font-size: 18px;
    }

    .object-author {
      font-size: 16px;
    }

    .object-identifier {
      font-size: 14px;
    }

    .vote-already-voted {
      margin-top: 22px;

      .voted-for {
        img {
          width: 44px;
          height: 44px;
          margin-bottom: 6px !important;
        }
        span {
          font-size: 12px;
          margin-top: 0;
        }
      }
    }

    .vote-button-success {
      margin-top: 23px;
      height: 38px;
      font-size: 14px;
      vertical-align: center;
      line-height: 1;
    }

    .object-grid {
      padding-top: 43px;
    }

    .object:not(:last-of-type) {
      margin-bottom: 40px;
    }

    .vote-score {
      span:last-child {
        width: 75px;
      }
    }
  }
</style>

<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .without-close .close {
    visibility: hidden;
  }

</style>
