<template>
  <div class="annual-pass" @click="handleHrefClick">
    <div class="annual-pass-info" :class="{'container': wrapContainer}">
      <h4 class="pass-title">{{annualPassTitle}}</h4>
      <div class="qr-icon">
      <img src="../assets/images/qr-zoom-icon.svg"
        @click="$refs.qrModal.show()">
      </div>
      <dl class="annual-pass-desc">
        <dt>{{$t('Loyalty.pass_number')}}</dt>
        <dd>{{annualPass.number}}
          <button @click="onDelete" :disabled="!unbindAvailable" class="unbind-pass opacity-hover font-weight-normal">{{$t('Loyalty.pass_unbind_label')}}</button>
        </dd>
        <dt>{{$t('Loyalty.pass_owner_name')}}</dt>
        <dd>{{annualPass.owner}}</dd>
        <div class="mt-3"/>
        <dt>{{$t('Loyalty.pass_status_label')}}</dt>
        <dd>{{$t('Loyalty.pass_status_' + statusMessage)}}
          <expiration-icon :status-message="statusMessage" />
        </dd>
        <template v-if="!annualPass.blocked">
          <dt>{{$t('Loyalty.pass_expires_at')}}</dt>
          <dd>{{expirationDate}}</dd>
          <dd v-if="annualPassExpired">{{$t('Loyalty.pass_expired')}}</dd>
          <dd v-else-if="annualPass.subscribedForAutoRenewal">{{$t('Loyalty.pass_subscribed_expires_within', [annualPassDaysInterval])}}</dd>
          <dd v-else>{{$t('Loyalty.pass_expires_within', [annualPassDaysInterval])}}</dd>
        </template>
        <div class="mt-3"/>
        <b-alert class="acc-error" variant="danger" v-if="sberbankError != null && sberbankError.errorCode != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_interaction_error',{ errorCode: sberbankError.errorCode,  errorMessage: sberbankError.errorMessage }) }}</b-alert>
        <b-alert class="acc-error" variant="danger" v-else-if="sberbankError != null" :show="sberbankError != null" @dismissed="sberbankError = null">{{ $t('Loyalty.sberbank_http_error', { statusCode: sberbankError.statusCode })}}</b-alert>
        <dt v-if="productAutoRenewalAvailable || annualPass.subscribedForAutoRenewal">{{$t('Loyalty.pass_auto_renewal_label')}}</dt>
        <dd v-if="annualPass.subscribedForAutoRenewal">{{$t('Loyalty.pass_subscription_active')}}
          <template v-if="annualPass.autoRenewalOwnerId === userId">
            &emsp;<button v-if="unsubscriptionAvailable" class="unsubscribe-pass opacity-hover font-weight-normal" @click="unsubscribePassClicked">{{$t('Loyalty.pass_unsubscribe')}}</button>&emsp;
            <button class="change-subscription-payment opacity-hover font-weight-normal" :disabled="!bindingAvailable" @click="changePaymentMethodClicked">{{$t('Loyalty.pass_subscription_changePayment')}}</button>
          </template>
        </dd>
        <dd v-else-if="productAutoRenewalAvailable">{{$t('Loyalty.pass_subscription_inactive')}}
          <template v-if="subscriptionAvailable">
            <button :id="autoRenewalButtonId" class="subscribe-pass opacity-hover font-weight-normal" @click="showSubscriptionConfirmModal">{{$t('Loyalty.pass_subscribe')}}</button>
          </template>
        </dd>
      </dl>
      <div class="qr-panel flex-column align-items-end">
        <qrcode-vue :value="qrData" ref="qr-code" class="qr-code" size="200"></qrcode-vue>
        <p class="d-block text-right">{{$t('Loyalty.pass_qr_label')}}</p>
      </div>
      <div class="subscription-benefits" v-if="!annualPass.blocked && loyaltyPageInfo[this.$root.$i18n.locale]">
        <div v-if="giftContent && ((subscriptionAvailable && !annualPass.subscribedForAutoRenewal) || annualPassExpired)"
          class=" d-table">
          <div class="d-table-cell item-icon v-align-middle"><img src="../assets/icons/gift.svg"></div>
          <div class="tooltip-item-text d-table-cell v-align-middle" v-html="giftContent"/>
        </div>
        <template v-if="annualPassExpired && loyaltyPageInfo[this.$root.$i18n.locale].expiredBannerItems">
          <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }" :key="index"
            v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].expiredBannerItems">
            <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
            <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
          </div>
        </template>
        <template v-if="subscriptionAvailable && !annualPass.subscribedForAutoRenewal && loyaltyPageInfo[this.$root.$i18n.locale].subscriptionBannerItems">
          <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }"  :key="index + 64"
            v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].subscriptionBannerItems">
            <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
            <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
          </div>
        </template>
        <template v-if="specialOfferEnabled && loyaltyPageInfo[this.$root.$i18n.locale].discountBannerItems">
          <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }" :key="index + 128" v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].discountBannerItems">
            <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
            <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
          </div>
        </template>
        <template v-if="typeChangeAvailable && loyaltyPageInfo[this.$root.$i18n.locale].typeChangeBannerItems">
          <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }"  :key="index + 192" v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].typeChangeBannerItems">
            <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
            <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
          </div>
        </template>
        <template v-if="!annualPassExpired && this.annualPass.attributes && this.annualPass.attributes.uspace && loyaltyPageInfo[this.$root.$i18n.locale].uspaceBannerItems">
          <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }"  :key="index + 256" v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].uspaceBannerItems">
            <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
            <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
          </div>
        </template>
      </div>
      <b-modal ref="qrModal" body-class="qr-code-modal" :hide-footer="true">
        <qrcode-vue class="d-flex justify-content-center qr-code" :value="qrData" ref="modal-qr-code"></qrcode-vue>
        <h4 class="d-block mt-3 text-center">{{$t('Loyalty.pass_qr_label')}}</h4>
      </b-modal>
    </div>
    <b-container class="manual-extend" v-if="renewalAvailable">
      <b-button class="collapser px-0" @click="renewalVisible = !renewalVisible" variant="link">
        {{$t('Loyalty.pass_renewalCollapse')}}<b-icon-chevron-up v-if="renewalVisible"/><b-icon-chevron-down v-else/>
      </b-button>
    </b-container>
    <template v-if="renewalAvailable">
      <b-collapse v-if="wrapContainer" v-model="renewalVisible" ref="renewal-collapse" class="renewal-collapse">
        <b-container>
          <div class="prolongation-info-content" v-html="prolongationDescription"></div>
        </b-container>
        <div class="renewal-container">
          <b-container>
            <b-row class="justify-content-center">
              <renewal-form :id="renewalFormId" :value="annualPass" @gift="addGift()" :loyalty-page-info="loyaltyPageInfo[this.$root.$i18n.locale]">
              </renewal-form>
            </b-row>
          </b-container>
        </div>
      </b-collapse>
      <b-collapse v-model="renewalVisible" ref="renewal-collapse" class="mt-2 renewal-collapse" v-else>
        <div class="prolongation-info-content" v-html="loyaltyPageInfo[this.$root.$i18n.locale].prolongationInfo"></div>
        <div class="renewal-container">
          <b-row class="justify-content-center">
            <renewal-form :id="'renewal-form-' + annualPass.number" :value="annualPass" @gift="addGift()" :loyalty-page-info="loyaltyPageInfo[this.$root.$i18n.locale]" class="py-4">
            </renewal-form>
          </b-row>
        </div>
      </b-collapse>
    </template>
    <template v-if="typeChangeAvailable">
      <b-container class="manual-type-change" v-if="typeChangeAvailable">
        <b-button class="collapser px-0" @click="typeChangeVisible = !typeChangeVisible" variant="link">
          {{$t('Loyalty.pass_typeChangeCollapse')}}<b-icon-chevron-up v-if="typeChangeVisible"/><b-icon-chevron-down v-else/>
        </b-button>
      </b-container>
      <b-collapse v-if="wrapContainer" v-model="typeChangeVisible" ref="type-change-collapse" class="renewal-collapse">
        <b-container>
          <div class="prolongation-info-content" v-html="loyaltyPageInfo[this.$root.$i18n.locale].typeChangeInfo"></div>
        </b-container>
        <div class="renewal-container">
          <b-container>
            <b-row class="justify-content-center">
              <type-change-form :id="typeChangeFormId" :value="annualPass" @gift="addGift()" :loyalty-page-info="loyaltyPageInfo[this.$root.$i18n.locale]">
              </type-change-form>
            </b-row>
          </b-container>
        </div>
      </b-collapse>
      <b-collapse v-model="typeChangeVisible" ref="type-change-collapse" class="mt-2 renewal-collapse" v-else>
        <div class="prolongation-info-content" v-html="loyaltyPageInfo[this.$root.$i18n.locale].typeChangeInfo"></div>
        <div class="renewal-container">
          <b-row class="justify-content-center">
            <type-change-form :id="'type-change-form' + annualPass.number" :value="annualPass" @gift="addGift()" :loyalty-page-info="loyaltyPageInfo[this.$root.$i18n.locale]">
            </type-change-form>
          </b-row>
        </div>
      </b-collapse>
    </template>
    <b-modal v-if="verificationFormUrl"
      ref="verificationForm"
      body-class="p-0"
      dialog-class="verification-modal"
      :hide-footer="true"
      size="lg"
    >
      <iframe class="renewal-iframe" :src="verificationFormUrl" :style="{ height: verificationFormHeight ? verificationFormHeight + 'px' : null }" ref="verificationFormIframe"/>
    </b-modal>
    <b-modal ref="subscription-success-modal"
      :hide-footer="true"
      size="lg"
      body-class="d-flex flex-column justify-content-center"
    >
      <p class="text-center my-4">{{$t('Loyalty.pass_subscription_subscribeSuccess')}}</p>
      <b-button variant="primary" @click="$refs['subscription-success-modal'].hide()">
        {{$t('Loyalty.pass_subscription_modalClose')}}
      </b-button>
    </b-modal>
    <b-modal ref="subscription-success-modal-gift"
      :hide-footer="true"
      size="lg"
      body-class="d-flex flex-column justify-content-center"
    >
      <b-form class="modal-subscription-form"  @submit.prevent="giftChoose">
        <div class="modal-subscription-div">
          <p class="modal-subscription-title my-4">{{ $t('Loyalty.pass_subscription_subscribeSuccess') }}</p>
          <p class="modal-subscription-subtitle my-4">{{ $t('Loyalty.pass_subscription_choose_gift') }}</p>
          <b-alert class="error" variant="danger" :show="error != null" @dismissed="!error">{{ error }}</b-alert>
          <div v-for="(gift, index) in loyaltyPageInfo[this.$root.$i18n.locale].gift.gifts" :key="gift.title">
            <div class="modal-subscription-gift my-3 ">
              <input type="radio" class="radio-input mr-3" :id="'radio' + gift.title" :value="index"
                     v-model="giftPicked">
              <label :for="'radio' + gift.title" class="modal-subscription-gift-title">{{ gift.title }}</label>
            </div>
          </div>
          <p class="small-text my-4">{{ $t('Loyalty.pass_subscription_message') }}</p>
          <b-button type="submit" class="modal-subscription-button" variant="primary">
            {{ $t('Loyalty.pass_subscription_modalClose') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal ref="subscription-confirm-modal"
      :hide-footer="true"
      body-class="d-flex flex-column justify-content-center subscription-confirm-modal"
      size="lg">
      <b-form class="modal-subscription-form" @submit.prevent="subscribePassClicked">
        <div class="modal-subscription-div">
          <p class="modal-subscription-title">{{ $t('Loyalty.pass_subscribe_label') }}</p>
          <p class="modal-subscription-confirm-subtitle">{{ $t('Loyalty.pass_subscribe_description') }}
            <a class="modal-a" :href="$t('Loyalty.passUsage_rules_link')" target="_blank">{{ $t('Loyalty.pass_subscribe_description_link') }}</a>
          </p>
          <b-button @click="closeProlongationModal" class="modal-subscription-confirm-button modal-subscription-confirm-button-no" variant="light">
            {{ $t('Loyalty.pass_subscribe_cancel') }}
          </b-button>
          <b-button type="submit" :disabled="!bindingAvailable" class="modal-subscription-confirm-button modal-subscription-confirm-button-yes" variant="primary">
            {{ $t('Loyalty.pass_subscribe_confirm') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
  import moment from 'moment'
  import QrcodeVue from 'qrcode.vue'
  import AnnualPassRenewalForm from '@/components/AnnualPassRenewalForm'
  import AnnualPassTypeChangeForm from '@/components/AnnualPassTypeChangeForm'
  import {mapActions, mapGetters, mapState} from 'vuex'
  import { fetchAnnualPass } from '@/modules/content-loader'
  import store from '@/store'
  import {BIconChevronUp, BIconChevronDown} from 'bootstrap-vue'
  import ExpirationIcon from './ExpirationIcon'


  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style
  export default {
    components: {
      'qrcode-vue': QrcodeVue,
      'renewal-form': AnnualPassRenewalForm,
      'type-change-form': AnnualPassTypeChangeForm,
      BIconChevronUp, BIconChevronDown, ExpirationIcon
    },
    computed: {
      qrData() {
        return '778=206640001=' + this.annualPass.number
      },
      autoRenewalButtonId() {
        return 'renewal-subscribe-' + this.annualPass.number
      },
      renewalFormId() {
        return 'renewal-form-' + this.annualPass.number
      },
      typeChangeFormId() {
        return 'type-change-form-' + this.annualPass.number
      },
      productAutoRenewalAvailable() {
        return this.suitableAnnualPassProduct?.map(apt => apt.renewalAvailable)[0]
      },
      subscriptionAvailable() {
        return !this.annualPassExpired && !this.annualPass.blocked &&
               (this.annualPass.autoRenewalOwnerId === this.userId || !this.annualPass.autoRenewalOwnerId) &&
               // разрешить продлять стариканам
               (this.productAutoRenewalAvailable || this.annualPass.retiree)
      },
      unsubscriptionAvailable() {
        if (!this.annualPass.autoRenewalUnsubscriptionAvailable) {
          return true
        }
        return moment().isAfter(moment(this.annualPass.autoRenewalUnsubscriptionAvailable))
      },
      specialOfferEnabled() {
        return this.annualPass?.specialPrice != null &&
               !moment(this.annualPass.specialPrice.expires).isBefore(moment()) &&
               this.annualPass.specialPrice.renewalPrice != null
      },
      statusMessage() {
        let expMoment = moment(this.annualPass.expires)
        let nowMoment = moment()
        if (this.annualPass.blocked) {
          return 'blocked'
        } else if (this.annualPass.active && !expMoment.isBefore(nowMoment)) {
          if (expMoment.isBefore(nowMoment.add(30, 'day'))) {
            return 'expires'
          }
          return 'active'
        } else {
          return 'inactive'
        }
      },
      prolongationDescription() {
        let contentDescription = this.suitableAnnualPassContent?.[this.$root.$i18n.locale]?.prolongationDescription
        if (contentDescription != null && contentDescription !== '') {
          return contentDescription
        }
        return this.loyaltyPageInfo[this.$root.$i18n.locale].prolongationInfo
      },
      annualPassExpired() {
        return moment(this.annualPass.expires).isBefore(moment())
      },
      annualPassDaysInterval() {
        return moment(this.annualPass.expires).diff(moment(), 'days')
      },
      suitableAnnualPassProduct() {
        return this.annualPassTypes?.filter(apt => apt.type === this.annualPass.type && apt.duration == this.annualPass.duration)
      },
      annualPassTitle() {
        if (this.annualPassTypes == null) {
          return null
        }
        let titleField = this.$i18n.locale === 'ru' ? 'titleRu' : 'titleEn'
        return this.suitableAnnualPassProduct.map(apt => apt[titleField])[0]
      },
      renewalAvailable() {
        if (this.annualPassTypes == null) {
          return null
        }
        if (this.annualPass.blocked || (this.annualPass.subscribedForAutoRenewal && !this.annualPassExpired)) {
          return false
        }
        let suitableProduct = this.suitableAnnualPassProduct[0]
        // разрешить продлять стариканам
        if (suitableProduct == null) {
          return false
        }
        return suitableProduct.renewalAvailable || (suitableProduct.renewalWithTypeChangeAvailable  && this.annualPassExpired) || this.annualPass.retiree
      },
      productsForTypeChange() {
        return this.annualPassTypes?.filter(apt => apt.typeChangePrice != null)?.filter(apt => apt.type !== this.annualPass?.type)
      },
      typeChangeAvailable() {
        if (this.productsForTypeChange == null) {
          return null
        }
        if (this.annualPass.blocked || this.annualPassExpired) {
          return false
        }
        let suitableProduct = this.suitableAnnualPassProduct[0]
        if (suitableProduct == null || !suitableProduct.renewalWithTypeChangeAvailable) {
          return false
        }
        return this.productsForTypeChange?.length > 0
      },
      expirationDate() {
        if (this.annualPass.expires == null) {
          return ''
        }
        return moment(this.annualPass.expires).format('DD.MM.YYYY')
      },
      giftContent() {
        if (this.loyaltyPageInfo[this.$root.$i18n.locale]?.gift?.types?.includes(this.annualPass.type)) {
          return this.loyaltyPageInfo[this.$root.$i18n.locale]?.gift?.info
        } else {
          return null
        }
      },
      ...mapState({
        annualPassTypes: state => state.loyalty.annualPassTypes
      }),
      ...mapGetters({ userId: 'auth/userId' })
    },
    mounted() {
      this.loadAnnualPassTypeList()
          .then(() => {
            this.handleHashChange()
            this.loadAnnualPassContent()
          })
      window.addEventListener('message', this.receiveMessage)
      window.addEventListener('hashchange', this.handleHashChange)
    },
    beforeDestroy() {
      window.removeEventListener('message', this.receiveMessage)
      window.removeEventListener('hashchange', this.handleHashChange)
    },
    watch: {
      suitableAnnualPassProduct(newVal, oldVal) {
        if (oldVal?.[0]?.resourceUri != newVal?.[0]?.resourceUri) {
          this.loadAnnualPassContent()
        }
      }
    },
    methods: {
      onDelete() {
        if (!this.unbindAvailable) {
          return
        }
        this.unbindAvailable = false
        this.$bvModal.msgBoxConfirm(this.$t('Loyalty.pass_unbind_warning'), {
          okVariant: 'light',
          cancelVariant: 'primary',
          contentClass: 'unbind-modal-content',
          okTitle: this.$t('Loyalty.pass_unbind_confirm'),
          cancelTitle: this.$t('Loyalty.pass_unbind_cancel'),
          title: this.$t('Loyalty.pass_unbind_title'),
          hideHeaderClose: false
        }).then((val) => {
          if (val) {
            return this.unbindAnnualPass(this.annualPass.number)
          }
        }).finally(() => {
          this.unbindAvailable = true
        })
      },
      showSubscriptionConfirmModal() {
        this.$refs['subscription-confirm-modal'].show()
      },
      closeProlongationModal() {
        this.$refs['subscription-confirm-modal'].hide()
      },
      handleHrefClick(e) {
        if (e.target.matches('a[href^="#"]')) {
          e.preventDefault()
          if (e.target.hash !== window.location.hash) {
            history.pushState({}, null, e.target.hash)
          }
          this.handleHashChange()
          if (window.location.hash === '#' + this.autoRenewalButtonId
              && !this.annualPass.subscribedForAutoRenewal
              && (this.annualPass.autoRenewalOwnerId === this.userId || !this.annualPass.autoRenewalOwnerId)) {
            this.showSubscriptionConfirmModal()
          }
        }
      },
      handleHashChange() {// обработка перемещения внутри страницы
        let scrollIntoMiddle = (elId) => {
          const element = document.getElementById(elId)
          if (element) {
            const elementRect = element.getBoundingClientRect()
            const absoluteElementTop = elementRect.top + elementRect.height / 2 + window.pageYOffset
            const middle = absoluteElementTop - (window.innerHeight / 2)
            if (isSmoothScrollSupported) {
              window.scrollTo({top: middle, behavior: 'smooth'})
            } else {
              window.scrollTo(0, middle)
            }
          }
        }
        if (this.renewalAvailable && '#' + this.renewalFormId === window.location.hash) {
          if (this.renewalVisible) {
            scrollIntoMiddle(this.renewalFormId)
          } else {
            this.$refs['renewal-collapse'].$once('shown', () => scrollIntoMiddle(this.renewalFormId))
            this.renewalVisible = true
          }
        } else if (this.typeChangeAvailable && '#' + this.typeChangeFormId === window.location.hash) {
          if (this.typeChangeVisible) {
            scrollIntoMiddle(this.typeChangeFormId)
          } else {
            this.$refs['type-change-collapse'].$once('shown', () => scrollIntoMiddle(this.typeChangeFormId))
            this.typeChangeVisible = true
          }
        }else if ('#' + this.autoRenewalButtonId === window.location.hash) {
          scrollIntoMiddle(this.autoRenewalButtonId)
        }
      },
      unsubscribePassClicked() {
        this.$bvModal.msgBoxConfirm(this.$t('Loyalty.pass_unsubscribe_warning'), {
          okVariant: 'light',
          cancelVariant: 'primary',
          contentClass: 'unbind-modal-content',
          okTitle: this.$t('Loyalty.pass_unbind_confirm'),
          cancelTitle: this.$t('Loyalty.pass_unbind_cancel'),
          title: this.$t('Loyalty.pass_unsubscribe_title'),
          hideHeaderClose: false
        }).then((val) => {
          if (val) {
            this.unsubscribeAnnualPass(this.annualPass.number)
                .then(() => {
                  document.location.reload()
                })
          }
        })
      },
      subscribePassClicked() {
        if (!this.bindingAvailable) {
          return
        }
        this.bindingAvailable = false
        this.sberbankError = null
        this.$refs['subscription-confirm-modal'].hide()
        this.subscribeAnnualPass({
          guid: this.annualPass.annualPassGuid,
          number: this.annualPass.number,
          locale: this.$root.$i18n.locale,
        }).catch((e) => {
          if (e.response && typeof e.response.data === 'object' && 'sberbankErrorCode' in e.response.data) {
            this.sberbankError = {
              statusCode: e.response.data.sberbankStatusCode,
              errorCode: e.response.data.sberbankErrorCode,
              errorMessage: e.response.data.sberbankErrorMessage
            }
          }
        }).then((data) => {
          if (data.needsCardAssignment) {
            this.verificationFormUrl = data.redirectUrl
            this.$nextTick(() => {
              this.$refs.verificationForm.show()
            })
          } else {
            if(this.loyaltyPageInfo[this.$root.$i18n.locale].gift?.types?.includes(this.annualPass.type)) {
              this.error=null
              this.$refs['subscription-success-modal-gift'].show()
            }
            else {
              this.$refs['subscription-success-modal'].show()
            }
          }
        }).finally(() => { this.bindingAvailable = true })
      },
      changePaymentMethodClicked() {
        if (!this.bindingAvailable) {
          return
        }
        this.bindingAvailable = false
        this.sberbankError = null
        this.performPaymentMethodChange(this.$root.$i18n.locale)
            .then(resp => {
              this.verificationFormUrl = resp.data.redirectUrl
              this.$nextTick(() => {
                this.$refs.verificationForm.show()
              })
            }).catch((e) => {
              if (e.response && typeof e.response.data === 'object' && 'sberbankErrorCode' in e.response.data) {
                this.sberbankError = {
                  statusCode: e.response.data.sberbankStatusCode,
                  errorCode: e.response.data.sberbankErrorCode,
                  errorMessage: e.response.data.sberbankErrorMessage
                }
              }
            }).finally(() => {
              this.bindingAvailable = true
            })
      },
      addGift() {
        this.gift = true
      },
      receiveMessage(event) {
        if (event.data.command === 'purchaseResult') {
          if(this.gift) {
            this.error=null
            this.$refs['subscription-success-modal-gift'].show()
            this.gift=false
          }
        }
        if (this.verificationFormUrl && (this.$refs.verificationFormIframe && event.source === this.$refs.verificationFormIframe.contentWindow)) {
          console.debug('Получено сообщение ' + event.data)
          if (event.data.command === 'verificationResult') {
            console.log('Результат выполнения ' + event.data.verificationResult ? 'успешно' : 'неуспешно')
            this.$refs.verificationForm.hide()
            this.updateUserData()
            if(this.loyaltyPageInfo[this.$root.$i18n.locale].gift.types.includes(this.annualPass.type)) {
              this.error=null
              this.$refs['subscription-success-modal-gift'].show()
            }
            else {
              this.$refs['subscription-success-modal'].show()
            }
          }
          else if (event.data.command === 'resize') {
            this.verificationFormHeight = event.data.height
          }
          //TODO: добавить перезапрос наличия карты у пользователя
        }
      },
      evalBannerText(text) {
        if (!(typeof text === 'string')) {
          return null
        }
        text = text
          .replaceAll('$renewalForm', this.renewalFormId)
          .replaceAll('$typeChangeForm', this.typeChangeFormId)
          .replaceAll('$autoRenewalButton', this.autoRenewalButtonId)
        if (this.annualPass?.attributes?.uspace) {
          text = text.replaceAll(/\$(?:(uspaceSessions)|(\{uspaceSessions\}))/g, this.annualPass.attributes.uspace)
        }
        if (this.specialOfferEnabled) {
          text = text.replaceAll(/\$(?:(discountExpires)|(\{discountExpires\}))/g, moment(this.annualPass.specialPrice.expires).format('DD.MM.yyyy'))
        }
        return text
      },
      loadAnnualPassContent() {
        if (this.suitableAnnualPassProduct?.[0]?.resourceUri == null) {
          console.warn('Не найден resourceUri для абонемента')
          this.suitableAnnualPassContent = null
          return
        }
        fetchAnnualPass(this.suitableAnnualPassProduct[0].resourceUri).then(resp => {
          this.suitableAnnualPassContent = resp.data
        })
      },
      giftChoose() {
        if (!this.giftButtonAvailable) {
          return
        }
        if (this.giftPicked != null) {
          this.giftButtonAvailable = false
          this.sendGiftInfo({
            passId: this.annualPass.annualPassGuid,
            giftIndex: this.giftPicked,
            passNumber: this.annualPass.number,
            giftNameRU: this.loyaltyPageInfo['ru'].gift.gifts[this.giftPicked].title,
            giftNameEN:  this.loyaltyPageInfo['en'].gift.gifts[this.giftPicked].title,
            locale: this.$root.$i18n.locale
          }).then(() => {
            this.$refs['subscription-success-modal-gift'].hide()
            this.error = null
            store.dispatch('auth/checkUnreadMessageCount')
          }).catch((err) => {
            this.error = err.response?.data?.message
            if (this.error == null) {
              this.error = this.$t('Loyalty.back_gift_error')
            }
          }).finally(() => {
            this.giftButtonAvailable = true
          })
        } else {
          this.error = this.$t('Loyalty.gift_not_selected')
        }
      },
      ...mapActions({
        updateUserData: 'auth/updateUserData',
        unbindAnnualPass: 'loyalty/unbindAnnualPass',
        loadAnnualPassTypeList: 'loyalty/loadAnnualPassTypeList',
        subscribeAnnualPass: 'loyalty/subscribeForAutoRenewal',
        sendGiftInfo: 'loyalty/sendGiftInfo',
        unsubscribeAnnualPass: 'loyalty/unsubscribeFromAutoRenewal',
        performPaymentMethodChange: 'loyalty/performPaymentMethodChange'
      }),
    },
    data() {
      return {
        suitableAnnualPassContent: null,
        renewalVisible: false,
        typeChangeVisible: false,
        verificationFormUrl: null,
        verificationFormHeight: null,
        giftPicked: null,
        gift: null,
        error: null,
        giftButtonAvailable: true,
        unbindAvailable: true,
        bindingAvailable: true,
        sberbankError: null,
      }
    },
    props: {
      wrapContainer: {
        type: Boolean,
        default: false
      },
      annualPass: {
        type: Object,
        required: true
      },
      loyaltyPageInfo: null,
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .qr-code {
    image-rendering: pixelated;
  }
  .qr-code-modal .qr-code canvas,
  .qr-code-modal .qr-code img {
    width: 75% !important;
    height: auto !important;
    max-width: 200px;
    max-height: 200px;
  }
  .annual-pass {
    font-size: 18px;
    .annual-pass-info {
      display: grid;
      grid-template-areas: "title qrcode" "description qrcode" "banners qrcode" "subscription-benefits subscription-benefits";
      column-gap: 110px;
    }

    .pass-title {
      grid-area: title;
      text-transform: lowercase;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.002em;
    }

    .annual-pass-desc {
      grid-area: description;
      margin-top: 30px;
    }

    .subscription-benefits {
      grid-area: subscription-benefits;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      margin-top: 36px;
      margin-bottom: 50px;
    }
    .subscription-benefits > * {
      padding-top: 24px;
      padding-bottom: 24px;
      width: 100%;
      position: relative;
    }

    .subscription-benefits > *:not(:last-child) {
      margin-bottom: 4px;
    }

    .subscription-benefits > .highlighted-banner::before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 50vw + var(--scrollbar-width) / 2);
      right: calc(50% - 50vw + var(--scrollbar-width) / 2);
      background: rgba($primary, 0.05);
      content: "";
      z-index: -1;
    }

    .subscription-benefits > .highlighted-banner a {
      color: #{$primary};
    }

    .subscription-benefits p {
      margin-bottom: 0;
    }

    .subscription-benefits .item-icon,
    .subscription-benefits img {
      width: 45px;
      align-self: center;
    }

    .qr-panel {
      grid-area: qrcode;
      display: flex;
      max-width: 250px;
      justify-self: right;

      p {
        font-weight: #{$font-weight-medium};
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.002em;
      }
    }

    a {
      text-decoration: underline;
    }

    dd {
      margin: 0;
    }

    dd::after {
      content: '\A';
      white-space: pre-line;
    }

    dd:last-of-type::after {
      content: '';
    }

    dd, dt {
      display: inline;
    }

    dd, dt, .address {
      vertical-align: middle;
    }

    dt {
      font-weight: 500;
    }

    dt::after {
      content: ': ';
    }

    .unbind-pass, .subscribe-pass, .change-subscription-payment, .unsubscribe-pass {
      color: #{$secondary};
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      font-size: 18px;
      padding: 0;
    }

    .manual-extend {

    }

    .manual-type-change {
      margin-top: 30px;
    }

    .renewal-collapse.show ~ .manual-type-change {
      margin-top: 40px;
    }

    .renewal-container {
      background-color: var(--erarta-very-light-gray);
    }

    .full-width {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }

    .manual-extend-toggle, .manual-extend-toggle:hover, .manual-extend-toggle:focus {
      color: #{$dark};
    }

    .qr-icon {
      grid-area: qr-icon;
      width: 36px;
      margin-top: 12px;
      position: relative;
    }

    .qr-icon > img {
      width: 100%;
      position: absolute;
      top: 0;
    }
    .renewal-collapse {
      margin-top: 34px;
      p {
        margin-bottom: 0;
      }
    }

    .renewal-form {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    .prolongation-info-content {
      * {
        margin-bottom: 0;
      }
      ul {
        list-style-type: "— ";
        list-style-position: inside;
        padding-left: 0;
      }
    }

    .subscription-confirm-modal .modal-subscription-form {
      margin-bottom: 0;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .qr-icon {
        display: none;
      }

      .prolongation-info-content {
        margin-bottom: 40px;
        max-width: 890px;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      font-size: 14px;

      .pass-title {
        font-size: 25px;
        line-height: 25px;
        letter-spacing: inherit;
      }
      .subscription-benefits {
        font-size: 14px;
        line-height: 16px;
        margin-top: 11px;
        margin-bottom: 31px;
      }

      .subscription-benefits > * {
        padding-top: 17px;
        padding-bottom: 17px;
      }

      .subscription-benefits > *:not(:last-child) {
        margin-bottom: 5px;
      }
      .subscription-benefits .item-icon,
      .subscription-benefits img {
        width: 22px;
      }

      .annual-pass-desc {
        margin-top: 13px;
      }

      .unbind-pass, .subscribe-pass, .change-subscription-payment, .unsubscribe-pass {
        font-size: 14px;
      }

      .annual-pass-info {
        display: grid;
        grid-template-areas: "title qr-icon" "description description" "banners banners" "subscription-benefits subscription-benefits" "renewal-button renewal-button" "renewal-block renewal-block" "type-change-button type-change-button" "type-change-block type-change-block";
        grid-template-columns: 1fr 36px;
        column-gap: 10px;
      }
      .qr-panel {
        display: none;
      }

      .prolongation-info-content {
        margin-bottom: 20px;
      }

      .manual-extend {

      }

      .manual-type-change {
        margin-top: 20px;
      }

      .renewal-collapse.show ~ .manual-type-change {
        margin-top: 30px;
      }

      .renewal-collapse {
        margin-top: 14px;
      }
      .renewal-form {
        padding-top: 9px;
        padding-bottom: 30px;
      }
    }
  }
  .unbind-modal-content {
    .modal-header {
      padding: 60px 0 20px 0;
      height: auto;

      .modal-title {
        font-size: 32px !important;
        line-height: 40px;
        font-weight: 500;
        color: #{$dark};
      }
    }
    .modal-footer {
      justify-content: space-around;
      border-top: none;
      padding: 28px 0 0 0;
      > .btn {
        flex: 1;
        margin: 0;
      }
      > .btn:not(:last-child) {
        margin: 0 26px 0 0;
      }
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .modal-header {
        padding: 98px 0 25px 0;
      }

      .modal-footer {
        padding: 50px 0 112px 0;
        flex-direction: column;

        >.btn:not(:last-child) {
          margin: 0 0 18px 0;
        }
        >.btn {
          width: 100%;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .modal-subscription-title {
    font-size: 32px;
    line-height: 1;
    font-weight: #{$font-weight-medium};
  }

  .modal-subscription-button{
    width:268px !important;
    height: 50px;
    margin-left: calc(50% - 134px);
  }

  .modal-subscription-div{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .modal-subscription-confirm-button {
    display: inline-block;
    width: 168px !important;
    height: 50px;
    margin-top: 20px;
  }

  .modal-subscription-confirm-button-yes {
    margin-left: calc(100% - 336px);
    margin-right: 0;
  }

  .modal-subscription-confirm-subtitle {
    margin-top: 25px;
  }

  .modal-subscription-form {
    width: auto;
  }

  .modal-a{
    color: #{$primary};
  }

  .small-text{
    font-size: 14px;
    color: #{$secondary};
    line-height: 20px;
    letter-spacing: 0.002em;
  }
  .radio-input{
    position: absolute;
    width: 18px;
    height: 18px;
  }

  input[type="radio"]:checked ,
  input[type="radio"]:not(:checked) {
    display: inline-block;
    position: relative;
    cursor: pointer;

  }

  input[type="radio"]:not(:checked):before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border: 1px solid #D1D1D0;
    background-color: white;
    border-radius: 100%;
  }

  input[type="radio"]:checked:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #{$primary};
    background-color: #{$primary};
    border-radius: 100%;
  }

  input[type="radio"]:checked:after
  {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: white;
  }

  .modal-subscription-gift-title {
    font-size: 17px;
    letter-spacing: 0.002em;
    display: inline-block;
    vertical-align: middle;
  }
  @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
    .modal-subscription-title {
      font-size: 32px;
      line-height: 1;
      font-weight: 500;
    }

    .modal-subscription-subtitle{
      margin-bottom: 6em !important;
    }

    .modal-subscription-confirm-button {
      width: 40% !important;
      height: 50px;
    }

    .modal-subscription-confirm-button-yes {
      margin-left: 10%;
    }

    .modal-subscription-button {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto
    }
  }

</style>
