<template>
  <b-form class="guide-search-form" @submit.prevent="onSubmit" inline>
    <img class="search-prefix" :src="require('@/assets/icons/search.svg')" >
    <b-form-input class="flex-grow-1" name="exhibit-number" v-model="exhibitNumber" type="number" :placeholder="$t('AudioGuides.audio_guide_exhibit_search_placeholder')" />
    <b-button type="submit" :title="$t('AudioGuides.audio_guide_exhibit_search_submit')"><img :src="require('@/assets/icons/search_go.svg')" ></b-button>
  </b-form>
</template>
<script>
  export default {
    data() {
      return {
        exhibitNumber: ''
      }
    },
    methods: {
      onSubmit() {
        this.$emit('submit', this.exhibitNumber)
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-search-form {
    width: 100%;
    border: 1px solid #{$erarta-light-gray};
    border-radius: 3px;
    margin-bottom: 50px;

    .search-prefix {
      margin-left: 16px;
      margin-right: 20px;
    }
    .form-control {
      height: 50px;
      border: none;
      padding-left: 0;
      padding-right: 0;
      width: auto;
      width: calc(100% - 120px);
      letter-spacing: 0;
    }
    .form-control:focus {
      border-color: none;
      box-shadow: none;
    }
    button[type=submit],button[type=submit]:focus, button[type=submit]:active,
    button.btn-secondary[type=submit],button.btn-secondary[type=submit]:focus, button[type=submit].btn-secondary:active{
      background-color: transparent;
      padding-right: 15px;
      border: none;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      background-color: #{$erarta-very-light-gray};
      border: none;

      .form-control {
        height: 40px;
      }
      .form-control,.form-control:focus {
        background-color: #{$erarta-very-light-gray};
      }
      button.btn-secondary[type=submit],button.btn-secondary[type=submit]:focus, button[type=submit].btn-secondary:active {
        opacity: 0.5;
      }

      .search-prefix {
        margin-left: 17px;
        margin-right: 26px;
        width: 14px;
        height: auto;
      }
    }
  }

</style>
