<template>
  <inline-svg :src="getURL()" @loaded="svgImageLoaded($event)"
              :id="`iconImage_${id}`" :ref="`svgImage${id}`"
              :href="getURL()"
              :opacity="isStatic ? 1 : isHighlight ? 1 : 0"
              :class="`icon-image-${isStatic ? 'static' : 'animated'} ${contentExist ? 'cursor-pointer' : ''}`"
              v-on="contentExist
          ? {click: ($event) => showContent($event, id.replace(/_animated/g, '')) }
          : {click: ($event) => stopHighlightIcon($event, id.replace(/_animated/g, '')) }"/>
</template>

<script>
import buildingPlan from '@/modules/buildingPlan'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'SVGImage',
  props: {
    id: {
      type: String,
      required: true
    },
    isStatic: {
      type: Boolean,
      default: true
    },
    contentExist: {
      type: Boolean,
      default: false
    },
    isHighlight: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InlineSvg
  },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  methods: {
    showContent($event, id) {
      this.stopHighlightIcon($event, id)
      this.$parent.showModal(id)
    },
    stopHighlightIcon($event, id) {
      this.$parent.hideHand()
      $event.preventDefault()
      buildingPlan.stopHighlightIcon(id)
      $event.stopPropagation()
    },
    getURL() {
      return require(`../../assets/images/building_plan/${this.isStatic ? 'static' : 'animated'}/${this.id}.svg`)
    },
    svgImageLoaded(svg) {
      this.width = svg.width.animVal.value
      this.height = svg.height.animVal.value
    }
  }
}
</script>

<style scoped>
</style>
