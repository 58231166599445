import {fetchDocument, fetchDocuments} from '../modules/content-loader'
/* eslint-disable */
import vernissageApi from '../modules/vernissage'
import {i18n} from '../modules/locale'

const state = () => ({
  vernissages: null,
  registrationInfo: null,
  vernissagesLoaded: false,
})

const getters = {
}

const mutations = {
  saveVernissageList(state, { docs, registrationInfo}) {
    state.vernissages = docs
    state.registrationInfo = registrationInfo
    state.vernissagesLoaded = true
  },
}

const actions = {
  async fetchVernissageList({commit}) {
    const indexResponse = await fetchDocument('vernissage/index.json')
    const indexData = indexResponse?.data[i18n.locale]?.structure
    if (indexData == null) {
      return
    }
    const filenames = Object
          .values(indexData)
          .map(value => value.split('/'))
          .map(value => value.splice(value.length - 2))
          .map(value => value.join('/'))
    // TODO: пофиксить хардкод
          .map(value => value + '.json')
    console.debug('fetching vernissages')
    const vernissageDocs = (await fetchDocuments(filenames))
        .filter(resp => resp.status === 'fulfilled')
        .map(resp => resp.value?.data)

    console.debug('fetching success, fetched', vernissageDocs.length, 'vernissages')
    // Получаем количество регистраций по каждому вернисажу
    const vernissageGuidList = vernissageDocs.map(v => v[i18n.locale].guid)
    const registrationInfo = (await vernissageApi.getEventRegistrationCountList(vernissageGuidList)).data
    commit('saveVernissageList', { docs: vernissageDocs, registrationInfo })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
