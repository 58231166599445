<template>
  <div class="guide-exhibit-detail position-relative" v-if="available && guideIndex && currentGuide && currentExhibit">
    <div class="guide-exhibit-back">
      <back-link
        :nav-text="$t('AudioGuides.audio_guide_exhibit_back')"
        :to="`${guidePathPrefix}/${$route.params.guideId}`"
        class="container"/>
    </div>
    <b-container class="small-container">
      <img :src="currentExhibit[$root.$i18n.locale].preview" class="img-fluid w-100">
      <guide-exhibit-header :plate-code="currentExhibit[$root.$i18n.locale].code" :location="currentExhibit[$root.$i18n.locale].location" :plate-color="currentGuide[$root.$i18n.locale].tooltipColor" />
      <guide-exhibit-detail :guide-content="currentGuide[$root.$i18n.locale]"
        :exhibit-content="currentExhibit[$root.$i18n.locale]"
        :guide-index="guideIndex[$root.$i18n.locale]"
        :guideGuid="$route.params.guideId"
        :previous-link="getNextExhibit(-1)"
        :next-link="getNextExhibit(1)"
        @number-submitted="onNumberSubmitted"/>
      <template v-if="currentGuide[$root.$i18n.locale].floorPlan && currentGuide[$root.$i18n.locale].floorPlan.floors">
        <h2>{{currentGuide[$root.$i18n.locale].floorPlan.title}}</h2>
        <guide-floors :floors="guideFloorsMerged"></guide-floors>
        <router-link class="howto" to="/audioGuide/howto">{{$t('AudioGuides.audio_guide_howto_label')}}</router-link>
      </template>
    </b-container>
    <b-modal ref="search-failure" :hide-footer="true" header-class="p-0" content-class="py-0">
      <h2 class="exhibit-not-found">{{$t('AudioGuides.exhibit_not_found')}}</h2>
    </b-modal>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import AudioGuideExhibitHeader from '@/components/audio_guide/AudioGuideExhibitHeader'
  import AudioGuideExhibitDetail from '@/components/audio_guide/AudioGuideExhibitDetail'
  import AudioGuideFloorPlan from '@/components/audio_guide/AudioGuideFloorPlan'
  import {fetchDocument, fetchDocumentWithDynamicSitePath} from '@/modules/content-loader'
  import BackLink from '@/components/common/BackLink'
  import tracker from '@/modules/track'

  function loadAudioGuide(locale, route, guideIndex) {
    let guideUri = guideIndex[locale].structure[route.params.guideId]
    return fetchDocumentWithDynamicSitePath(guideUri + '.json')
  }

  function loadAudioGuideExhibit(locale, route, guideIndex) {
    let guideUri = guideIndex[locale].structure[route.params.guideId]
    let exhibitUri = guideIndex[locale]
      .resources[guideUri]
      .exhibits[route.params.exhibitCode]
    return fetchDocumentWithDynamicSitePath(exhibitUri + '.json')
  }

  export default {
    components : {
      'guide-exhibit-detail': AudioGuideExhibitDetail,
      'guide-floors': AudioGuideFloorPlan,
      'guide-exhibit-header': AudioGuideExhibitHeader,
      BackLink
    },
    async beforeRouteEnter(to, from, next) {
      let guideIndexResponse = await fetchDocument(to.meta?.props?.indexPath ? to.meta?.props?.indexPath : 'audio_guide/index.json')
      let [guideResponse, guideExhibitResponse] = await Promise.all([
        loadAudioGuide('ru', to, guideIndexResponse.data),
        loadAudioGuideExhibit('ru', to, guideIndexResponse.data),
      ])
      next( (vm) => {
        vm.guideIndex = guideIndexResponse.data
        vm.currentGuide = guideResponse.data
        vm.currentExhibit = guideExhibitResponse.data
        document.title = vm.title
        tracker.track(to, from)
      })
    },
    async beforeRouteUpdate(to, from, next) {
      if (!this.guideIndex) {
        this.guideIndex = (await fetchDocument(to.meta?.props?.indexPath ? to.meta?.props?.indexPath : 'audio_guide/index.json')).data
      }
      let [guideResponse, guideExhibitResponse] = await Promise.all([
        loadAudioGuide('ru', to, this.guideIndex),
        loadAudioGuideExhibit('ru', to, this.guideIndex),
      ])
      this.currentGuide = guideResponse.data
      this.currentExhibit = guideExhibitResponse.data
      next()
      document.title = this.title
      tracker.track(to, from)
    },
    created() {
      this.initializeContent()
    },
    mounted() {
      document.title = this.title
    },
    watch: {
      title: function(newVal) {
        document.title = newVal
      }
    },
    props: {
      indexPath: {
        type: String,
        default: 'audio_guide/index.json'
      },
      guidePathPrefix: {
        type: String,
        default: '/audioGuide'
      }
    },
    data() {
      return {
        guideIndex: null,
        currentGuide: null,
        currentExhibit: null,
      }
    },
    computed: {
      title() {
        if (!this.currentExhibit) {
          return 'Загрузка' + this.$t('Common.title_suffix')
        }
        return this.$route.params.exhibitCode + '. ' + this.currentExhibit[this.$root.$i18n.locale].title + this.$t('Common.title_suffix')
      },
      guideUri(){
        return this.guideIndex[this.$root.$i18n.locale].structure[this.$route.params.guideId]
      },
      guideFloorsMerged() {
        if (!this.currentExhibit
            || !this.currentExhibit[this.$root.$i18n.locale].shownFloors
            || this.currentExhibit[this.$root.$i18n.locale].shownFloors.length == 0) {
          return this.currentGuide[this.$root.$i18n.locale].floorPlan.floors
        }
        return this.currentGuide[this.$root.$i18n.locale].floorPlan.floors
                   .map((floor) => {
                     if (this.currentExhibit[this.$root.$i18n.locale].shownFloors.includes(floor.index)) {
                       return Object.assign({}, floor, { visible: true })
                     }
                     return floor
                   })
      },
      exhibitUri() {
        return this.guideIndex[this.$root.$i18n.locale]
                   .resources[this.guideUri]
                   .exhibits[this.$route.params.exhibitCode]
      },
      available() {
        return this.annualPasses?.length > 0 || !this.isNormalUser
      },
      ...mapGetters({
        annualPasses: 'loyalty/annualPasses',
        isNormalUser: 'auth/userIsNormal'
      })
    },
    methods: {
      initializeContent() {
        this.loadAnnualPassList()
        let guideIndexPromise = this.guideIndex ? Promise.resolve() :
                                fetchDocument(this.indexPath).then(resp => {
                                  this.guideIndex = resp.data
                                })
        if (!this.currentExhibit || !this.currentGuide) {
          guideIndexPromise.then(() => {
            return this.fetchAudioGuideExhibit()
          })
        }
      },
      fetchAudioGuideExhibit() {
        return Promise.all([
          fetchDocumentWithDynamicSitePath(this.guideUri + '.json')
            .then(resp => {
              this.currentGuide = resp.data
            }),
          fetchDocumentWithDynamicSitePath(this.exhibitUri + '.json')
            .then(resp => {
              this.currentExhibit = resp.data
            })
        ])
      },
      onNumberSubmitted(code) {
        if (this.guideIndex[this.$root.$i18n.locale].resources[this.guideUri].exhibits[code]) {
          let link = `${this.guidePathPrefix}/${this.$route.params.guideId}/${code}`
          if (this.$router.currentRoute.path !== link) {
            this.$router.push(link)
          }
        } else {
          if ('ga' in window) {
            let tracker = window.ga.getAll()[0]
            if (tracker) {
              tracker.send(
                'event',
                'audio_guide',
                'exhibit_not_found',
                code
              )
            }
          }
          this.$refs['search-failure'].show()
        }
      },
      getNextExhibit(offset) {
        let guideExhibitIndex = this.currentGuide[this.$root.$i18n.locale].exhibits?.indexOf(this.exhibitUri)
        if (guideExhibitIndex == null || guideExhibitIndex === -1) {
          console.debug('Не удалось найти индекс работы аудиогида')
          return null
        }
        let newExhibitIndex = guideExhibitIndex + offset
        if (newExhibitIndex < 0 || newExhibitIndex >= this.currentGuide[this.$root.$i18n.locale].exhibits.length) {
          console.debug('Выход за пределы массива работ')
          return null
        }
        let exhibitUri = this.currentGuide[this.$root.$i18n.locale].exhibits[newExhibitIndex]
        let code = Object
          .entries(this.guideIndex[this.$root.$i18n.locale].resources[this.guideUri].exhibits)
          .filter(([,v]) => v === exhibitUri)
          .map(([k]) => k)[0]
        if (code) {
          return `${this.guidePathPrefix}/${this.$route.params.guideId}/${code}`
        }
        return null
      },
      ...mapActions({loadAnnualPassList: 'loyalty/loadAnnualPassList' }),
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-exhibit-detail {
    a {
      color: #{$secondary};
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      letter-spacing: 0.002em;
      line-height: 24px;
    }

    @media all and (hover: hover) and (pointer: fine) {
      a:hover {
        color: #{$secondary};
        opacity: 0.7;
      }
    }
    @media not all and (hover: hover) and (pointer: fine) {
      a:hover {
        color: #{$secondary};
        text-decoration: underline;
      }
    }
    .howto {
      font-weight: #{$font-weight-medium};
      display: block;
      margin-top: 120px;
      margin-bottom: 80px;
      font-size: 19px;
    }

    .guide-exhibit-content {
      margin-top: 35px;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      margin-top: 40px;

      .guide-exhibit-header {
        height: 100px;
        border-bottom: solid 1px #{$erarta-much-lighter-gray};
      }

      .guide-exhibit-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .guide-exhibit-header {
        margin-top: 10px;
      }
      h2 {
        font-size: 25px;
        line-height: 28px;
      }
      .guide-exhibit-content {
        margin-top: 27px;
      }
      .back-link {
        margin-top: 28px;
        margin-bottom: 43px;
      }

      .howto {
        margin-top: 28px;
        margin-bottom: 40px;
      }
    }
  }
</style>
