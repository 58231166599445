import {MESSAGES_AXIOS} from '@/modules/backend-config'

export default {
  getMessageList(size, index) {
    return MESSAGES_AXIOS.post('/list', {
      pageSize: size,
      pageNumber: index
    })
  },
  getMessage(id) {
    return MESSAGES_AXIOS.get(`/${id}`)
  },
  updateReadState(id) {
    return MESSAGES_AXIOS.put(`/${id}`)
  },
  getUnreadCount() {
    return MESSAGES_AXIOS.get('/unread')
  }
}
