<template>
  <b-form @submit.prevent="onSubmit">
    <b-alert class="acc-error" variant="danger" :show="error != null && errorMessage != null" @dismissed="!error">{{ $t(errorMessage) }}</b-alert>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.password" :placeholder="$t('Authentication.passwordRecover_change_field_password_placeholder')" :type="passwordFieldNew.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldNew.hover = true" @mouseleave="passwordFieldNew.hover = false"
             @click="passwordFieldNew = switchVisibility(passwordFieldNew)" :src="passwordIcon(passwordFieldNew)">
      </div>
      <b-form-invalid-feedback :state="v$.form.password.$dirty ? !v$.form.password.required.$invalid : null">{{ $t('Authentication.passwordRecover_change_field_password_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.password.$dirty ? !v$.form.password.minLength.$invalid : null">{{ $t('Authentication.passwordRecover_change_field_password_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <div class="password-input">
        <b-form-input v-model="form.repeatPassword" :placeholder="$t('Authentication.passwordRecover_change_field_repeatPassword_placeholder')" :type="passwordFieldRep.type"/>
        <img class="toggle-icon" @mouseover="passwordFieldRep.hover = true" @mouseleave="passwordFieldRep.hover = false"
             @click="passwordFieldRep = switchVisibility(passwordFieldRep)" :src="passwordIcon(passwordFieldRep)">
      </div>
      <b-form-invalid-feedback :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.required.$invalid : null">{{ $t('Authentication.passwordRecover_change_field_repeatPassword_errors_mandatory') }}</b-form-invalid-feedback>
      <b-form-invalid-feedback :state="v$.form.repeatPassword.$dirty ? !v$.form.repeatPassword.sameAsPassword.$invalid : null">{{ $t('Authentication.passwordRecover_change_field_repeatPassword_errors_invalid') }}</b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="isProcessing">{{ $t('Authentication.passwordRecover_change_confirm') }}</b-button>
  </b-form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {minLength, required, sameAs} from '@vuelidate/validators'
import account from '@/modules/account'

export default {
  name: 'PasswordRecovery',
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        password: '',
        repeatPassword: '',
        token: ''
      },
      isProcessing: false,
      error: null,
      passwordFieldNew: {
        type: 'password',
        hover: false,
        visible: false
      },
      passwordFieldRep: {
        type: 'password',
        hover: false,
        visible: false
      }
    }
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(6)
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs(this.form.password)
        }
      }
    }
  },
  mounted() {
    {
      let token = this.$route.query.token
      if (!token) {
        this.$router.push('/login')
      } else {
        this.form.token = token
      }
    }
  },
  computed: {
    errorMessage() {
      if (this.error == null) {
        return null
      }
      const fields = {
        'USER_NOT_FOUND': 'Authentication.passwordRecover_error_token_not_found',
        'USER_INACTIVE': 'Authentication.passwordRecover_error_user_inactive',
      }
      return fields[this.error]
    }
  },
  methods: {
    onSubmit() {
      this.error = null
      this.v$.$touch()
      if (this.v$.$invalid || this.isProcessing) {
        return
      }
      this.isProcessing = true
      account.confirmPasswordRecovery(this.form)
             .then(() => this.$emit('completed'))
             .catch(err => this.error = err.response?.data?.errorKind)
             .finally(() => this.isProcessing = false)
    },
    switchVisibility(field) {
      field.type = field.type === 'password' ? 'text' : 'password'
      return field
    },
    passwordIcon(passwordField) {
      if (passwordField.type === 'password') {
        if (passwordField.hover === true) {
          return require('@/assets/icons/eye-hover.svg')
        } else {
          return require('@/assets/icons/eye.svg')
        }
      } else {
        if (passwordField.type === 'text') {
          if (passwordField.hover === true) {
            return require('@/assets/icons/eye-slash-hover.svg')
          } else {
            return require('@/assets/icons/eye-slash.svg')
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.password-input{
  position: relative;
}

.toggle-icon{
  top: 20px;
  right: 20px;
  height: 20px;
  width: 26px;
  display: inline-block !important;
  position: absolute;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .toggle-icon{
    top: 11px;
    right: 11px;
    height: 18px;
    width: 24px;
  }
}
</style>
