<template>
  <div :class="className"><span @click="switchLocale">{{switchedLocale.toUpperCase()}}</span></div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'LocaleSwitcher',
    data() {
      return {}
    },
    props: {
      className: {
        type: String,
        default: ''
      },
      localeChanged: {
        type: Function,
        default: () => {
        }
      }
    },
    computed: {
      switchedLocale: function() {
        return this.$root.$i18n.locale === 'ru' ? 'en' : 'ru'
      }
    },
    methods: {
      switchLocale() {
        if (this.$root.$i18n.locale === 'en') {
          this.$root.$i18n.locale = 'ru'
        } else {
          this.$root.$i18n.locale = 'en'
        }
        this.$emit('localeChanged')
        localStorage.setItem('lang',this.$root.$i18n.locale)
        moment.locale(this.$root.$i18n.locale)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
  span {
    cursor: pointer;
    font-weight: #{$font-weight-medium};
    vertical-align: -2px;
  }

  @media(hover: hover) and (min-width: #{map-get($grid-breakpoints, md)}) {
    span:hover {
      color: #{$primary} !important;
      vertical-align: -1px;
    }
  }

</style>
