<template>
  <div class="d-flex flex-column flex-1">
    <b-container>
      <page-title :title="header" custom-class="registration"></page-title>
    </b-container>
    <div v-if="!registrationCompleted" class="acc-container justify-content-center d-flex">
      <b-row class="acc-form-row">
        <b-col md="auto">
          <Registration @completed="registrationCompleted = true"/>
        </b-col>
      </b-row>
    </div>
    <b-container v-else>
      <span class="acc-instructions">{{ $t('Authentication.confirmRegistration_text') }}</span>
    </b-container>
  </div>
</template>

<script>
  import Registration from '@/components/Registration'
  import PageTitle from '@/components/common/PageTitle'
  import tracker from '@/modules/track'

  export default {
    name: 'RegistrationPage',
    components: {Registration, PageTitle},
    data () {
      return {
        registrationCompleted: false
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        document.title = vm.title
        tracker.track(to, from)
      })
    },
    mounted() {
      document.title = this.title
    },
    watch: {
      title: function(newVal) {
        document.title = newVal
      }
    },
    computed: {
      header() {
        return this.registrationCompleted ? this.$t('Authentication.confirmRegistration_header') : this.$t('Authentication.signUp_header')
      },
      title() {
        return (this.registrationCompleted ? this.$t('Authentication.confirmRegistration_title') : this.$t('Authentication.signUp_title')) + this.$t('Common.title_suffix')
      }
    }
  }
</script>
