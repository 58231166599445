<template>
  <div class="guide-exhibit-detail position-relative" v-if="guideIndex && currentGuide && currentExhibit">
    <div class="guide-exhibit-back">
      <back-link
        :nav-text="$t('AudioGuides.audio_guide_exhibit_back')"
        :to="`/privateAudioGuide/${$route.params.guideId}`"
        class="container"/>
    </div>
    <b-container class="small-container">
      <img :src="currentExhibit[$root.$i18n.locale].preview" class="img-fluid w-100">
      <guide-exhibit-header :plate-code="currentExhibit[$root.$i18n.locale].code" :location="currentExhibit[$root.$i18n.locale].location" :plate-color="currentGuide[$root.$i18n.locale].tooltipColor" />
      <guide-exhibit-detail :guide-content="currentGuide[$root.$i18n.locale]"
        :exhibit-content="currentExhibit[$root.$i18n.locale]"
        :guide-index="guideIndex[$root.$i18n.locale]"
        :guideGuid="$route.params.guideId"
        :previous-link="getNextExhibit(-1)"
        :next-link="getNextExhibit(1)"
        @number-submitted="onNumberSubmitted"/>
      <template v-if="currentGuide[$root.$i18n.locale].floorPlan && currentGuide[$root.$i18n.locale].floorPlan.floors">
        <h2>{{currentGuide[$root.$i18n.locale].floorPlan.title}}</h2>
        <guide-floors :floors="guideFloorsMerged"></guide-floors>
      </template>
    </b-container>
    <b-modal ref="search-failure" :hide-footer="true" header-class="p-0" content-class="py-0">
      <h2 class="exhibit-not-found">{{$t('AudioGuides.exhibit_not_found')}}</h2>
    </b-modal>
  </div>
</template>
<script>
  import AudioGuideExhibitDetailPage from './AudioGuideExhibitDetailPage'
  import {fetchDocument} from '@/modules/content-loader'

  export default {
    extends: AudioGuideExhibitDetailPage,
    methods: {
      initializeContent() {
        let guideIndexPromise = this.guideIndex ? Promise.resolve() :
                                fetchDocument(this.indexPath).then(resp => {
                                  this.guideIndex = resp.data
                                })
        if (!this.currentExhibit || !this.currentGuide) {
          guideIndexPromise.then(() => {
            return this.fetchAudioGuideExhibit()
          })
        } 
      }
    }
  }
</script>
