<template>
  <div>
    <div class="order-item">
      <div class="order-item-title">
        <a :href="item.itemLink" v-if="dataUrl">
          <img class="item-image" :src="dataUrl" alt="">
        </a>
        <div class="title-text">
          <span class="title" v-if="dataTitle" >{{ dataTitle }}</span>
          <span>{{ item['author_' + $root.$i18n.locale] }}</span>
        </div>
      </div>
      <div class="order-item-desc">{{ item['description_' + $root.$i18n.locale] }}</div>
      <div class="order-item-count"><span class="mobile-title">{{$t('Order.count')}}</span>{{ item.count }}</div>
      <div class="order-item-price">
        <span class="mobile-title">{{$t('Order.price')}}</span>
        <span class="full_price" v-if="item.discount !== item.price">{{item.price}}</span>
        <span class="price">&nbsp;{{ item.discount }}</span>
      </div>
    </div>

    <hr class="order-detail-separator">
  </div>
</template>

<script>
import {fetchAnnualPass} from '@/modules/content-loader'

export default {
  name: 'OrderDetailView',
  data: () => ({
    annualPass: {}
  }),
  props: {
    item: {
      type: Object,
      required: true
    },
    isAnnualPass: {
      type: Boolean,
      required: true
    }
  },
  methods : {
    loadAnnualPass() {
      fetchAnnualPass(this.item.resourceUri).then(doc => {
        this.annualPass = doc.data
      })
    }
  },
  computed : {
    dataUrl(){
      if (this.item.image != null) {
        return this.item.image
      } else if (this.isAnnualPass && this.annualPass[this.$root.$i18n.locale] && this.annualPass[this.$root.$i18n.locale].image != null) {
        return this.annualPass[this.$root.$i18n.locale].image
      }
      return null
    },
    dataTitle(){
      if (this.item['title_' + this.$root.$i18n.locale] != null) {
        return this.item['title_' + this.$root.$i18n.locale]
      } else if (this.isAnnualPass && this.annualPass[this.$root.$i18n.locale] && this.annualPass[this.$root.$i18n.locale].title != null) {
        return this.annualPass[this.$root.$i18n.locale].title
      }
      return null
    }
  },
  beforeMount() {
    if (this.isAnnualPass && this.item.resourceUri) {
      fetchAnnualPass(this.item.resourceUri).then(doc => {
        this.annualPass = doc.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.order-item {
  display: flex;
  align-items: center;
}
.order-item-title {
  display: flex;
  align-items: center;
}
.title-text {
  display: grid;
}
.item-image {
  width: 150px;
  height: 150px;
  margin-right: 38px;
}
.title {
  font-weight: 500;
  font-size: 19px;
}
.order-item-desc {
  color: #87878A;
  font-size: 16px;
}
.order-item-count {
  font-weight: 500;
  font-size: 24px;
}
.full_price {
  font-size: 24px;
}
.price {
  font-size: 24px;
  font-weight: 500;
}

.order-item-price > span::after {
  content: ' ₽';
  white-space: pre-line;
}

.mobile-title {
  display: none;
}

.order-item-price {
  text-align: right;
}

@media (max-width: 1023px) {
  .order-item {
    display: block;
  }
  .title-text {
    margin-left: 0;
    margin-right: auto;
    font-size: 16px;
  }
  .item-image {
    width: 100px;
    height: 100px;
  }
  .mobile-title {
    display: block;
    font-weight: 500;
  }
  .orders .mobile-title::after {
    content: ':\00a0';
  }
  .order-item-title {
    align-items: end;
    flex-direction: row-reverse;
}
  .order-item-count {
    font-size: 17px;
    font-weight: unset;
    text-align: left !important;
    line-height: 20px;
    display: flex;
    margin-top: 20px;
  }
  .order-item-price {
    font-size: 17px;
    text-align: left;
    margin-top: 7px;
    line-height: 20px;
    display: flex;
  }
  .price {
    font-size: 17px;
    font-weight: unset;
  }
  .full_price {
    font-size: 17px;
  }
  .order-item-desc {
    display: flex;
    margin-top: 10px;
    font-size: 14px;
  }
  .title {
    font-size: 17px;
  }
}
</style>
