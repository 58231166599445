<template>
  <div class="guide-detail" v-if="guideIndex && currentGuide">
    <guide-banner :background-image-url="currentGuide[$root.$i18n.locale].preview"
      :title="currentGuide[$root.$i18n.locale].title"
      :category="$t(currentGuide[$root.$i18n.locale].regular ? 'AudioGuides.audio_guide_category' : 'AudioGuides.audio_guide_temporary_category')"
      back-link="/privateAudioGuide"
      :back-title="$t('AudioGuides.audio_guide_detail_back')"
    />
    <guide-header class="container"
      :info-block="currentGuide[$root.$i18n.locale].infoBlock"
      :plate-color="currentGuide[$root.$i18n.locale].tooltipColor"
      :show-excursion="currentGuide[$root.$i18n.locale].regular"
      @excursion-start-click="excursionStartHandle" />
    <hr>
    <b-container class="small-container">
      <guide-detail :guide-content="currentGuide[$root.$i18n.locale]" :guide-index="guideIndex[$root.$i18n.locale]" :guideGuid="$route.params.guideId" @number-submitted="onNumberSubmitted" />
      <template v-if="currentGuide[$root.$i18n.locale].floorPlan && currentGuide[$root.$i18n.locale].floorPlan.floors">
        <h2>{{currentGuide[$root.$i18n.locale].floorPlan.title}}</h2>
        <guide-floors :floors="currentGuide[$root.$i18n.locale].floorPlan.floors"></guide-floors>
      </template>
    </b-container>
    <b-modal ref="search-failure" :hide-footer="true" header-class="p-0" content-class="py-0">
      <h2 class="exhibit-not-found">{{$t('AudioGuides.exhibit_not_found')}}</h2>
    </b-modal>
  </div>
</template>
<script>
  import AudioGuideDetailPage from './AudioGuideDetailPage'
  import {fetchDocument} from '@/modules/content-loader'

  export default {
    extends: AudioGuideDetailPage,
    methods: {
      initializeContent() {
        // подгрузка контента на случай перезагрузки кода (где обработчики не срабатывают)
        let guideIndexPromise = this.guideIndex ? Promise.resolve() :
                                fetchDocument(this.indexPath).then(resp => {
                                  this.guideIndex = resp.data
                                })
        if (!this.currentGuide) {
          guideIndexPromise.then(() => {
            return this.fetchAudioGuide()
          })
        }
      }
    }
  }
</script>
