<template>
  <div class="guide-detail" v-if="(annualPasses || !this.isNormalUser) && guideIndex && currentGuide" @click="handleClicks">
    <guide-banner :background-image-url="currentGuide[$root.$i18n.locale].preview"
      :title="currentGuide[$root.$i18n.locale].title"
      :category="$t(currentGuide[$root.$i18n.locale].regular ? 'AudioGuides.audio_guide_category' : 'AudioGuides.audio_guide_temporary_category')"
      back-link="/audioGuide"
      :back-title="$t('AudioGuides.audio_guide_detail_back')"
    />
    <guide-header class="container"
      :info-block="currentGuide[$root.$i18n.locale].infoBlock"
      :plate-color="currentGuide[$root.$i18n.locale].tooltipColor"
      :show-excursion="available && currentGuide[$root.$i18n.locale].regular"
      @excursion-start-click="excursionStartHandle" />
    <hr>
    <guide-demo-header @info-clicked="$refs['loyalty-info'].show()" v-if="!available" class="container" bind-form-url="/loyalty" />
    <hr v-if="!available">
    <b-container class="small-container" v-if="available">
      <guide-detail :guide-content="currentGuide[$root.$i18n.locale]" :guide-index="guideIndex[$root.$i18n.locale]" :guideGuid="$route.params.guideId" @number-submitted="onNumberSubmitted" />
      <template v-if="currentGuide[$root.$i18n.locale].floorPlan && currentGuide[$root.$i18n.locale].floorPlan.floors">
        <h2>{{currentGuide[$root.$i18n.locale].floorPlan.title}}</h2>
        <guide-floors :floors="currentGuide[$root.$i18n.locale].floorPlan.floors"></guide-floors>
        <router-link class="howto opacity-hover" to="/audioGuide/howto">{{$t('AudioGuides.audio_guide_howto_label')}}</router-link>
      </template>
    </b-container>
    <b-container v-else>
      <guide-demo-detail :guide-content="currentGuide[$root.$i18n.locale]" />
    </b-container>
    <b-modal ref="search-failure" :hide-footer="true" header-class="p-0" content-class="py-0">
      <h2 class="exhibit-not-found">{{$t('AudioGuides.exhibit_not_found')}}</h2>
    </b-modal>
    <b-modal ref="loyalty-info" header-class="p-0" content-class="py-0 loyalty-info" footer-class="d-flex justify-content-center">
      <h2>{{$t('AudioGuides.no_annual_pass_modal_title')}}</h2>
      <p>{{$t('AudioGuides.no_annual_pass_modal_description')}}</p>
      <template #modal-footer>
        <a :href="$t('AudioGuides.no_annual_pass_modal_link_url')" class="external">{{$t('AudioGuides.no_annual_pass_modal_link_label')}}</a>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import HeaderBanner from '@/components/audio_guide/HeaderBanner'
  import AudioGuideHeader from '@/components/audio_guide/AudioGuideHeader'
  import AudioGuideDetail from '@/components/audio_guide/AudioGuideDetail'
  import AudioGuideDemoHeader from '@/components/audio_guide/AudioGuideDemoHeader'
  import AudioGuideDemoDetail from '@/components/audio_guide/AudioGuideDemoDetail'
  import AudioGuideFloorPlan from '@/components/audio_guide/AudioGuideFloorPlan'
  import {fetchDocument, fetchDocumentWithDynamicSitePath} from '@/modules/content-loader'
  import tracker from '@/modules/track'

  function loadAudioGuide(locale, route, guideIndex) {
    let guideUri = guideIndex[locale].structure[route.params.guideId]
    return fetchDocumentWithDynamicSitePath(guideUri + '.json')
  }

  export default {
    components : {
      'guide-banner': HeaderBanner,
      'guide-detail': AudioGuideDetail,
      'guide-demo-detail': AudioGuideDemoDetail,
      'guide-floors': AudioGuideFloorPlan,
      'guide-header': AudioGuideHeader,
      'guide-demo-header': AudioGuideDemoHeader,
    },
    async beforeRouteEnter(to, from, next) {
      let guideIndexResponse = await fetchDocument(to.meta?.props?.indexPath ? to.meta?.props?.indexPath : 'audio_guide/index.json')
      let guideResponse = await loadAudioGuide('ru', to, guideIndexResponse.data)
      next( (vm) => {
        vm.currentGuide = guideResponse.data
        vm.guideIndex = guideIndexResponse.data
        document.title = vm.title
        tracker.track(to, from)
      })
    },
    async beforeRouteUpdate(to, from, next) {
      if (!this.guideIndex) {
        this.guideIndex = (await fetchDocument(to.meta?.props?.indexPath ? to.meta?.props?.indexPath : 'audio_guide/index.json')).data
      }
      this.currentGuide = (await loadAudioGuide(this.$root.$i18n.locale, to, this.guideIndex)).data
      next()
      document.title = this.title
      tracker.track(to, from)
    },
    mounted() {
      document.title = this.title
    },
    watch: {
      title: function(newVal) {
        document.title = newVal
      }
    },
    props: {
      indexPath: {
        type: String,
        default: 'audio_guide/index.json'
      },
      guidePathPrefix: {
        type: String,
        default: '/audioGuide'
      }
    },
    data() {
      return {
        guideIndex: null,
        currentGuide: null
      }
    },
    created() {
      this.initializeContent()
    },
    computed: {
      title() {
        if (!this.currentGuide) {
          return 'Загрузка' + this.$t('Common.title_suffix')
        }
        return this.currentGuide[this.$root.$i18n.locale].title + this.$t('Common.title_suffix')
      },
      available() {
        return this.hasActiveAnnualPass || !this.isNormalUser
      },
      ...mapGetters({
        annualPasses: 'loyalty/annualPasses',
        hasActiveAnnualPass: 'loyalty/hasActiveAnnualPass',
        isNormalUser: 'auth/userIsNormal'
      })
    },
    methods: {
      initializeContent() {
        this.loadAnnualPassList()
        // подгрузка контента на случай перезагрузки кода (где обработчики не срабатывают)
        let guideIndexPromise = this.guideIndex ? Promise.resolve() :
                                fetchDocument(this.indexPath).then(resp => {
                                  this.guideIndex = resp.data
                                })
        if (!this.currentGuide) {
          guideIndexPromise.then(() => {
            return this.fetchAudioGuide()
          })
        }
      },
      fetchAudioGuide() {
        let guideUri = this.guideIndex[this.$root.$i18n.locale].structure[this.$route.params.guideId]
        return fetchDocumentWithDynamicSitePath(guideUri + '.json')
                           .then(resp => {
                             this.currentGuide = resp.data
                           })
      },
      handleClicks(event) {
        let { target } = event
        while (target && target.tagName !== 'A') target = target.parentNode
        if (target && target.matches('a.collapser[data-toggle="collapse"]')) {
          let collapseElementId = target.getAttribute('data-target')
          let collapseElement = document.querySelector(collapseElementId)
          if (collapseElement != null && collapseElement.matches('.collapse')) {
            collapseElement.classList.toggle('show')
          }
        }
      },
      excursionStartHandle() {
        let guideUri = this.guideIndex[this.$root.$i18n.locale].structure[this.$route.params.guideId]
        if (this.currentGuide[this.$root.$i18n.locale].exhibits?.length > 0) {
          let exhibitUrl =  this.currentGuide[this.$root.$i18n.locale].exhibits[0]
          let code = Object
            .entries(this.guideIndex[this.$root.$i18n.locale].resources[guideUri].exhibits)
            .filter(([,v]) => v === exhibitUrl)
            .map(([k]) => k)[0]
          if (code) {
            this.$router.push(`${this.guidePathPrefix}/${this.$route.params.guideId}/${code}`)
          } else {
            console.warn('Не удалось найти код аудиогида по URL')
          }
        } else {
          console.warn('У аудиогида нет привязанных работ в списке')
        }
      },
      onNumberSubmitted(code) {
        if (!code) {
          return
        }
        code = String(code).trim()
        let guideUri = this.guideIndex[this.$root.$i18n.locale].structure[this.$route.params.guideId]
        if (this.guideIndex[this.$root.$i18n.locale].resources[guideUri].exhibits[code]) {
          this.$router.push(`${this.guidePathPrefix}/${this.$route.params.guideId}/${code}`)
        } else {
          if ('ga' in window) {
            let tracker = window.ga.getAll()[0]
            if (tracker) {
              tracker.send(
                'event',
                'audio_guide',
                'exhibit_not_found',
                code
              )
            }
          }
          this.$refs['search-failure'].show()
        }
      },
      ...mapActions({loadAnnualPassList: 'loyalty/loadAnnualPassList' }),
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-detail {
    a {
      color: #{$secondary};
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      letter-spacing: 0.002em;
      line-height: 24px;
      font-size: 19px;
      font-weight: 500;
    }

    a:hover {
      color: #{$secondary};
      text-decoration: underline;
      opacity: 0.7;
    }

    h2 {
      font-size: 36px;
    }

    .howto {
      display: block;
      margin-top: 120px;
      margin-bottom: 80px;
    }

    > hr {
      height: 0px;
      border-top: solid 1px #{$erarta-much-lighter-gray};
      margin: 0;
    }

    .guide-demo-header, .guide-header {
      /*border-bottom: solid 1px #{$erarta-much-lighter-gray};*/
    }

    .guide-content {
      margin-top: 35px;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .guide-header {
        height: 100px;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      .guide-content {
        margin-top: 20px;
      }
      h2 {
        font-size: 25px;
        line-height: 28px;
        margin-top: 50px;
      }
      .howto {
        margin-top: 28px;
        margin-bottom: 40px;
      }
    }
  }
</style>
