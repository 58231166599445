<template>
<div class="orders">
  <b-container>
    <back-link to="/" :nav-text="$t('Common.back')" />
    <page-title :title="$t('Order.header')"></page-title>
    <hr class="title-separator">
  </b-container>
  <b-container>
    <orders></orders>
  </b-container>

</div>
</template>

<script>

import PageTitle from '@/components/common/PageTitle'
import Orders from '@/components/order/Orders'
import BackLink from '@/components/common/BackLink'

export default {
  name: 'OrdersPage',
  components: {
    'page-title': PageTitle,
    'orders' : Orders,
    BackLink
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.orders {
  .collapse-btn, .collapse-btn:hover, .collapse-btn:active, .collapse-btn:focus {
    background: none;
    border: none;
    background-color: unset;
  }
  hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top: 4px solid #{$erarta-lighter-gray};
  }

  hr.title-separator {
    margin-top: 10px;
  }

  .back-link {
    display: block;
    margin-top: 26px;
  }

  .title-container {
    padding-top: 68px;
  }

  @media (max-width: 1023px) {
    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    hr.title-separator {
      margin-top: 0;
    }
    .title-container {
      padding-top: 26px;
    }

    .back-link {
      margin-top: 28px;
    }
  }
}

dd {
  margin: 0;
}

dd::after {
  content: '\A';
  white-space: pre-line;
}

dd:last-of-type::after {
  content: '';
}

dd, dt {
  display: inline;
}

dt::after {
  content: ': ';
}

dt {
  font-weight: 500 !important;
}
</style>
