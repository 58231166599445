<template>
  <div class="d-flex flex-column flex-1">
    <b-container>
      <page-title :title="$t('Authentication.passwordRecover_header')"></page-title>
    </b-container>
    <div v-if="!recoveryCompleted" class="acc-container justify-content-center d-flex">
      <b-row class="acc-form-row">
        <b-col md="auto">
          <PasswordRecoveryRequest @completed="recoveryCompleted = true"/>
        </b-col>
      </b-row>
    </div>
    <b-container v-else>
      <span class="acc-instructions">{{ $t('Authentication.passwordRecover_instructions') }}</span>
    </b-container>
  </div>
</template>

<script>
import PasswordRecoveryRequest from '@/components/PasswordRecoveryRequest'
import PageTitle from '@/components/common/PageTitle'

export default {
  name: 'PasswordRecoveryRequestPage',
  components: {PasswordRecoveryRequest, PageTitle},
  data() {
    return {
      recoveryCompleted: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.acc-instructions {
  font-size: 18px;
}

@media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
  .acc-instructions {
    font-size: 16px;
  }
}
</style>
