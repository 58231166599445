<template>
  <div class="guide-page" v-if="articleContent">
    <b-container>
      <back-link
        :nav-text="$t('AudioGuides.audio_guide_detail_back')"
        to="/audioGuide/" />
      <page-title :title="articleContent[$root.$i18n.locale].title"/>
    </b-container>
    <b-container class="small-container" v-html="articleContent[$root.$i18n.locale].description">
    </b-container>
  </div>
</template>
<script>
  import PageTitle from '@/components/common/PageTitle'
  import BackLink from '@/components/common/BackLink'
  import {fetchDocument} from '@/modules/content-loader'
  export default {
    components: {
      'page-title': PageTitle,
      BackLink
    },
    data() {
      return {
        articleContent: null
      }
    },
    mounted() {
      fetchDocument('article/audio_guide_howto.html.json')
        .then(resp => this.articleContent = resp.data)
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .guide-page {
    white-space: normal;
    audio {
      margin-bottom: 30px;
      margin-top: 30px;
      display: block;
    }
    ul.list-style-dash {
      list-style-type: none;
      padding-left: 0;
    }
    
    
    ul.list-style-dash > li:before {
      display: inline-block;
      content: "-";
      text-indent: 0;
      margin-left: 0px;
      margin-right: 10px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      audio {
        margin-top: 15px;
        margin-bottom: 18px;
      }
    }
  }
</style>
