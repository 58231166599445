<template>
  <div class="d-flex guide-demo-header">
    <div class="d-flex align-items-center">
      <img :src="require('@/assets/icons/eye.svg')">
      {{$t('AudioGuides.no_annual_pass_description')}}
      <button class="info" @click="$emit('info-clicked')"><img :src="require('@/assets/icons/info.svg')"></button>
    </div>
    <router-link class="btn btn-primary buy-annual-pass-button" :to="bindFormUrl">{{$t('AudioGuides.bind_pass_label')}}</router-link>
  </div>
</template>
<script>
  export default {
    props: {
      bindFormUrl: {
        type: String,
        required: true,
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-demo-header {
    :not(.buy-annual-pass-button) {
      color: #{$secondary};
      font-size: 16px;
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      height: 100px;
      align-items: center;
      
      > *:not(:last-child) {
        margin-right: 56px;
      }

      .buy-annual-pass-button {
        margin-left: auto;
        margin-bottom: 0;
      }
    }
    .buy-annual-pass-button {
      background-color: #{$primary};
      font-size: 14px;
      text-decoration: none;
      display: inline-block;
    }
    img, svg {
      width: 24px;
      height: auto;
      display: block;
    }
    > * > *:first-child {
      margin-right: 16px;
    }
    > * > *:last-child {
      margin-left: 16px;
    }
    button.info {
      border: none;
      background: transparent;
      padding: 0;
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      flex-direction: column;
      align-items: flex-start;
      > *:first-child {
        justify-content: space-between;
      }
      > * {
        margin-top: 17px;
      }
      .buy-annual-pass-button {
        font-size: 16px;
        margin-bottom: 20px;
      }
      :not(.buy-annual-pass-button) {
        font-size: 14px;
        line-height: 16.5px;
      }
    }
  }
</style>
