<template>
    <div class="privileges">
      <b-container><h2>{{$t('Loyalty.privileges_header')}}</h2></b-container>
      <div class="privilege-grid container-md">
        <h4 class="text-center">{{$t('Loyalty.privileges_ticket_header')}}</h4>
        <h4 class="text-center">{{$t('Loyalty.privileges_annual_pass_header')}}</h4>
        <template v-for="(privilege,index) in privilegeInfo">
          <privilege class="privilege" :privilege-info="privilege.ticket" v-if="privilege.ticket" :key="index * 2" />
          <div class="no-privilege" :key="index * 2" v-else>
            <picture class="img-fluid">
              <source srcset="../assets/icons/close_mobile.svg" media="(max-width: 1023.98px)" />
              <img srcset="../assets/icons/close_desktop.svg">
            </picture>
          </div>
          <privilege class="privilege" :privilege-info="privilege.annualPass" v-if="privilege.annualPass" :key="index * 2 + 1" />
          <div class="no-privilege" :key="index * 2 + 1" v-else>
            <picture class="img-fluid">
              <source srcset="../assets/icons/close_mobile.svg" media="(max-width: 1023.98px)" />
              <img srcset="../assets/icons/close_desktop.svg">
            </picture>
          </div>
        </template>
      </div>
    </div>
</template>
<script>
  import Privilege from '@/components/Privilege'
  export default {
    components: {
      'privilege': Privilege
    },
    props: {
      privilegeInfo: {
        type: Array,
        required: true
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .privileges {
    h2 {
      font-size: 25px;
      line-height: 25px;
      font-weight: #{$font-weight-medium};
    }

    .privilege-grid {
      margin-top: 21px;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 12px;
      padding-bottom: 8px;
    }

    h4 {
      font-weight: #{$font-weight-medium};
      font-size: 16px;
      letter-spacing: 0.002em;
      text-transform: lowercase;
    }

    .privilege-grid::after {
      content: ' ';
      position: absolute;
      border-right: 2px solid #{$erarta-lighter-gray};
      left: 50%;
      left: calc(50% - 1px);
      height: 100%;
      top: 0;
    }

    .privilege, .no-privilege {
      justify-items: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      height: auto;
    }

    .privilege span {
      max-width: 140px;
    }

    .no-privilege {
      /*background: url("../assets/icons/close_mobile.svg") center center no-repeat;*/
      min-height: 88px;
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .privilege-grid {
        padding-left: 10px;
        padding-right: 10px;

        column-gap: 22px;
      }
      .privilege, .no-privilege {
        max-width: 100%;
      }

      .privilege > img {
        max-width: 140px;
        width:100%;
      }
    }
    @media (min-width: #{map-get($grid-breakpoints, md)}) {

      h2 {
        font-size: 36px;
        line-height: 64px;
      }
      h4 {
        font-size: 32px;
        line-height: 40px;
      }

      .privilege-grid {
        h4 {
          margin-bottom: -40px;/*проще так чем для всех margin вписывать*/
        }
        margin-top: 80px;
        padding-bottom: 120px;
        row-gap: 40px;
      }

      .privilege, .no-privilege {
        display: flex;
        flex-direction: column;
        height: auto;

      }
      .privilege {
        margin-left: auto;
        margin-right: auto;
        span {
          max-width: 320px;
        }
        max-width: 100%;
      }
      .privilege > img {
        width: auto;
      }

      .no-privilege {
        /*background: url("../assets/icons/close_desktop.svg") center center no-repeat;*/
        min-height: 197px;
        max-width: 317px;
        width: 100%;
      }

    }
  }

</style>
