<template>
  <div class="d-flex guide-header">
    <div class="align-items-center plate-block">
      <svg viewBox="0 0 24 24" width="24" height="24">
        <rect :fill="plateColor" x="1" y="1" width="22" height="22" stroke="#444444" rx="3" ry="3" stroke-width="2" />
      </svg>
      {{infoBlock.plateColor}}
    </div>
    <div class="align-items-center location-block" v-if="infoBlock.location">
      <img :src="require('@/assets/icons/geolocation.svg')">
      {{infoBlock.location}}
    </div>
    <div class="align-items-center exhibit-block" v-if="infoBlock.exhibitCount">
      <img :src="require('@/assets/icons/picture_exhibit.svg')">
      {{infoBlock.exhibitCount}}
    </div>
    <div class="align-items-center" v-if="infoBlock.duration">
      <img :src="require('@/assets/icons/clock.svg')">
      {{infoBlock.duration}}
    </div>
    <div class="align-items-center" v-if="infoBlock.ageRestriction">
      <img :src="require('@/assets/icons/age_restriction.svg')">
      {{infoBlock.ageRestriction}}
    </div>
    <b-button v-if="showExcursion" variant="primary" class="start-excursion" @click="$emit('excursion-start-click')">{{$t('AudioGuides.start_excursion')}}</b-button>
  </div>
</template>
<script>

  export default {
    props: {
      infoBlock: {
        type: Object,
        required: true
      },
      plateColor: {
        type: String,
        required: false,
        default: 'white'
      },
      showExcursion: {
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .guide-header {
    font-weight: #{$font-weight-medium};

    > *:not(.start-excursion) {
      display: grid !important;
      grid-template-columns: 24px 1fr;
      column-gap: 16px;
      grid-auto-flow: column;
      font-size: 16px;
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      align-items: center;
      
      > *:not(.start-excursion) {
        margin-right: 56px;
      }

      .start-excursion {
        margin-left: auto;
      }
    }
    .plate-block svg,
    .plate-block img,
    .exhibit-block img,
    .exhibit-block  svg {
      width: 24px;
      height: 24px;
    }

    img, svg {
      width: auto;
      height: 22px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;

      button.start-excursion {
        width: 100%;
        height: 40px;
      }
      > *:not(.start-excursion) {
        font-size: 14px;
      }
      > * {
        margin-top: 19px;
      }
      > *:last-child {
        margin-bottom: 20px;
      }
    }
  }
</style>
