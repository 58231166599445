<template>
  <b-modal :ref="`modal`" size="lg" body-class="p-0" :hide-footer="true">
    <img v-if="!!element.content.image" :src="element.content.image"/>
    <h3 class="title">{{element.content.title}}</h3>
    <div v-html="element.content.description"></div>
    <div class="floor">{{element.content.floor}}</div>
    <div v-if="!!element.content.link" class="link" >
      <a :href="element.content.link" class="external" target="_blank">{{$t('BuildingPlan.museum_link')}}</a>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: ['element'],
}
</script>

<style scoped>
img {
  max-width: 100%;
}
.floor {
  font-size: 14px;
  line-height: 20px;
  color: #87878A;
  font-weight: 500;
  margin-top: 6px;
}
.link {
  border-top: 2px solid #CCCED0;
  margin-top: 27px;
  padding-top: 20px;
}
.link a {
  justify-content: center;
}
.link .external {
  font-size: 18px;
}

@media (max-width: 1023px) {
  .link .external {
    font-size: 16px;
  }
  h3.title {
    font-size: 17px;
  }
  .link {
    margin-bottom: 25px;
    border-top: 1px solid #CCCED0;
  }
}

</style>

<style>
.modal-header{
  .height: 40px;
}
@media (max-width: 1023px) {
  .modal-header {
    height: unset;
    min-height: 68px;
  }
}
</style>
