import accountApi from '@/modules/account'
import membershipApi from '@/modules/membership'
import moment from 'moment'

const state = () => ({
  annualPasses: null,
  annualPassesLoaded: false,
  annualPassesLoadPromise: null,
  annualPassTypes: null,
  annualPassTypesLoaded: false,
  annualPassTypesLoadPromise: null,
  tickets: null,
  ticketsLoaded: false,
  ticketLoadPromise: null,
})

const getters = {
  annualPasses: state => state.annualPasses,
  activePasses: state => state.annualPasses?.filter(
    ap => ap.active && !ap.blocked && (!!ap.expires && moment(Date.now()).isBefore(moment(ap.expires)))
  ),
  activeClubPasses(state, getters) {
    const clubPassTypes = state.annualPassTypes.filter(type => type.club)
      .map(type => type.type)
    return getters.activePasses?.filter(activePass => clubPassTypes.includes(activePass?.type))
  },
  anyActiveClubPassOrElseAnyActivePass(state, getters) {
    const activeClubPassNumber = getters.activeClubPasses?.find(activeClubPass => activeClubPass != null)?.number
    if (activeClubPassNumber != null) {
      return activeClubPassNumber
    }
    return state.activePasses?.find(activePass => activePass != null)?.number
  },
  extensibleAnnualPassTypes: state => state.annualPassTypes?.filter(ap => ap.duration === 1 && ap.renewalAvailable),
  availableAnnualPassTypesForTypeChange: state => state.annualPassTypes?.filter(ap => ap.duration === 1 && ap.typeChangePrice != null),
  allAnnualPassTypes: state => state.annualPassTypes?.filter(ap => ap.duration === 1),
  existAnnualPassesWithAttrs: state => attrs => {
    if (typeof attrs === 'undefined') {
      return true
    }
    return state.annualPasses?.filter(ap => ap.active).some(ap => {
      return attrs.some(attr => {
        return ap.attributes && ap.attributes[attr] != null && ap.attributes[attr]
      })
    })
  },
  hasActiveAnnualPass(state, getters) {
    return getters.activePasses && getters.activePasses.length > 0
  },
  tickets(state) {
    return state.tickets
  },
  activeTickets: state => state.tickets?.filter(
    ap => ap.active && ap.expires && moment(Date.now()).isBefore(moment(ap.expires))
  ),
}

const mutations = {
  saveAnnualPassList (state, payload) {
    state.annualPasses = payload
    state.annualPassesLoaded = true
  },
  annualPassUpdatePending(state, payload) {
    state.annualPassesLoadPromise = payload
  },
  addAnnualPass (state, payload) {
    if (state.annualPasses != null) {
      state.annualPasses.push(payload)
    }
  },
  removeAnnualPass(state, number) {
    if (state.annualPasses != null) {
      state.annualPasses = state.annualPasses.filter(ap => ap.number !== number)
    }
  },
  invalidateAnnualPassList (state) {
    state.annualPassesLoaded = false
  },
  saveAnnualPassTypeList (state, payload) {
    state.annualPassTypes = payload
    state.annualPassTypesLoaded = true
  },
  invalidateAnnualPassTypeList (state) {
    state.annualPassTypesLoaded = false
  },
  annualPassTypesUpdatePendingStatus(state, payload) {
    state.annualPassTypesLoadPromise = payload
  },
  ticketsUpdatePending(state, payload) {
    state.ticketLoadPromise = payload
  },
  saveTicketList(state, payload) {
    state.tickets = payload
    state.ticketsLoaded = true
  }
}

const actions = {
  loadAnnualPassList({rootState, state, commit}) {
    if (rootState.auth.userData != null && rootState.auth.userData.id != null) {
      if (state.annualPasses == null || !state.annualPassesLoaded) {
        if (state.annualPassesLoadPromise == null) {
          let annualPassesLoadPromise = accountApi.getAnnualPassList()
            .then(resp => {
              commit('saveAnnualPassList', resp.data)
              return resp.data
            })
            .finally(() => {
              commit('annualPassUpdatePending', null)
            })
          commit('annualPassUpdatePending', annualPassesLoadPromise)
          return annualPassesLoadPromise
        } else {
          return state.annualPassesLoadPromise
        }
      }
      return []
    }
    return Promise.resolve(state.annualPasses)
  },
  loadAnnualPassTypeList({state, commit}) {
    if (state.annualPassTypes == null || !state.annualPassTypesLoaded) {
      if (state.annualPassTypesLoadPromise == null) {
        let promise = accountApi.getAnnualPassTypeList()
                                .then(resp => {
                                  commit('saveAnnualPassTypeList', resp.data)
                                  return resp.data
                                }).finally(() => {
                                  commit('annualPassTypesUpdatePendingStatus', null)
                                })
        commit('annualPassTypesUpdatePendingStatus', promise)
        return promise
      }
      else {
        return state.annualPassTypesLoadPromise
      }
    }
    return Promise.resolve(state.annualPassTypes)
  },
  bindAnnualPass({commit}, {number, ownerName}) {
    return accountApi.bindAnnualPass(number, ownerName)
                     .then(resp => {
                       commit('addAnnualPass', resp.data)
                       return resp.data
                     })
  },
  unbindAnnualPass({commit}, number) {
    return accountApi.unbindAnnualPass(number)
                     .then(() => {
                       commit('removeAnnualPass', number)
                       return number
                     })
  },
  subscribeForAutoRenewal({rootState}, {number, guid, locale}) {
    if (rootState.auth.userData?.sberbankBindingIdSet) {
      return accountApi.performAutoRenewalSubscription(number)
                       .then((resp) => {
                         return {
                           response: resp,
                           needsCardAssignment: false
                         }
                       })
    } else {
      return membershipApi.performSubscriptionWithCardAssignment(rootState.auth.userData.id, number, guid, locale)
                          .then(resp => {
                            let data = resp.data
                            data.response = resp
                            data.needsCardAssignment = true
                            return data
                          })
    }
  },
  unsubscribeFromAutoRenewal(context, number) {
    return accountApi.performAutoRenewalUnsubscription(number)
  },
  performPaymentMethodChange({rootState}, locale) {
    return membershipApi.performPaymentMethodChange(rootState.auth.userData.id, locale)
  },
  sendGiftInfo(context, {passId, giftIndex, passNumber, giftNameRU, giftNameEN, locale}) {
    return accountApi.sendGiftInfo(passId, giftIndex, passNumber, giftNameRU, giftNameEN, locale)
  },
  forceLoadAnnualPassList({commit}) {
    let annualPassesLoadPromise = accountApi.getAnnualPassList()
      .then(resp => {
        commit('saveAnnualPassList', resp.data)
        return resp.data
      })
      .finally(() => {
        commit('annualPassUpdatePending', null)
      })
    commit('annualPassUpdatePending', annualPassesLoadPromise)
  },
  loadTicketList({rootState, state, commit}) {
    if (rootState.auth.userData != null && rootState.auth.userData.id != null) {
      if (state.tickets == null || !state.ticketsLoaded) {
        if (state.ticketLoadPromise == null) {
          let ticketLoadPromise = accountApi.getTicketList()
            .then(resp => {
              commit('saveTicketList', resp.data)
              return resp.data
            })
            .finally(() => {
              commit('ticketsUpdatePending', null)
            })
          commit('ticketsUpdatePending', ticketLoadPromise)
          return ticketLoadPromise
        } else {
          return state.ticketLoadPromise
        }
      }
      return []
    }
    return Promise.resolve(state.tickets)
  },
  forceLoadTicketList({commit}) {
    let ticketsLoadPromise = accountApi.getTicketList()
      .then(resp => {
        commit('saveTicketList', resp.data)
        return resp.data
      })
      .finally(() => {
        commit('ticketsUpdatePending', null)
      })
    commit('ticketsUpdatePending', ticketsLoadPromise)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
