<template>
  <div>
    <h2 class="exhibitions__title font-weight-medium">{{$t('BuildingPlan.current_exhibitions')}}</h2>
    <ul class="d-flex exhibitions__list">
      <li v-for="(exhibition, index) in exhibitions" :key="index" class="exhibitions__item d-flex">
        <div class="exhibitions__image-wrap">
          <div v-if="exhibition.icons.length > 0" class="icons">
            <span v-for="icon in exhibition.icons" :key="icon">
              <img :src="require(`../../assets/images/building_plan/static/${icon}_modal.svg`)" :alt="icon"/>
            </span>
          </div>
          <div v-if="!!exhibition.floor" class="floor">
            {{exhibition.floor}}
          </div>
          <a class="exhibitions__item-img-wrap" v-on:click="highlightIcon(exhibition.icons, $event)">
            <img :alt="exhibition.title" :src="exhibition.image">
          </a>
        </div>
        <div class="exhibitions__item-bottom">
          <a class="exhibitions__item-name" v-on:click="highlightIcon(exhibition.icons, $event)">
            {{ exhibition.title }}
          </a>
          <div class="exhibitions__item-date">
            {{ reformatDate(exhibition.dateStart, "DD.MM.YYYY", 'DD MMMM') }}  — {{ reformatDate( exhibition.dateEnd, "DD.MM.YYYY", 'DD MMMM YYYY') }}
          </div>
          <div class="exhibitions__item-age">
            {{ exhibition.ageLimit }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import buildingPlan from '@/modules/buildingPlan'
import moment from 'moment'

export default {
  name: 'Exhibitions',
  props: {
    exhibitions: {
      type: Array
    },
  },
  created () {
    moment.locale(this.$i18n.locale)
  },
  methods: {
    highlightIcon(iconIds, e) {
      e.stopPropagation()
      if (!iconIds) {
        return
      }
      let additionalIconIds = []
      if (iconIds.length > 1) {
        additionalIconIds = iconIds.slice(1, iconIds.length)
      }
      buildingPlan.highlightIcon(iconIds[0], additionalIconIds)
    },
    reformatDate(value, initialFormat, targetFormat) {
      return moment(value, initialFormat).format(targetFormat)
    }
  }
}
</script>

<style scoped>
.exhibitions__image-wrap {
  position: relative;
}
.icons {
  position: absolute;
  padding: 16px;
  z-index: 1;
}
.icons span {
  margin-right: 5px;
}
.floor {
  position: absolute;
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  bottom: 0;
  padding-left: 16px;
  padding-bottom: 12px;
  z-index: 1;
}
.exhibitions__title {
  font-size: 36px;
  margin-bottom: 60px;
}
.exhibitions__list {
  flex-wrap: wrap;
  margin-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
.exhibitions__item {
  width: calc(33% - 27px);
  margin-right: 44px;
  background-color: #f5f5f6;
  margin-bottom: 40px;
  color: #fff;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 514px;
}

.exhibitions__item-img-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
img {
  max-width: 100%;
  height: auto;
}
.exhibitions__item.gray .exhibitions__item-bottom {
  color: #000;
  background-color: #f5f5f6;
}
a.exhibitions__item-name {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.26;
  margin-bottom: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.exhibitions__item-date, .exhibitions__item-age {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: .2px;
  margin-bottom: 13px;
  color: #49494a;
}
.exhibitions__item-age {
  margin-top: 10px
}
.exhibitions__item-bottom {
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 240px;
}
.exhibitions__item-img-wrap {
  height: 274px;
  background-blend-mode: multiply;
  position: relative;
  display: block;
}

@media (max-width: 1439px) {
  .exhibitions__item-bottom {
    min-height: 240px;
  }
}
@media (max-width: 1279px) {
  .exhibitions__item-bottom {
    min-height: 220px;
  }
}
@media (max-width: 1023px) {
  .exhibitions__item-img-wrap {
    height: 210px;
  }
  .exhibitions__item-bottom {
    min-height: 180px;
  }
  .exhibitions__title {
    margin-bottom: 10px;
  }
  .floor {
    font-size: 17px;
  }
  .icons img {
    width: 40px;
  }
  a.exhibitions__item-name {
    font-size: 17px;
  }
  .exhibitions__title {
    font-size: 25px;
  }
}
@media (max-width: 480px) {
  .exhibitions__item {
    width: 100%;
    margin: 0 0 25px;
    min-height: initial;
  }
  .exhibitions__item-bottom {
    min-height: 100px;
  }
}
@media (min-width: 1024px) {
  .exhibitions__item:nth-child(3n) {
    margin-right: 0;
  }
  .exhibitions__list {
    margin-bottom: 40px;
  }
}
@media (min-width: 481px) and (max-width: 1023px) {
  .exhibitions__item {
    width: calc(50% - 10px);
    min-height: initial;
    margin-right: 20px;
  }
  .exhibitions__item:nth-child(2n) {
    margin-right: 0;
  }
  .exhibitions__item {
    margin-bottom: 25px;
  }
}

</style>
