<template>
  <div class="container-fluid">
    <page-title :title="$t('Common.myErarta_header')" class="container mx-auto main-page-title"></page-title>
    <MyErarta v-if="dataLoaded" :have-active-pass="(!!(activePasses && activePasses.length)) || (!!(activeTickets && activeTickets.length))" class="my-erarta"/>
    <MyPossibilities v-if="dataLoaded" :data="myPossibilities" :available="!!(activePasses && activePasses.length)"
      class="mb-3" :pre-selected-section="this.$route.hash"/>
  </div>
</template>

<script>
  import MyErarta from '../components/MyErarta'
  import MyPossibilities from '../components/MyPossibilities'
  import {fetchDocument} from '@/modules/content-loader'
  import PageTitle from '@/components/common/PageTitle'
  import {mapGetters} from 'vuex'

  const myPossibilitiesPath = 'my_possibilities/my_possibilities.html.json'
  export default {
    name: 'MainPage',
    components: {MyPossibilities, MyErarta, PageTitle},
    data: () => ({
      myPossibilities: null,
      fetchError: false
    }),
    computed: {
      dataLoaded() {
        return this.myPossibilities != null
      },
      ...mapGetters({ activePasses: 'loyalty/activePasses', activeTickets: 'loyalty/activeTickets' })
    },
    methods: {
    },
    async beforeRouteEnter(to, from, next) {
      let myPossibilities, fetchError
      try {
        myPossibilities = (await fetchDocument(myPossibilitiesPath)).data
      } catch {
        fetchError = true
      }

      next( (vm) => {
        vm.myPossibilities = myPossibilities
        vm.fetchError = fetchError
      })
    },
    async beforeRouteUpdate(to, from, next) {
      try {
        if (!this.myPossibilities) {
          this.myPossibilities = (await fetchDocument(myPossibilitiesPath)).data
        }
      } catch {
        this.fetchError = true
      }

      next()
    },
    mounted() {
      if (!this.myPossibilities) {
        fetchDocument(myPossibilitiesPath)
          .then(doc => this.myPossibilities = doc.data)
          .catch(() => {
            this.fetchError = true
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/_variables.scss";

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {

  }
</style>
