import { render, staticRenderFns } from "./AudioGuideExhibitHeader.vue?vue&type=template&id=384af012"
import script from "./AudioGuideExhibitHeader.vue?vue&type=script&lang=js"
export * from "./AudioGuideExhibitHeader.vue?vue&type=script&lang=js"
import style0 from "./AudioGuideExhibitHeader.vue?vue&type=style&index=0&id=384af012&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports