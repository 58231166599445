<template>
  <div class="ticket-card">
    <h4 class="pass-title">{{$t('Loyalty.one_time_pass_type')}}</h4>
    <div class="qr-icon">
      <img src="../assets/images/qr-zoom-icon.svg"
        @click="$refs.qrModal.show()">
    </div>
    <dl class="ticket-desc">
      <dt>{{$t('Loyalty.pass_number')}}</dt>
      <dd>{{data.number}}</dd>
      <div class="mt-3"/>
      <dt>{{$t('Loyalty.pass_status_label')}}</dt>
      <dd>{{$t('Loyalty.pass_status_' + statusMessage)}}
        <expiration-icon :status-message="statusMessage" />
      </dd>
      <dd v-if="ticketExpired">{{$t('Loyalty.pass_expired')}}</dd>
    </dl>
    <div class="qr-panel flex-column align-items-end">
      <qrcode-vue :value="qrData" ref="qr-code" class="qr-code" size="200"></qrcode-vue>
      <p class="d-block text-right">{{$t('Loyalty.pass_qr_label')}}</p>
    </div>
    <div class="subscription-benefits" v-if="loyaltyPageInfo[this.$root.$i18n.locale]">
      <template v-if="activeEntryTicket && loyaltyPageInfo[this.$root.$i18n.locale].activeEntryTicketBannerItems">
        <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }" :key="index"
             v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].activeEntryTicketBannerItems">
          <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
          <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
        </div>
      </template>
      <template v-else-if="!ticketExpired && loyaltyPageInfo[this.$root.$i18n.locale].activeTicketBannerItems">
        <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }" :key="index"
          v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].activeTicketBannerItems">
          <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
          <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
        </div>
      </template>
      <template v-if="ticketExpired && loyaltyPageInfo[this.$root.$i18n.locale].ticketExpiredBannerItems">
        <div class="d-table" :class="{ 'highlighted-banner': bannerInfo.highlighted }" :key="index"
          v-for="(bannerInfo, index) in loyaltyPageInfo[this.$root.$i18n.locale].ticketExpiredBannerItems">
          <div class="d-table-cell item-icon v-align-middle"><img :src="bannerInfo.image"></div>
          <div class="tooltip-item-text d-table-cell v-align-middle" v-html="evalBannerText(bannerInfo.text)"></div>
        </div>
      </template>
    </div>
    <b-modal ref="qrModal" body-class="qr-code-modal" :hide-footer="true">
      <qrcode-vue class="d-flex justify-content-center qr-code" :value="qrData" ref="modal-qr-code"></qrcode-vue>
      <h4 class="d-block mt-3 text-center">{{$t('Loyalty.pass_qr_label')}}</h4>
    </b-modal>
  </div>
</template>
<script>
  import moment from 'moment'
  import QrcodeVue from 'qrcode.vue'
  import ExpirationIcon from './ExpirationIcon'

  export default {
    components: {
      'qrcode-vue': QrcodeVue,
      ExpirationIcon
    },
    computed: {
      qrData() {
        return this.data.entryCode
      },
      statusMessage() {
        let expMoment = moment(this.data.expires)
        let nowMoment = moment()
        if (this.data.active && !expMoment.isBefore(nowMoment)) {
          return 'active'
        } else {
          return 'inactive'
        }
      },
      ticketExpired() {
        return moment(this.data.expires).isBefore(moment())
      },
      activeEntryTicket() {
        return !this.ticketExpired && this.data.entryDate != null
      }
    },
    methods: {

      evalBannerText(text) {
        if (!(typeof text === 'string')) {
          return null
        }

        return text
      },
    },
    data() {
      return {
      }
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      loyaltyPageInfo: null,
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";

  .qr-code {
    image-rendering: pixelated;
  }
  .qr-code-modal .qr-code canvas,
  .qr-code-modal .qr-code img {
    width: 75% !important;
    height: auto !important;
    max-width: 200px;
    max-height: 200px;
  }
  .ticket-card {
    font-size: 18px;
    display: grid;
    grid-template-areas: "title qrcode" "description qrcode" "subscription-benefits subscription-benefits";
    grid-template-columns: 1fr min-content;
    column-gap: 110px;
    grid-template-rows: min-content 1fr;
    row-gap: 30px;

    .pass-title {
      grid-area: title;
      text-transform: lowercase;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.002em;
    }

    .ticket-desc {
      grid-area: description;
    }

    .qr-panel {
      grid-area: qrcode;
      display: flex;
      max-width: 250px;
      justify-self: right;

      p {
        font-weight: #{$font-weight-medium};
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.002em;
      }
    }

    a {
      text-decoration: underline;
    }

    dd {
      margin: 0;
    }

    dd::after {
      content: '\A';
      white-space: pre-line;
    }

    dd:last-of-type::after {
      content: '';
    }

    dd, dt {
      display: inline;
      vertical-align: middle;
    }

    dt {
      font-weight: 500;
    }

    dt::after {
      content: ': ';
    }

    .qr-icon {
      grid-area: qr-icon;
      width: 36px;
      margin-top: 12px;
      position: relative;
    }

    .qr-icon > img {
      width: 100%;
      position: absolute;
      top: 0;
    }

    .subscription-benefits {
      grid-area: subscription-benefits;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      margin-top: 36px;
      margin-bottom: 50px;

      &:empty {
        margin: 0;
      }

      & > * {
        padding-top: 24px;
        padding-bottom: 24px;
        width: 100%;
        position: relative;
      }

      & > *:not(:last-child) {
        margin-bottom: 4px;
      }

      & > .highlighted-banner::before {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 50vw + var(--scrollbar-width) / 2);
        right: calc(50% - 50vw + var(--scrollbar-width) / 2);
        background: rgba($primary, 0.05);
        content: "";
        z-index: -1;
      }

      & > .highlighted-banner a {
        color: #{$primary};
      }

      p {
        margin-bottom: 0;
      }

      .item-icon,
      img {
        width: 45px;
        align-self: center;
      }
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .qr-icon {
        display: none;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      font-size: 14px;
      display: grid;
      grid-template-areas: "title qr-icon" "description description"  "subscription-benefits subscription-benefits";
      grid-template-columns: 1fr 36px;
      column-gap: 10px;
      grid-template-rows: min-content 1fr;
      row-gap: 13px;

      .pass-title {
        font-size: 25px;
        line-height: 25px;
        letter-spacing: inherit;
      }

      .qr-panel {
        display: none;
      }

      .subscription-benefits {
        font-size: 14px;
        line-height: 16px;
        margin-top: 11px;
        margin-bottom: 31px;

        &:empty {
          margin: 0;
        }

        & > * {
          padding-top: 17px;
          padding-bottom: 17px;
        }

        & > *:not(:last-child) {
          margin-bottom: 5px;
        }
        .item-icon,
        img {
          width: 22px;
        }
      }
    }
  }
</style>
