<template>
  <div class="loyalty" v-if="loyaltyPageInfo">
    <b-container>
      <back-link to="/" :nav-text="$t('Common.back')" />
      <page-title :title="$t('Loyalty.header')"></page-title>
    </b-container>
    <annual-passes :loyalty-page-info="loyaltyPageInfo" :wrap-container="true" />
    <b-container>
      <ticket-list :loyalty-page-info="loyaltyPageInfo" />
      <b-button class="collapser px-0" @click="bindFormVisible = !bindFormVisible" variant="link">{{$t('Loyalty.bindCollapse')}}<b-icon-chevron-up v-if="bindFormVisible"/><b-icon-chevron-down v-else/></b-button>
    </b-container>
    <div class="bind-container">
      <b-container>
        <b-collapse class="mt-2" v-model="bindFormVisible" ref="binding-collapse">
          <b-row class="justify-content-center">
            <bind-form @pass-bound="onPassBound"></bind-form>
          </b-row>
        </b-collapse>
      </b-container>
    </div>
    <component :is="$te('Loyalty.giftBanner_link') ? 'a' : 'div'" :href="$te('Loyalty.giftBanner_link') ? $t('Loyalty.giftBanner_link') : null" class="gift-container d-flex flex-column justify-content-center">
      <b-container>
        <gift-banner/>
      </b-container>
    </component>
    <div class="bottom-container">
      <privileges class="privilege-container" v-if="loyaltyPageInfo[$root.$i18n.locale] && loyaltyPageInfo[$root.$i18n.locale].privileges"
        :privilege-info="loyaltyPageInfo[$root.$i18n.locale].privileges"/>
      <b-container>
        <a target="_blank" class="info opacity-hover" :href="$t('Loyalty.passUsage_rules_link')">{{$t('Loyalty.rulesLink')}}</a>
      </b-container>
    </div>
  </div>
</template>

<script>
import AnnualPasses from '@/components/AnnualPasses'
import TicketList from '@/components/TicketList'
import AnnualPassBindForm from '@/components/AnnualPassBindForm'
import PageTitle from '@/components/common/PageTitle'
import Privileges from '@/components/Privileges'
import GiftBanner from '@/components/GiftBanner'
import {fetchDocument} from '@/modules/content-loader'
import BackLink from '@/components/common/BackLink'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'

export default {
    name: 'LoyaltyPage',
    components: {
      'annual-passes': AnnualPasses,
      'ticket-list': TicketList,
      'bind-form': AnnualPassBindForm,
      'page-title': PageTitle,
      'gift-banner': GiftBanner,
      'privileges': Privileges,
      BackLink, BIconChevronUp, BIconChevronDown
    },
    beforeMount() {
      fetchDocument('annual_pass_config/annual_pass_config.html.json').then((doc) => {
        this.loyaltyPageInfo = doc.data
      })
    },
    data() {
      return {
        bindFormVisible: false,
        loyaltyPageInfo: null
      }
    },
    methods: {
      onPassBound(boundPass) {
        this.$bvModal.msgBoxOk(this.$t('Loyalty.binding_success', [boundPass.number]), {
          size: 'md',
          contentClass: 'bind-pass-modal-content',
          okVariant: 'primary',
          hideHeaderClose: false
        })
      }
    }
  }
</script>
<style lang="scss">
  @import "@/assets/styles/_variables.scss";
  .loyalty {
    a.info {
      color: #{$secondary};
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      letter-spacing: 0.002em;
      line-height: 24px;
      font-size: 19px;
      font-weight: #{$font-weight-medium};
    }
    .gift-container {
      background-color: #{$primary};
      margin-top: 150px;
      height: 230px;
    }

    .bind-container {
      background-color: var(--erarta-very-light-gray);
      margin-top: 53px;
    }
    .privilege-container {
      padding-bottom: 80px;
    }

    .bottom-container {
      margin-top: 80px;
      margin-bottom: 80px;
    }
    .bind-form {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    hr {
      margin-top: 40px;
      margin-bottom: 40px;
      border-top: 4px solid #{$erarta-lighter-gray};
    }

    hr.title-separator {
      margin-top: 10px;
      margin-bottom: 50px;
    }

    .back-link {
      display: block;
      margin-top: 26px;
    }

    .title-container {
      padding-top: 68px;
    }

    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .privilege-container {
        padding-bottom: 52px;
      }
      .bind-container {
        margin-top: 20px;
      }
      .gift-container {
        margin-top: 40px;
        height: 67px;
        img {
          max-height: 51px;
          width: auto;
        }
      }
      .bottom-container {
        margin-top: 25px;
        margin-bottom: 40px;
      }

      a.info {
        font-size: 14px;
        line-height: 1;
      }

      .bind-form {
        padding-top: 33px;
        padding-bottom: 30px;
      }

      hr {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      hr.title-separator {
        margin-top: 0px;
      }

      .title-container {
        padding-top: 26px;
      }

      .back-link {
        margin-top: 28px;
      }
    }
  }

  .bind-pass-modal-content {
    .modal-body {
      margin: 60px 0 20px 0;
    }
    .modal-footer {
      justify-content: space-around;
      border-top: none;
      padding: 28px 0 0 0;
      > .btn {
        flex: 1;
        margin: 0;
        max-width: 50%;
      }
      > .btn:not(:last-child) {
        margin: 0 26px 0 0;
      }
    }
    @media (max-width: #{map-get($grid-breakpoints, md) - 0.02px}) {
      .modal-body {
        padding: 98px 0 25px 0;
      }
      .gift-container {
        margin-top: 40px;
      }
      .modal-footer {
        padding: 50px 0 112px 0;
        flex-direction: column;

        >.btn:not(:last-child) {
          margin: 0 0 18px 0;
        }
        >.btn {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
</style>
